/** @import icons */

import { FaCheck } from "react-icons/fa";

/**
 * 
 * @param { id, label, state } 
 * 
 * @returns checkbox component for montage form
 * 
 */

const CheckboxController = ( { id, label, state } ) => {

    /** @returns component */

    return ( <div className="fields">

        <div className="form-checkbox">

            <input 
            
                type="checkbox" 
                
                id={ id } 
                
                value={ state.view } 
                
                onChange={ e => ( e.target.checked ) ? state.set ( true ) : state.set ( false ) } 
                
                checked={ state.view } />

            <label htmlFor={ id }>

                <span className="custom-checkbox">

                    <span className="checked">

                        <FaCheck />

                    </span>

                </span>
                
                { label }
                
            </label>

        </div>

    </div> );

}

/** @exports CheckboxController */
 
export default CheckboxController;