import { gql } from '@apollo/client';

const 

CREATE_ORDER = gql`mutation CreateOrder ( 
    
    $products: [OrderProductInput!]!, 
    
    $assembly: Boolean!,
    
    $mobilePhoneNr: String!,

    $deliveryMethod: String,

    $paymentMethod: String,

    $city: String,

    $countryCode: String,

    $address: String,

    $email: String!,

    $postCode: String,

    $firstName: String!,

    $lastName: String!,

    $pvnRegNr: String,

    $companyName: String,

    $deliveryPlanId: Int,

    $userNotes: String,

    $invoiceNumber: String,

    $pdf: Upload

    ) {

    createOrder ( input: { products: $products, pdf: $pdf, orderInfo: {

        assembly: $assembly,

        mobilePhoneNr: $mobilePhoneNr,

        deliveryMethod: $deliveryMethod,

        paymentMethod: $paymentMethod,

        city: $city,

        address: $address,

        email: $email,

        postCode: $postCode,

        firstName: $firstName,

        lastName: $lastName,

        pvnRegNr: $pvnRegNr,

        companyName: $companyName,

        countryCode: $countryCode,

        deliveryPlanId: $deliveryPlanId,

        userNotes: $userNotes,

        invoiceNumber: $invoiceNumber

    } } ) {

        order { 

            id

            price

            deliveryPrice
            
            paymentMethod

            deliveryMethod

            orderType

            orderProductVendors {

                id

                count

                price

                product {

                    id

                    productType

                    model

                    manufacturer {
                            
                        name

                    }

                }

            }
        
        }

        errors

        paymentLink

        paymentMethods

        paymentWidget

    }

}`;

/** @export mutations */

export { CREATE_ORDER }