import PoppinsBlack from "../../../../../Assets/Fonts/Poppins-Black.ttf";

import PoppinsBold from "../../../../../Assets/Fonts/Poppins-Bold.ttf";

import PoppinsExtraBold from "../../../../../Assets/Fonts/Poppins-ExtraBold.ttf";

import PoppinsExtraLight from "../../../../../Assets/Fonts/Poppins-ExtraLight.ttf";

import PoppinsLight from "../../../../../Assets/Fonts/Poppins-Light.ttf";

import PoppinsMedium from "../../../../../Assets/Fonts/Poppins-Medium.ttf";

import PoppinsRegular from "../../../../../Assets/Fonts/Poppins-Regular.ttf";

import PoppinsSemiBold from "../../../../../Assets/Fonts/Poppins-SemiBold.ttf";

import PoppinsThin from "../../../../../Assets/Fonts/Poppins-Thin.ttf";

import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';

import DocumentHeader from "./Components/Header";

import DocumentFooter from "./Components/Footer";

import DocumentNotices from "./Components/Notices";

import DocumentParameters from "./Components/Parameters";

import DocumentEntities from "./Components/Entities";

import DocumentUserNotes from "./Components/Notes";

import DocumentProducts from "./Components/Products";

Font.register( {

    family: 'Poppins',

    formtat: 'truetype',

    fonts: [

        { src: PoppinsRegular, fontWeight: 400 },

        { src: PoppinsMedium, fontWeight: 500 },

        { src: PoppinsSemiBold, fontWeight: 600 },

        { src: PoppinsBold, fontWeight: 700 },

        { src: PoppinsExtraBold, fontWeight: 800 },

        { src: PoppinsBlack, fontWeight: 900 },

        { src: PoppinsThin, fontWeight: 100 },

        { src: PoppinsExtraLight, fontWeight: 200 },

        { src: PoppinsLight, fontWeight: 300 }

    ]

} );

export default function PDFDocument ( { state, contacts, type, order, products } ) {

    return (

        <Document>

            <Page size="A4" style={ { flexDirection: "column", gap: 10, fontFamily: "Poppins", fontWeight: 400 } }>

                <View style={ { flexDirection: "column", gap: 5, minHeight: "100vh", padding: 10, fontSize: 9 } }>

                    <DocumentHeader />

                    <View style={ { flex: 1, gap: 10 } }>

                        <Text style={ { fontSize: 12, fontWeight: 600 } }>

                            { type === "invoice" ? "Avansa Rēķins" : type === "cash" ? "Rēķins" : "Rēķins" }

                        </Text>

                        <DocumentParameters state={ state } />

                        <DocumentEntities state={ state } contacts={ contacts } />

                        <DocumentProducts order={ order?.orderPriceCalculator || {} } products={ products.product.edges } />

                        <DocumentUserNotes message={ state?.userNotes || "" } />

                        <DocumentNotices />

                    </View>

                    <DocumentFooter contacts={ contacts } />

                </View>

            </Page>

        </Document>

    );

};