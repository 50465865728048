/** @import modules */

import { Fragment } from "react";

/** @import components */

import CompareActions from "./Actions";

import ActionNotification from "../ActionNotifications";

const Compare = ( { productId, type, notifications } ) => {

    /** @returns */

    return ( <Fragment>

        { notifications.view.length > 0 && 

        <ActionNotification 
        
            data={ notifications.view } /> }

        <CompareActions 
        
            productId={ productId } 

            type={ type }
        
            notifications={ { view: notifications.view, set: notifications.set } } />

    </Fragment> );

}

/** @exports Compare */
 
export default Compare;