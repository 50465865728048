/** Import modules */

import { useParams, useNavigate }   from "react-router-dom";

import { useTranslation }           from "react-i18next";

/** Import datasets */

import { SortingOptions }           from "../../../../../Datasets/Products";

/** Import icons */

import { BsFillCaretDownFill }      from "react-icons/bs";

/** Import hooks */

import { RimsNavigator }            from "../../../../../Hooks/RimsNavigator";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Product sorting component
 * 
 */

const Sorting = ( { attributes } ) => { const 

    { t }               = useTranslation    (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    selected            = new URLSearchParams ( parameters ).get ( "sort" ) || "default",
    
    Sort = event => { const 

        object = { f : 12, l : null, b : null, a : null, 
            
            sort: ( event.target.value === "default" ) ? null : event.target.value };

        return navigate ( `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }/${ 
            
            new URLSearchParams ( RimsNavigator ( parameters, object ) ).toString () }` );

    }

    /** Return component */

    return ( <div className="sort">

        <span>
            
            { t ( "sort_by" ) }
            
        </span>

        <label className="select shadow-select">

            <select onChange={ e => Sort ( e )  } defaultValue={ selected }>

                { SortingOptions ().map ( ( option, index ) => <option 
                
                value={ option.value } 
                
                key={ index }>

                    { option.name }

                </option> ) }

            </select>

            <span>
                
                <BsFillCaretDownFill />
                
            </span>

        </label>

    </div> );

}

/** Export component */
 
export default Sorting;