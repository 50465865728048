import { gql } from "@apollo/client";

const 

FrontEndPages = gql`query FrontEndPagesQuery ( $pageType: FrontendPagesTypesEnum, $language: FrontendPagesLanguagesEnum ) { 
    
    frontendPages ( pageType: $pageType, language: $language ) { 

    edges { node {

        id

        content

        language

    } }
          
} }`;

/** Export queries */

export { 
    
    FrontEndPages 

};