import { useNavigate } from "react-router-dom";

import { 
    
    HiChevronRight, 
    
    HiChevronLeft, 
    
    HiChevronDoubleLeft }       from "react-icons/hi";

const BlogPagination = ( { pageInfo } ) => { const navigate = useNavigate();

    /** Return component */

    return ( <div className="pagination">

        { pageInfo.hasPreviousPage &&
        
            <button onClick={ () => navigate ( `/blog` ) }>
                
                <HiChevronDoubleLeft />
                
            </button> }

        <button 
        
            disabled={ ! pageInfo.hasPreviousPage } 
            
            onClick={ () => {

                navigate ( `/blog/${ new URLSearchParams ( { l: 6, b: pageInfo.startCursor } ).toString () }` );

            } }>
                
                <HiChevronLeft />
                
            </button>

        <button 
        
            disabled={ ! pageInfo.hasNextPage  } 
            
            onClick={ () => {

                navigate ( `/blog/${ new URLSearchParams ( { f: 6, a: pageInfo.endCursor } ).toString () }` );

            } }>
                
                <HiChevronRight />
                
            </button>

    </div> );

}

/** Export component */
 
export default BlogPagination;