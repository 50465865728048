/** @import modules */

import { gql } from "@apollo/client";

const 

/** Services collection */

TECH_INFO = gql`query { technicalInformation ( first: 12 ) { edges { node {

    id

    image

    title

    description

    content

    isPublished
          
    createdAt
    
    updatedAt
    
    language

} } } }`;

/** @export queries */

export { TECH_INFO }