/** Import modules */

import { useTranslation }       from "react-i18next";

/** Import icons */

import { 
    
    HiChevronRight, 
    
    HiChevronLeft, 
    
    HiChevronDoubleLeft }       from "react-icons/hi";

const 

/** Product sorting dataset */

SortingOptions = () => { const

    { t } = useTranslation ();

    return [

        { value: "default", name: t ( "popularity"  ) },

        { value: "DESC",    name: t ( "descending"  ) },

        { value: "ASC",     name: t ( "ascending"   ) }

    ]

},

/** Product catalog pagination */

ProductsPagination = ( pageInfo ) => {

    return [

        { 
            
            icon            : <HiChevronDoubleLeft />, 
            
            query           : { 
                
                f           : 12, 
                
                l           : null, 
                
                a           : null, 
                
                b           : null }, 
            
            state           : ! pageInfo.hasPreviousPage,
        
            disableState    : pageInfo.hasPreviousPage },

        { 
            
            icon            : <HiChevronLeft />, 
            
            query           : { 
                
                l           : 12, 
                
                b           : pageInfo.startCursor, 
                
                a           : null, 
                
                f           : null }, 
            
            state           : ! pageInfo.hasPreviousPage,
        
            disableState    : true },

        { 
            
            icon            : <HiChevronRight />, 
            
            query           : { 
                
                f           : 12, 
                
                a           : pageInfo.endCursor, 
                
                l           : null, 
                
                b           : null }, 
            
            state           : ! pageInfo.hasNextPage,
        
            disableState    : true }

    ];

}

/** Export datasets */

export { 
    
    SortingOptions, 
    
    ProductsPagination

}