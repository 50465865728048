/** @import modules */

import cookies                      from "js-cookie";

import { useParams, useNavigate }   from "react-router-dom";

import { useTranslation }           from "react-i18next";

/** @import models */

import { NavigationModel }          from "../../Models/Services";

/** @import icons */

import { BsFillCaretDownFill }      from "react-icons/bs";

const NavigationSelect = () => { const 

    { loading, data }   = NavigationModel   (),

    { t }               = useTranslation    (),

    { serviceId }       = useParams         (),

    navigate            = useNavigate       (),

    Action = id => {

        if ( id === "default" ) return navigate ( `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_services__" ) }` );

        navigate ( `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_services__" ) }/${ id }` )

    };

    /** @return component */

    return ( <div className="mobile-aside">

        <label className="select shadow-select">

            <select onChange={ e => Action ( e.target.value ) } value={ serviceId || "default" }>

                <option value="default">

                    { loading ? `${ t ( "loading" ) }...` : t ( "select_option" ) }

                </option>

                { ! loading && data.services.edges.filter ( 
                    
                    obj => obj.node.language === cookies.get ( 'i18next' ) && obj.node.isActive === true ).map ( ( service, index ) => 
                    
                <option key={ index } value={ service.node.id }>

                    { service.node.title }

                </option> ) }

            </select>

            <span>
                
                <BsFillCaretDownFill />
                
            </span>

        </label>

    </div> );

}

/** @export NavigationSelect */
 
export default NavigationSelect;