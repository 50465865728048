/** @import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import parse                    from 'html-react-parser';

/** @import models */

import TechInfoModel            from "../../Models/TechnicalModel";

/** @import components */

import Breadcrumbs              from "../../Components/Layout/Header/Breadcrumbs";

import Layout                   from "../../Components/Technical/Layout";

import ComponentLoader          from "../../Components/Layout/Loaders/Loader";

/**
 * 
 * @returns Services page
 * 
 */

const SingleInfo = () => { const { t } = useTranslation (),

    { id } = useParams (),

    { loading, data } = TechInfoModel ();

    /** @return component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "technical_info" ), URI: `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_techinfo__" ) }` },

            { name: loading ? `${ t ( "loading" ) }...` : data.technicalInformation.edges.filter ( obj => obj.node.id === id )[0].node.title, URI: `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_techinfo__" ) }` }

        ] } type="basic" />

        <Layout title={ t ( "technical_info" ) }>

            <div className="paragraph">

                { loading ? <ComponentLoader /> : parse ( data.technicalInformation.edges.filter ( obj => obj.node.id === id )[0].node.content ) }

            </div>

        </Layout>
        
    </div> );

}

/** @export SingleInfo */
 
export default SingleInfo;