/** @import assets */

import "../../Styles/Montage.css";

/**
 * 
 * @param { children} param0 
 * 
 * @returns montage layout component
 * 
 */

const MontageLayout = ( { children } ) => {

    /** Return component */

    return ( <div className="content montage">
    
        <div className="layout">

            <section>{ children }</section>

        </div>
    
    </div> );

}

/** Export component */
 
export default MontageLayout;