/** Import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import { 
    
    useEffect, 
    
    useRef, 
    
    useState }                  from "react";

import usePosition              from "../../../Hooks/usePosition";

import { Helmet }               from "react-helmet-async";

/** Import models */

import Collection               from "../../../Models/TyresCollection";

/** Import hooks */

import Display                  from "../../../Hooks/ProductDisplay";

/** Import components */

import TyresLayout              from "../../../Components/Products/Tyres/Catalog/Layout";

import Breadcrumbs              from "../../../Components/Layout/Header/Breadcrumbs";

import Products                 from "../../../Components/Products/Tyres/Catalog/Collection";

import Pagination               from "../../../Components/Products/Tyres/Catalog/Pagination";

import Actions                  from "../../../Components/Products/Tyres/Catalog/Actions";

import NoProductsFound          from "../../../Components/Products/Shared/Catalog/NoProductsFound";

import ComponentLoader          from "../../../Components/Layout/Loaders/Loader";

/**
 * 
 * @returns Blog page
 * 
 */

const TyresCatalog = () => { const 

    routeParams                         = useParams ().parameters,

    { loading, data }                   = Collection ( routeParams ),

    isMounted                           = useRef ( false ),

    [ notification, setNotification ]   = useState ( 0 ),
    
    { t }                               = useTranslation (),

    { layout, setLayout }               = Display (),

    filters                             = useRef ( null ),

    [ 
        
        mobileFilters, 
        
        setMobileFilters ]  = useState ( false ),

    /** Product loader */
    
    ProductCatalog = () => { if ( loading ) return <ComponentLoader />

        if ( ! data.multiFieldProductSearch.edges.length ) return <NoProductsFound />

        return <Products 
        
            layout={ layout } 
        
            products={ data.multiFieldProductSearch.edges } 
        
            notice={ { view: notification, set: setNotification } } />

    };

    useEffect ( () => {

        if (isMounted.current) {
            const timer = setTimeout(() => setNotification ( "" ), 1000);
            return () => clearTimeout(timer);
          } else {

            isMounted.current = true;
          }

    }, [ notification ] );

    usePosition ();

    /** Render component */

    return ( <div className="container max-content-width">

         <Helmet>

            <title>{ `Tireshop.lv | ${ t ( "tyres" ) }` }</title>

            <link rel="canonical" href="/shop/products/tyres/" />

        </Helmet>

        <Breadcrumbs collection={ [

            { name: t ( "tyres" ), URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }` }

        ] } />

        <TyresLayout title={ t ( "tyres" ) } filters={ { view: mobileFilters, set: setMobileFilters, element: filters } }>

            <Actions layout={ { view: layout, set: setLayout } } mobile={ { view: mobileFilters, set: setMobileFilters, element: filters } } />

            <ProductCatalog />

            { ! loading && 

                <Pagination pageInfo={ data.multiFieldProductSearch.pageInfo } />

            }

        </TyresLayout>
        
    </div> );

}

/** Export component */
 
export default TyresCatalog;