/** Import modules */

import { useTranslation }   from "react-i18next";

import { Helmet }           from "react-helmet-async";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import Layout               from "../../Components/Services/Layout";

import List                 from "../../Components/Services/Services";

/**
 * 
 * @returns Services page
 * 
 */

const Services = () => { const { t } = useTranslation ();

    /** Render component */

    return ( <div className="container max-content-width">

        <Helmet>

            <title>{ `Tireshop.lv | ${ t ( "services" ) }` }</title>

            <link rel="canonical" href={ `/content/services` } />

        </Helmet>

        <Breadcrumbs collection={ [

            { name: t ( "services" ), URI: `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_services__" ) }` }

        ] } />

        <Layout title={ t ( "services" ) }>

            <List />

        </Layout>
        
    </div> );

}

/** Export component */
 
export default Services;