/** @module */

import { useEffect }    from 'react';

import ReactGA          from 'react-ga';

/**
 * 
 * @returns google analytics
 * 
 */

const Analytics = () => { const TRACKING_ID = "G-BZP9WZWZLR";

    ReactGA.initialize ( TRACKING_ID );

    useEffect ( () => {

        ReactGA.pageview ( window.location.pathname + window.location.search );

    }, [] );

}

/** @exports Analytics */
 
export default Analytics;