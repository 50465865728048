/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import components */

import Breadcrumbs          from "../../../Components/Layout/Header/Breadcrumbs";

import TempNotice           from "../../../Components/TempNotice";

/**
 * 
 * @returns Sale page
 * 
 */

const Sale = () => { const { t } = useTranslation ();

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "sale" ), URI: "/shop/products/sale" }

        ] } />

        <TempNotice type="undefined" notice="Nobody can answer, how it will work or how it will look." />
        
    </div> );

}

/** Export component */
 
export default Sale;