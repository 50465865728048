const InputController = ( { id, label, state, placeholder = false } ) => {

    /** @returns component */

    return ( <div className="fields">

        <div className="form-fields">

            <label htmlFor={ id }>
                
                { label }
                
            </label>

            <input 
            
                type="text" 
            
                name={ id } 
            
                id={ id } 

                placeholder={ placeholder }
            
                defaultValue={ state.view } 
            
                onChange={ e => state.set ( e.target.value ) } />

        </div>

    </div> );

}

/** @exports InputController */
 
export default InputController;