import { useTranslation } from "react-i18next";

const 

/** @return Single tyre permalink */

TyrePermalinks = ( product ) => { const { t } = useTranslation ();
    
    let permalink = `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_tyre__" ) }`; const 

    params = {

        id              : product.id,

        manufacturer    : encodeURIComponent ( product.manufacturer.name.toUpperCase () ),

        model           : encodeURIComponent ( product.model ),

        size            : `${ product.width }-${ product.height }-${ product.diameter }`

    }, 
    
    object = Object.values ( params );

    /** Concat parameters */

    for ( let index = 0; object.length > index; index ++ ) {

        permalink = permalink.concat ( `/${ object[ index ] }` );

    }

    /** Return permalink */

    return permalink;

},

/** @return Single rim permalink */



RimPermalinks = ( product ) => { const { t } = useTranslation ();
    
    let permalink = `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_rim__" ) }`; const 

    params = {

        id              : product.id,

        manufacturer    : encodeURIComponent ( product.manufacturer.name.toUpperCase () ),

        model           : encodeURIComponent ( product.model ),

        size            : `${ product.width }-${ product.diameter }`

    }, 
    
    object = Object.values ( params );

    /** Concat parameters */

    for ( let index = 0; object.length > index; index ++ ) {

        permalink = permalink.concat ( `/${ object[ index ] }` );

    }

    /** Return permalink */

    return permalink;

},

/** @return Single battery permalink */

BatteryPermalinks = ( product ) => { let permalink = "/shop/battery"; const 

    params = {

        id              : product.id,

        manufacturer    : encodeURIComponent ( product.manufacturer.name.toUpperCase () ),

        model           : encodeURIComponent ( product.model )

    }, 
    
    object = Object.values ( params );

    /** Concat parameters */

    for ( let index = 0; object.length > index; index ++ ) {

        permalink = permalink.concat ( `/${ object[ index ] }` );

    }

    /** Return permalink */

    return permalink;

};

/** Export misc */

export { TyrePermalinks, RimPermalinks, BatteryPermalinks }