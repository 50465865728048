/** @import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

/** @import components */

import Breadcrumbs              from "../../Components/Layout/Header/Breadcrumbs";

import MontageLayout            from "../../Components/Montage/Layout";

import EliminateAppointment     from "../../Components/Montage/Eliminate/Appointment";

/**
 * 
 * @returns montage page
 * 
 */

const MontageEliminate = () => { const { t } = useTranslation (),

    { uuid } = useParams ();

    /** @return component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "montage" ), URI: "/content/montage" },

            { name: t ( "eliminate_montage_appointment" ), URI: "#" }

        ] } />

        <MontageLayout>

            <div className="title">
                
                <h2>

                    { t ( "eliminate_montage_appointment" ) }

                </h2>
                
            </div>

            <EliminateAppointment appointment={ uuid } />

        </MontageLayout>
        
    </div> );

}

/** @export component */
 
export default MontageEliminate;