/** @import models */

import ContactsModel            from "../../Models/Contacts";

/** @import components */

import ContactsInformation      from "./Information";

import Requisites               from "./Requisites";

import Social                   from "./Social";

import Location                 from "./Map";

import ContactsForm             from "./Form";

import ComponentLoader          from "../Layout/Loaders/Loader";

const ContactsLayout = () => { const

    { loading, data }  = ContactsModel ();

    /** @return component */

    return ( <div className="contacts-information">

        <div className="left">

            { loading ? <ComponentLoader /> : <ContactsInformation data={ { contacts: data.contactInfo.contact_info, hours: data.contactInfo.working_hours } } /> }

            { loading ? <ComponentLoader /> : <Requisites data={ data.contactInfo.requisites } /> }

            <Social />

        </div>

        <div className="right">

            <Location />

            <ContactsForm />

        </div>

    </div> );

}

/** @export ContactsLayout */
 
export default ContactsLayout;