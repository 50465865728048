/** Import modules */

import { useQuery }             from "@apollo/client";

/** Import queries */

import { FilterAttributes }     from "../Queries/Tyres";

/**
 * 
 * @returns Tyres product collection model
 * 
 */

const RimsAttributes = () => { const

    { loading, data } = useQuery ( FilterAttributes );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default RimsAttributes;