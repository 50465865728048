/** @import datasets */

import { SocialNetworks } from "../../Datasets/Contacts";

/**
 * 
 * @returns social networks list
 * 
 */

const Social = () => {

    /** @return component */

    return ( <div className="social-networks">

        { SocialNetworks ().map ( ( network, index ) => <a href={ network.URL } key={ index } target="_blank" rel="noreferrer">
        
            { network.icon }
        
        </a> ) }

    </div> );

}
 
/** @export Social */

export default Social;