/** @import modules */

import { useTranslation }           from "react-i18next";

import { useParams, useNavigate }   from "react-router-dom";

import { Swiper, SwiperSlide }      from "swiper/react";

import { FreeMode, Navigation } from "swiper";

/** @import models */

import TyresAttributes              from "../../Models/TyresFilters";

/** @import modules */

import { TyresNavigator }           from "../../Hooks/TyresNavigator";

import { useEffect, useState }                 from "react";

/** @import misc */

import seasonSelector               from "../../Misc/SeasonSelector";

/** @import components */

import ComponentLoader              from "../Layout/Loaders/Loader";

/** Import icons */

import { BsFillCaretDownFill }      from "react-icons/bs";

import searchImage from "../../Assets/search-image-5.jpg";

import "swiper/css";

import "swiper/css/navigation";

const HomeProductSearch = () => { const { t } = useTranslation (),

    { loading, data } = TyresAttributes (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    [ width, setWidth ] = useState ( "default" ),

    [ height, setHeight ] = useState ( "default" ),

    [ diameter, setDiameter ] = useState ( "default" ),

    [ season, setSeason ] = useState ( "default" ),

    [ type, setType ] = useState ( "default" ),

    Properties = () => {

        if ( loading ) return <ComponentLoader />

        const { heights, widths, diameters, seasons } = data.searchAttributes.tires[0],

        Width       = [ ...widths    ].sort( ( a, b ) => parseInt      ( a.value ) - parseInt      ( b.value ) ),

        Height      = [ ...heights   ].sort( ( a, b ) => parseFloat    ( a.value ) - parseFloat    ( b.value ) ),

        Diameter    = [ ...diameters ].sort( ( a, b ) => parseFloat    ( a.value ) - parseFloat    ( b.value ) );

        const fields = [

            { name: t ( "width" ), slug: "width", options: Width, type: "INT", state: width, set: setWidth },

            { name: t ( "height" ), slug: "height", options: Height, type: "FLOAT", state: height, set: setHeight },

            { name: t ( "diameter" ), slug: "diameter", options: Diameter, type: "TEXT", state: diameter, set: setDiameter },

            { name: t ( "season" ), slug: "season", options: seasons, type: "TEXT", state: season, set: setSeason }

        ];

        return fields.map ( ( field, index ) => <div className="field" key={ index }>

            <div className="name hide-768">{ field.name }</div>

            <div className="search-select">

                <label>

                    <select onChange={ e => field.set ( e.target.value ) } defaultValue={ field.state }>

                        <option value="default">-- { t ( field.slug ) } --</option>

                        { field.options.map ( ( option, key ) => <option 
                        
                        value={ ( field.type === "INT" ? parseInt ( option.value ) : field.type === "FLOAT" ? parseFloat ( option.value ) : option.value ) } key={ key }>

                            { field.type === "INT"   && parseInt     ( option.text ) }

                            { field.type === "FLOAT" && parseFloat   ( option.text ) }

                            { field.type === "TEXT" && t ( option.text ) }

                        </option> ) }

                    </select>

                    <span>
            
                        <BsFillCaretDownFill />
                        
                    </span>

                </label>

            </div>

        </div> )

    },

    search = () => {

        const object = { 
            
            f : 12, 
            
            l: null, 
            
            a: null, 
            
            b: null, 
            
            w: ( width === "default" ) ? null : width, 
            
            h: ( height === "default" ) ? null : height, 
            
            d: ( diameter === "default" ) ? null : diameter, 
            
            s: ( season === "default" ) ? null : season,
        
            vt: ( type === "default" ) ? null : type,
        
            sort: "ASC" };

        return navigate (  
            
            `${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
            
                new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

    }

    useEffect ( () => {

        if ( type === "default" ) { setWidth ( "default" ); setHeight ( "default" ); setDiameter ( "default" ); setSeason ( "default" ) }

        if ( type === "passenger_car" ) { setWidth ( 205 ); setHeight ( 55 ); setDiameter ( 16 ); setSeason ( seasonSelector () ) }

        if ( type === "truck" ) { setWidth ( 315 ); setHeight ( 80 ); setDiameter ( 22.5 ); setSeason ( seasonSelector () ) }

        if ( type === "moto" ) { setWidth ( 120 ); setHeight ( 70 ); setDiameter ( 17 ); setSeason ( seasonSelector () ) }

        if ( type === "4x4" ) { setWidth ( 235 ); setHeight ( 60 ); setDiameter ( 18 ); setSeason ( seasonSelector () ) }

        if ( type === "bus" ) { setWidth ( 235 ); setHeight ( 65 ); setDiameter ( 16 ); setSeason ( seasonSelector () ) }

    }, [ type ] );

    /** @return component */

    return ( <div className="search">

        <img src={ searchImage } alt="product-search" />

        <div className="content max-content-width">

            <div className="search-heading">

                <h1>{ t ( "search_tires" ) }</h1>

            </div>

            <div className="properties">
                
                <Properties />
                
            </div>

            <div className="action">

                <button onClick={ search }>{ t ( "search" ) }</button>

            </div>

            { ! loading &&

            <Swiper 
        
            slidesPerView={ 1 } 

            freeMode={ true }
            
            navigation={ true } 
            
            modules={ [ Navigation, FreeMode ] } 
            
            className="swiper-vehicle-types">

                <SwiperSlide>

                <div className="type-option">

                    <input type="radio" id="default" name="mobile_vehicle_types" value="default" checked={ type === "default" } onChange={ () => setType ( "default" ) } />

                    <label htmlFor="default">

                        { t ( "all" ) }

                    </label>

                </div>

                </SwiperSlide>

                { data.searchAttributes.tires[0].vehicle_types.map ( ( vType, index ) => <SwiperSlide key={ index }>
                
                    <div className="type-option" key={ index }>

                        <input type="radio" id={ vType.value } name="mobile_vehicle_types" value={ vType.value } checked={ type === vType.value } onChange={ () => setType ( vType.value ) } />

                        <label htmlFor={ vType.value }>

                            { t ( vType.value ) }

                        </label>

                    </div> 
                
                </SwiperSlide> ) }

            </Swiper>}

            { ! loading && <div className="vehicle-type">

                <div className="type-option">

                    <input type="radio" id="default" name="vehicle_types" value="default" checked={ type === "default" } onChange={ () => setType ( "default" ) } />

                    <label htmlFor="default">

                        { t ( "all" ) }

                    </label>

                </div>
                
                { data.searchAttributes.tires[0].vehicle_types.map ( ( vType, index ) => <div className="type-option" key={ index }>

                    <input type="radio" id={ vType.value } name="vehicle_types" value={ vType.value } checked={ type === vType.value } onChange={ () => setType ( vType.value ) } />

                    <label htmlFor={ vType.value }>

                        { t ( vType.value ) }

                    </label>

                </div> ) }
                
            </div> }

        </div>

    </div> );

}

/** @export component */
 
export default HomeProductSearch;