/** @import modules */

import { useQuery }             from "@apollo/client";

/** @import queries */

import { BANNERS }              from "../Queries/Banners";

/** @returns banners */

const Banners = () => { const

    { loading, data } = useQuery ( BANNERS );

    /** @returns */

    return { loading, data }

}

/** @export Banners */

export default Banners;