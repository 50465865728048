/** @import modules */

import { useTranslation }       from "react-i18next";

/** @import components */

import Voltages                 from "../Components/Products/Batteries/Catalog/Filters/Voltages";

import Currents                 from "../Components/Products/Batteries/Catalog/Filters/Currents";

import Capacities               from "../Components/Products/Batteries/Catalog/Filters/Capacities";

import Manufactures             from "../Components/Products/Batteries/Catalog/Filters/Manufacturer";

import AvailabilityFilter       from "../Components/Products/Batteries/Catalog/Filters/Availability";

import PriceRange               from "../Components/Products/Batteries/Catalog/Filters/Price";

const 

/** @return filter widgets */

FiltersCollection = ( data ) => { const { t } = useTranslation (),

    { 
        
        voltages, 
        
        currents, 
        
        capacities,

        manufacturers,

        min_price,

        max_price
    
    } = data.searchAttributes.batteries[0],

    Current         = [ ...currents     ].sort ( ( a, b ) => parseFloat ( a.value ) - parseFloat ( b.value ) ),

    Voltage         = [ ...voltages     ].sort ( ( a, b ) => parseFloat ( a.value ) - parseFloat ( b.value ) ),

    Capacity        = [ ...capacities   ].sort ( ( a, b ) => parseFloat ( a.value ) - parseFloat ( b.value ) ),
    
    Manufacturers   = [ ...manufacturers ].sort( ( a, b ) => a.text.localeCompare ( b.text ) );

    return [

        { 
            
            title: t ( "availability" ), 
        
            content: <AvailabilityFilter />, 
        
            toggle: true,
        
            position: "open" },

        { 
            
            title: t ( "manufacturer" ), 
        
            content: <Manufactures attributes={ Manufacturers } />, 
        
            toggle: false },

        { 
    
            title: t ( "price_range" ), 
        
            content: <PriceRange min={ min_price } max={ max_price } />, 
        
            toggle: true,
        
            position: "open" },

        { 
            
            title: t ( "voltages" ), 
        
            content: <Voltages attributes={ Voltage } />, 
        
            toggle: false },

        { 
        
            title: t ( "currents" ), 
        
            content: <Currents attributes={ Current } />, 
        
            toggle: false },

        { 
    
            title: t ( "capacities" ), 
        
            content: <Capacities attributes={ Capacity } />, 
        
            toggle: false }

    ];

};

/** @export datasets */

export { 
    
    FiltersCollection

}