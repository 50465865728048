/** @import modules */

import { Fragment } from "react";

import { useTranslation } from "react-i18next";

/** @import icons */

import { AiOutlineColumnWidth } from "react-icons/ai";

import { LuGaugeCircle } from "react-icons/lu";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Product properties component
 * 
 */

const Properties = ( { product } ) => { const { t } = useTranslation (),

    properties_collection = [

        { 
            
            icon: <AiOutlineColumnWidth />, 

            type: "tag",
            
            value: ( product.width === 0 ) ? null : product.width, 

            label: product.width,
            
            tooltip: t ( "width" ),
        
            case: "lowercase" },

        { 
            
            icon: <LuGaugeCircle />, 

            type: "tag",
            
            value: ( product.diameter === 0 ) ? null : product.diameter, 

            label: `R${ parseInt ( product.diameter ) }`,
            
            tooltip: t ( "radius" ),
        
            case: "lowercase" }

    ],

    properties = properties_collection.filter ( item => item.value !== null );

    /** Return component */

    return ( <div className="product-props properties">

        { properties.map ( ( prop, index ) => <Fragment key={ index }>

            { prop.type === "chart" && <div className="prop">

                <div className="props-tooltip">

                    { prop.tooltip }

                </div>

                <div className="icon">
                    
                    { prop.icon }
                
                </div>

                <div className={ `value property-color-${ prop.color } ${ prop.case }` }>
                    
                    { prop.label }
                    
                </div>

            </div> }

            { prop.type === "tag" && <div className="prop">

                <div className="props-tooltip">

                    { prop.tooltip }

                </div>

                <div className="icon">

                    { prop.icon }

                </div>

                <div className="value-numeric">

                    { prop.label }

                </div>

            </div> }

            { prop.type === "text" && <div className="prop">

                <div className="props-tooltip">

                    { prop.tooltip }

                </div>

                { prop.type }

            </div> }

        </Fragment> ) }

    </div> );

}

/** Export component */
 
export default Properties;