/** @import modules */

import { useTranslation }   from "react-i18next";

import cookies              from "js-cookie";

import { Link }             from "react-router-dom";

/** @import models */

import { ServicesModel }    from "../../Models/Services";

/** @import components */

import HomeHeading          from "./Heading";

import ComponentLoader      from "../Layout/Loaders/Loader";

import NoImage              from "../../Assets/no-image.jpg";

/**
 * 
 * @returns Home services component
 * 
 */

const HomeServices = () => { const { t } = useTranslation (),

    { loading, dataset } = ServicesModel (),

    Services = ( { object } ) => { const 
        
        filter = object.services.edges.filter ( obj => obj.node.language === cookies.get ( 'i18next' ) && obj.node.isActive === true );

        return filter.slice ( 0, 6 ).map ( ( service, index ) => <Link to={ `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_services__" ) }/${ service.node.id }/${ encodeURIComponent ( service.node.title ) }` } key={ index }>

            <img src={ service.node.image } alt={ service.node.title } onError={ ( { currentTarget } ) => {

                currentTarget.onerror = null;

                currentTarget.src = NoImage;

            } } />

            <div className="title">

                { service.node.title }

            </div>

        </Link> );

    };

    /** Return component */

    return ( <div className="max-content-width">
    
        <div className="services">

            <HomeHeading 
            
                title={ t ( "goods_and_services" ) } 
            
                description={ t ( "goods_and_services_desc" ) } />

            { loading ? <ComponentLoader /> : 

                <div className="content">

                    <Services object={ dataset } />

                </div> }

            <div className="view-more">

                <Link to={ `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_services__" ) }` }>{ t ( "view_all_services" ) }</Link>

            </div>

        </div>
    
    </div>);

}

/** Export component */
 
export default HomeServices;