/** Import assets */

import "../../../../Styles/Products.css";

/** Import components */

import Filters from "./Filters";

import AsideBanners from "../../../Shared/AsideBanners";

/**
 * 
 * @param { title, children } param0 
 * 
 * @returns Tyres catalog layout component
 * 
 */

const TyresLayout = ( { title, children, filters } ) => {

    /** Return component */

    return ( <div className="content product-catalog tyres-catalog">
    
        <div className="layout">

            <aside>

                <div className="title">
                
                    <h2>{ title }</h2>
                
                </div>

                <Filters attributes={ filters } />

                { filters.view === true && <div className="mobile-shadow" onClick={ () => {

                    filters.set ( false );

                    return filters.element.current.classList.remove ( "mobile-filters-state" );

                } }></div> }

                <AsideBanners />

            </aside>

            <section>{ children }</section>

        </div>
    
    </div> );

}

/** Export component */
 
export default TyresLayout;