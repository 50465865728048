/** Import modules */

import { NavLink } from "react-router-dom";

/**
 * 
 * @param {routes} param0 
 * 
 * @returns Footer navigation component
 * 
 */

const FooterNavigation = ( { routes } ) => {

    /** Return component */

    return (<div className="footer-navigation">

        <nav>

            <ul>

                { routes.map ( ( route, index ) => 
                
                    <li key={ index }>

                        <NavLink to={ route.route } className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                            
                            { route.name }
                            
                        </NavLink>

                    </li>

                ) }

            </ul>

        </nav>

    </div>);

}

/** Export component */
 
export default FooterNavigation;