/** Import modules */

import { useTranslation }               from "react-i18next";

import { useNavigate, useParams }       from "react-router-dom";

/** Import hooks */

import { TyresNavigator }               from "../../../../../Hooks/TyresNavigator";

/** Import icons */

import { FaCheck }                      from "react-icons/fa";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Seasons filter component
 * 
 */

const Seasons = ( { attributes } ) => { const 
    
    { t } = useTranslation (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    params              = new URLSearchParams ( parameters ),

    LoadSeasons = event => { const 

        object = { f : 12, l : null, a : null, b : null, s : params.get ( "s" ) ? params.get ( "s" ).split ( "," ) : [], loc: window.scrollY };

        if ( event.target.checked ) { object.s.push ( event.target.value );

            return navigate (  
            
                `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );
        
        } else { object.s.splice ( params.get ( "s" ).split ( "," ).indexOf ( event.target.value ), 1);

            return navigate (  
            
                `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

        }

    };

    /** Return component */

    return ( <div className="content seasons">

        { attributes.map ( ( season, index ) => <label htmlFor={ season.value } className="checkbox" key={ index }>

            <input 
            
                type="checkbox" 
            
                id={ season.value } 
            
                value={ season.value } 
            
                onChange={ e => LoadSeasons ( e ) } 
                
                checked={ params.get ( "s" ) && ( params.get ( "s" ).split ( "," ).indexOf ( season.value ) !== -1 ) } />

            <span className="check">

                <span className="checked">

                    <FaCheck />

                </span>

            </span>

            <span className="name">

                { t ( season.text ) }

            </span>

        </label> ) }

    </div> );

}

/** Export component */
 
export default Seasons;