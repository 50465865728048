/** @import modules */

import { useState, useEffect } from "react";

/** Fetch stored policy from local storage */

const policyStorage = JSON.parse ( localStorage.getItem ( "cookie_policy" ) ) || JSON.parse ( false );

/**
 * 
 * @returns Policy hook
 * 
 */

export const usePolicy = () => { const

    [ policy, setPolicy ] = useState ( policyStorage );

    useEffect ( () => {

        localStorage.setItem ( "cookie_policy", JSON.stringify ( policy ) );

    }, [ policy, setPolicy ] );

    /** @returns hook data */

    return { view: policy, set: setPolicy };

}