/** Import datasets */

import HeaderDatasets               from "../../../Datasets/Header";

/** Import modules */

import { NavLink }                  from "react-router-dom";

/** Import swiper modules */

import { Swiper, SwiperSlide }      from "swiper/react";

import  SwiperCore, { FreeMode, Navigation }    from 'swiper';

/** Middleware */

SwiperCore.use ( [ FreeMode ] );

/**
 * 
 * @returns Header swiper navigation component
 */

const SwiperNavigation = () => {

    /** Return component */

    return (<div className="swiper-navigation">

        <Swiper slidesPerView={ 2.5 } spaceBetween={ 5 } loop={ false } freeMode={ true } modules={ [ FreeMode, Navigation ] } navigation={ true }>

            { HeaderDatasets ().ShopCollection.map ( ( item, index ) => { 

                return <SwiperSlide key={ index }>
                    
                    <NavLink to={ item.route } className={({ isActive }) => (isActive ? ( item.accent ) ? "active accented" : "active" : ( item.accent ) ? "inactive accented" : "inactive")}>
                        
                        { item.name }
                        
                    </NavLink>
                    
                </SwiperSlide>

            } ) }

        </Swiper>

    </div>);
}

/** Export component */
 
export default SwiperNavigation;