import { Fragment } from "react";

import Banners from "../../Models/Banners";

import ComponentLoader from "../Layout/Loaders/Loader";

import cookies from "js-cookie";

/** @import assets */

import "../../Styles/Shared/AsideBanners.css";

import NoImage from "../../Assets/no-image.jpg";

/**
 * 
 * @returns banners for aside components
 * 
 */

const AsideBanners = () => { const { data, loading } = Banners (),

    validateBanners = banners => { const

        validate = banners.filter ( ( curr ) => { 
                
            return Date.parse ( curr.tillDate ) > new Date () 
            
            && 
            
            Date.parse ( curr.fromDate ) < new Date () 
            
            &&
            
            curr.language === cookies.get ( 'i18next' )

            &&

            curr.isActive === true

            &&

            curr.position === null
        
        } )

        return ( validate.length > 0 ) ? validate[ Math.floor ( Math.random () * validate.length ) ] : false;

    },

    Banner = () => {

        if ( loading ) return <ComponentLoader />

        const banners = validateBanners ( data.banners );

        return ( banners ) ? <div className="aside-banner">

            <a href={ banners.link }>

                <img src={ banners.image } alt={ banners.title } onError={ ( { currentTarget } ) => {

                    currentTarget.onerror = null;

                    currentTarget.src = NoImage;

                } } />

            </a>

        </div> : <Fragment />

    };

    /** @returns */

    return ( <Banner /> );

}

/** @exports AsideBanners */
 
export default AsideBanners;