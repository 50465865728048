/** @import modules */

import { Link } from "react-router-dom";

/** @import misc */

import { RimPermalinks } from "../../../../Misc/Permalinks";

/** @import components */

import Properties from "../../Rims/Catalog/Item/Properties";

/** @import icons */

import { AiFillEye } from "react-icons/ai";

/** @import assets */

import NoProduct from "../../../../Assets/no-rims.jpg";

const Rim = ( { product } ) => { const permalink = RimPermalinks ( product );

    /** @returns component */

    return ( <div className="rim">

        <div className="product-image">

            <Link to={ permalink }>

                { ( ! product.currentImage ) ? 
                
                    <img src={ NoProduct } alt={ product.model } /> 
                    
                    : <img src={ product.currentImage } alt={ product.model } onError={ ( { currentTarget } ) => {

                        currentTarget.src = NoProduct;
            
                    } } /> }

            </Link>

        </div>

        <div className="product-name">

            <Link to={ permalink }>

                <div className="manufacturer">

                    { product.manufacturer.name }

                </div>

                <div className="model">

                    { product.model }

                </div>

            </Link>

            <div className="price">

                &euro; { parseFloat ( product.priceList.original_price ).toFixed ( 2 ) }

            </div>

        </div>

        <div className="product-data">

            <Properties product={ product } />
                    
        </div>

        <div className="product-actions">

            <Link to={ permalink }>

                <AiFillEye />

            </Link>

        </div>

    </div> );

}

/** @exports Rim */
 
export default Rim;