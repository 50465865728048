/** Import modules */

import { useQuery }         from "@apollo/client";

import { useTranslation }   from "react-i18next";

/** Import queries */

import { Team }             from "../../Queries/Contacts";

/** Import components */

import ContactsMember       from "./Member";

import ComponentLoader      from "../Layout/Loaders/Loader";

/**
 * 
 * @returns Contacts team section component
 * 
 */

const ContactsTeam = () => { const 

    { t } = useTranslation (),
    
    { loading, data } = useQuery ( Team ),

    Members = () => {

        if ( loading ) return <ComponentLoader />

        if ( data.contacts.length === 0 ) return <div>- { t ( "no_information" ) }</div>

        return data.contacts.map ( member => <ContactsMember 
            
            key={ member.id } 
            
            member={ member } /> );

    };

    /** Return component */

    return (<div className="team">

        <Members />

    </div>);

}

/** Export component */
 
export default ContactsTeam;