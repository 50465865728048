/** @module                         Hooks */

import { useEffect }                from "react";

/** @module                         Utilities */

import { onCheckoutOption }         from "../../../../Misc/DataLayer";

/** @module                         React-Icons */

import { FaDotCircle }              from "react-icons/fa";

const 

Payments = ( { state, action, __, data } ) => {

    /** @event useEffect */

    useEffect ( () => {

        action ( {

            target: {

                name: "paymentMethod",

                value: data.orderOptions.payment_method[ 0 ].value

            }

        } );

    }, [] );

    /** @event useEffect */

    useEffect ( () => {

        ( state.paymentMethod ) && onCheckoutOption ( 3, state.paymentMethod ) ;

    }, [ state.paymentMethod ] );

    /** @returns */

    return (

        <div className="sector payment">

            <div className="radios">

                {

                    data.orderOptions.payment_method.map ( ( option, index ) => {

                        return (

                            <div className="radio" key={ index }>

                                <label htmlFor={ option.value } className={ `${ state.paymentMethod === option.value ? "selected" : "default" }` }>

                                    <input 

                                        type="radio" 

                                        id={ option.value } 

                                        name="paymentMethod" 

                                        value={ option.value } 

                                        checked={ state.paymentMethod === option.value ? true : false } 

                                        onChange={ e => action ( e ) } />

                                    <span className="icon">

                                        <span className="checked">

                                            <FaDotCircle />

                                        </span>

                                    </span>

                                    <span className="name">

                                        { __ ( option.value ) }

                                    </span>

                                </label>

                            </div>

                        );

                    } )

                }

            </div>

            { state.paymentMethod === "cash" &&
            
                <div className="notification valid">

                    { __ ( "cash_payment_notice" ) }

                </div>
            
            }

            { state.paymentMethod === "online_card_payment" &&
            
                <div className="notification valid">

                    { __ ( "payment_gateway_notice" ) }

                </div>
            
            }

            { state.paymentMethod === "invoice" &&
            
                <div className="notification valid">

                    { __ ( "bank_transfer_notice" ) }

                </div>
            
            }

        </div>

    );

}

/** @exports Payments */
 
export default Payments;