/** @import modules */

import { Link } from "react-router-dom";

/** @import misc */

import { BatteryPermalinks } from "../../../../Misc/Permalinks";

/** @import icons */

import { AiFillEye } from "react-icons/ai";

/** @import assets */

import NoProduct from "../../../../Assets/no-batteries.jpg";

const Battery = ( { product } ) => { const permalink = BatteryPermalinks ( product );

    /** @returns component */

    return ( <div className="battery">

        <div className="product-image">

            <Link to={ permalink }>

                { ( ! product.currentImage ) ? 
                
                    <img src={ NoProduct } alt={ product.model } /> 
                    
                    : <img src={ product.currentImage } alt={ product.model } onError={ ( { currentTarget } ) => {

                        currentTarget.src = NoProduct;
            
                    } } /> }

            </Link>

        </div>

        <div className="product-name">

            <Link to={ permalink }>

                <div className="manufacturer">

                    { product.manufacturer.name }

                </div>

                <div className="model">

                    { product.model }

                </div>

            </Link>

            <div className="price">

                &euro; { parseFloat ( product.priceList.original_price ).toFixed ( 2 ) }

            </div>

        </div>

        <div className="product-actions">

            <Link to={ permalink }>

                <AiFillEye />

            </Link>

        </div>

    </div> );

}

/** @exports Battery */
 
export default Battery;