/** Import datasets */

import HeaderDatasets   from "../../../../Datasets/Header";

/** Import modules */

import { Link }         from "react-router-dom";

/**
 * 
 * @param {action} param0 
 * 
 * @returns Mobile navigation shop component
 * 
 */

const MobileNavShop = ( { action } ) => {

    /** Return component */

    return (<div className="shop">

        <ul>

            { HeaderDatasets ().Shop.map ( ( attr, index ) => <li key={ index }>

                <Link to={ attr.route } onClick={ () => action.set ( false ) }>

                    { attr.name }

                </Link>

            </li> ) }

        </ul>

    </div>);
    
}

/** Export component */
 
export default MobileNavShop;