/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import TempNotice           from "../../Components/TempNotice";

/**
 * 
 * @returns Gallery page
 * 
 */

const Gallery = () => { const { t } = useTranslation ();

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "gallery" ), URI: "/content/gallery" }

        ] } />

        <TempNotice type="missing" notice="Missing gallery content endpoint" />
        
    </div> );

}

/** Export component */
 
export default Gallery;