export const countries = [

    { name: "latvia", code: "LV" },
    
    { name: "lithuania", code: "LT" },

    { name: "estonia", code: "EE" }

];

export const latvianCities = [

    "Riga",
    "Daugavpils",
    "Liepāja",
    "Jelgava",
    "Jūrmala",
    "Ventspils",
    "Rezekne",
    "Valmiera",
    "Jekabpils",
    "Ogre",
    "Tukums",
    "Cesis",
    "Salaspils",
    "Kuldiga",
    "Saldus",
    "Talsi",
    "Dobele",
    "Kraslava",
    "Ilukste",
    "Ludza",
    "Bauska",
    "Sigulda",
    "Aizkraukle",
    "Gulbene",
    "Limbazi",
    "Madona",
    "Preili",
    "Grobina",
    "Valka",
    "Smiltene",
    "Aizpute",
    "Lielvarde",
    "Lielupe",
    "Ozolnieki",
    "Rujiena",
    "Mazsalaca",
    "Skrunda",
    "Broceni",
    "Olaine",
    "Mores",
    "Iecava",
    "Saulkrasti",
    "Balvi",
    "Vecumnieki",
    "Plavinas",
    "Aloja",
    "Cesvaine",
    "Talsi",
    "Kandava",
    "Vilani",
    "Ape",
    "Kegums",
    "Viesite",
    "Balozi",
    "Dagda",
    "Piltene",
    "Priekule",
    "Subate",
    "Zilupe",
    "Pavilosta",
    "Stende",
    "Vangazi",
    "Limbazi",
    "Smiltene",
    "Varkava",
    "Strenci",
    "Ainazi",
    "Akniste",
    "Aluksne",
    "Aluksne",
    "Ambele",
    "Ape",
    "Auce",
    "Baldone",
    "Baltinava",
    "Balvi",
    "Bauska",
    "Birzgale",
    "Cesis",
    "Cibla",
    "Dagda",
    "Daugavpils",
    "Dobele",
    "Durbe",
    "Grobinа",
    "Gulbene",
    "Iecava",
    "Ilukste",
    "Jaunjelgava",
    "Jekabpils",
    "Jelgava",
    "Kandava",
    "Karsava",
    "Kegums",
    "Koceni",
    "Koknese",
    "Krāslava",
    "Kuldiga",
    "Lielupe",
    "Ligatne",
    "Limbazi",
    "Livanu",
    "Lubana",
    "Ludza",
    "Madona",
    "Mazsalaca",
    "Ogre",
    "Olaine",
    "Pavilosta",
    "Plavinas",
    "Preili",
    "Priekule",
    "Rezekne",
    "Riebini",
    "Riga",
    "Roja",
    "Rujiena",
    "Sabile",
    "Saldus",
    "Salaspils",
    "Saulkrasti",
    "Seda",
    "Sigulda",
    "Skrunda",
    "Smiltene",
    "Stende",
    "Strenci",
    "Subate",
    "Talsi",
    "Tukums",
    "Valdemarpils",
    "Valga",
    "Valka",
    "Vangaži",
    "Varaklāni",
    "Vecpiebalga",
    "Vecumnieki",
    "Ventspils",
    "Viesite",
    "Viļaka",
    "Vilani",
    "Zilupe",
    "Ādaži",
    "Ērgļi",
    "Ķekava",
    "Ķegums",
    "Ķesterciems",
    "Ķūķi",
    "Ēdole",
    "Ērģeme"

];

export const lithuanianCities = [

    "Vilnius",
    "Kaunas",
    "Klaipėda",
    "Šiauliai",
    "Panevėžys",
    "Alytus",
    "Marijampolė",
    "Mažeikiai",
    "Jonava",
    "Utena",
    "Telšiai",
    "Visaginas",
    "Kretinga",
    "Gargždai",
    "Tauragė",
    "Ukmergė",
    "Plungė",
    "Šilutė",
    "Radviliškis",
    "Palanga",
    "Kėdainiai",
    "Telsiai",
    "Širvintos",
    "Naujoji Akmenė",
    "Joniškis",
    "Ignalina",
    "Biržai",
    "Grigiškės",
    "Pasvalys",
    "Švenčionys",
    "Trakai",
    "Raseiniai",
    "Anykščiai",
    "Kuršėnai",
    "Šalčininkai",
    "Prienai",
    "Kelme",
    "Kaišiadorys",
    "Elektrėnai",
    "Rokiškis",
    "Neringa",
    "Molėtai",
    "Šakiai",
    "Silale",
    "Kupiškis",
    "Akmenė",
    "Zarasai",
    "Rietavas",
    "Skuodas",
    "Dusetos",
    "Pagėgiai",
    "Kalvarija",
    "Pabradė",
    "Eišiškės",
    "Seda",
    "Utena",
    "Varėna",
    "Vilkaviškis",
    "Kybartai",
    "Kazlų Rūda",
    "Pakruojis",
    "Širvintos",
    "Švenčionys",
    "Trakai",
    "Raseiniai",
    "Anykščiai",
    "Kuršėnai",
    "Šalčininkai",
    "Prienai",
    "Kelme",
    "Kaišiadorys",
    "Elektrėnai",
    "Rokiškis",
    "Neringa",
    "Molėtai",
    "Šakiai",
    "Silale",
    "Kupiškis",
    "Akmenė",
    "Zarasai",
    "Rietavas",
    "Skuodas",
    "Dusetos",
    "Pagėgiai",
    "Kalvarija",
    "Pabradė",
    "Eišiškės",
    "Seda",
    "Varėna",
    "Vilkaviškis",
    "Kybartai",
    "Kazlų Rūda",
    "Pakruojis",
    "Rietavas",
    "Venta",
    "Nemenčinė",
    "Eišiškės",
    "Seda",
    "Gargždai",
    "Dotnuva",
    "Gelgaudiškis",
    "Akmenė",
    "Rūdiškės",
    "Kybartai",
    "Kazlų Rūda",
    "Pakruojis",
    "Rietavas",
    "Venta",
    "Nemenčinė",
    "Dotnuva",
    "Gelgaudiškis",
    "Klaipėda",
    "Molėtai",
    "Vilkaviškis",
    "Nida",
    "Naujoji Akmene",
    "Kudirkos Naumiestis",
    "Ežerėlis",
    "Simnas",
    "Veisiejai",
    "Prienai",
    "Kalvarija",
    "Ariogala",
    "Rusnė",
    "Klaipeda",
    "Gargždai",
    "Mazeikiai",
    "Salantai",
    "Kretinga",
    "Vilkaviškis",
    "Venta",
    "Nemenčinė",
    "Dotnuva",
    "Gelgaudiškis",
    "Klaipėda",
    "Molėtai",
    "Vilkaviškis",
    "Nida",
    "Naujoji Akmene",
    "Kudirkos Naumiestis",
    "Ežerėlis",
    "Simnas",
    "Veisiejai",
    "Prienai",
    "Kalvarija",
    "Ariogala",
    "Rusnė",
    "Klaipeda",
    "Gargždai",
    "Mazeikiai",
    "Salantai",
    "Kretinga",
    "Vilkaviškis",
    "Venta",
    "Nemenčinė",
    "Dotnuva",
    "Gelgaudiškis",
    "Klaipėda",
    "Molėtai",
    "Vilkaviškis",
    "Nida",
    "Naujoji Akmene",
    "Kudirkos Naumiestis",
    "Ežerėlis",
    "Simnas",
    "Veisiejai",
    "Prienai",
    "Kalvarija",
    "Ariogala",
    "Rusnė",
    "Klaipeda",
    "Gargždai",
    "Mazeikiai",
    "Salantai",
    "Kretinga",
    "Venta",
    "Nemenčinė",
    "Dotnuva",
    "Gelgaudiškis",
    "Klaipėda",
    "Molėtai",
    "Vilkaviškis",
    "Nida",
    "Naujoji Akmene",
    "Kudirkos Naumiestis",
    "Ežerėlis",
    "Simnas",
    "Veisiejai",
    "Prienai",
    "Kalvarija",
    "Ariogala",
    "Rusnė",
    "Klaipeda",
    "Gargždai",
    "Mazeikiai",
    "Salantai",
    "Kretinga"

];  

export const estonianCities = [

    "Tallinn",
    "Tartu",
    "Narva",
    "Kohtla-Järve",
    "Pärnu",
    "Viljandi",
    "Maardu",
    "Rakvere",
    "Sillamäe",
    "Võru",
    "Valga",
    "Haapsalu",
    "Jõhvi",
    "Keila",
    "Paide",
    "Kiviõli",
    "Türi",
    "Põlva",
    "Saue",
    "Paldiski",
    "Tapa",
    "Põltsamaa",
    "Elva",
    "Rapla",
    "Sindi",
    "Jõgeva",
    "Tõrva",
    "Kärdla",
    "Võhma",
    "Loksa",
    "Kunda",
    "Otepää",
    "Mustvee",
    "Narva-Jõesuu",
    "Tamsalu",
    "Karksi-Nuia",
    "Lihula",
    "Kohila",
    "Antsla",
    "Mõisaküla",
    "Räpina",
    "Abja-Paluoja",
    "Tapa",
    "Püssi",
    "Sõmeru",
    "Kuressaare",
    "Muhu",
    "Kärdla",
    "Kihnu",
    "Lihula",
    "Nõva",
    "Kose",
    "Lüganuse",
    "Käina",
    "Suure-Jaani",
    "Tootsi",
    "Värska",
    "Varstu",
    "Koeru",
    "Loksa",
    "Kunda",
    "Otepää",
    "Mustvee",
    "Narva-Jõesuu",
    "Tamsalu",
    "Karksi-Nuia",
    "Lihula",
    "Kohila",
    "Antsla",
    "Mõisaküla",
    "Räpina",
    "Abja-Paluoja",
    "Tapa",
    "Püssi",
    "Sõmeru",
    "Kuressaare",
    "Muhu",
    "Kärdla",
    "Kihnu",
    "Lihula",
    "Nõva",
    "Kose",
    "Lüganuse",
    "Käina",
    "Suure-Jaani",
    "Tootsi",
    "Värska",
    "Varstu",
    "Koeru"

];