/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import ContactsSection      from "../../Components/Contacts/Section";

/** Import datasets */

import { ContactsDataset }      from "../../Datasets/Contacts";

/** Import assets */

import "../../Styles/Contacts.css";

const Contacts = () => { const { t } = useTranslation ();

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "contacts" ), URI: "/content/contacts" }

        ] } />
    
        <div className="content contacts">

            { ContactsDataset ().Sections.map ( ( section, index ) => <ContactsSection 
            
                title={ section.title } 
            
                key={ index }>

                    { section.content }

            </ContactsSection> ) }

        </div>
        
    </div> );

}

/** Export component */
 
export default Contacts;