/** Import modules */

import { useTranslation }       from "react-i18next";

/**
 * 
 * @returns Policies datasets
 * 
 */

const PoliciesDatasets = () => { const { t } = useTranslation ();

    /** Return datasets */

    return {

        /** Policies routes */

        Routes: [

            { 
                
                name    : t ( "privacy_policy" ), 
            
                URI     : "/policies/privacy-policy" },

            { 
                
                name    : t ( "cookies" ), 
            
                URI     : "/policies/cookie-policy" },

            { 
            
                name    : t ( "terms_and_conditions" ), 
            
                URI     : "/policies/terms-of-use" }

        ]

    }

}

/** Export datasets */

export default PoliciesDatasets;