/** @module                 GraphQL */

import { useQuery }         from "@apollo/client";

/**
 * 
 * @param { * } query 
 * 
 * @param { object } attributes 
 * 
 * @returns graphql query object
 * 
 */

const 

useFetch = ( query, attributes = {}, cache = "cache-first", networkNotify = false ) => { const 
    
  { loading, data, refetch, variables } = useQuery ( 
    
        query, 
        
        { 
            
            variables: attributes, 
            
            fetchPolicy: cache, 
            
            notifyOnNetworkStatusChange: networkNotify, 
            
            errorPolicy: 'all' 
        
        } 
        
    );

  /** @returns */

  return { loading, data, variables, refetch }

}

/** @exports useFetch */
 
export default useFetch;