/** @import modules */

import { Link               } from "react-router-dom";

import { useTranslation     } from "react-i18next";

/** @import hooks */

import { usePolicy          } from "../../../Hooks/Policy";

/** @import assets */

import logotype             from "../../../Assets/tireshop-logo.png";
import { Fragment } from "react";

/**
 * 
 * @returns cookie policy notice
 * 
 */

const SitePolicy = () => { const { t } = useTranslation (),

    { view, set } = usePolicy (),

    Template = () => {

        if ( view ) return <Fragment />

        return <div className="site-policy">

            <div className="max-content-width">

                <div className="policy">

                    <img src={ logotype } alt="tireshop.lv" />

                    <div className="announcement">

                        <div className="title">

                            { t ( "site_policy_title" ) }

                        </div>

                        <div className="text">

                            { t ( "site_policy_text" ) } <Link to="/policies/cookie-policy">

                                { t ( "site_policy_link" ) }    
                                
                            </Link>.

                        </div>

                    </div>

                    <button className="confirm-policy" onClick={ () => set ( true ) }>

                        { t ( "accept" ) }

                    </button>

                </div>

            </div>

        </div>

    }

    /** @returns */

    return ( <Template /> );

}
 
/** @exports SitePolicy */

export default SitePolicy;