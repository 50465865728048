/** @import modules */

import { useQuery }             from "@apollo/client";

/** @import queries */

import { QuickSearch }          from "../Queries/QuickSearch";

/**
 * 
 * @param { params } attr 
 * 
 * @returns single string search model
 * 
 */

const StringSearchResults = ( keywords ) => { const

    { loading, data, refetch } = useQuery ( QuickSearch, { variables: { query: keywords, first: 12 } } );

    /** Return model */

    return { loading, data, refetch }

}

/** @export StringSearchResults */

export default StringSearchResults;