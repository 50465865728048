import { useContext }           from "react";

import { CompareContext } from "../../../Contexts/CompareContext";

/** @import components */

import CompareItem from "./Item";

const Table = ( { title, type } ) => { const 

    { compare, setCompare } = useContext ( CompareContext ),

    data = compare.filter ( a => a.type === type ),

    Items = () => { const 

        collection = [];

        for ( let index = 0; index < 4; index++ ) {

            collection.push ( ( data[index] === undefined ) ? <div className="compare-empty"></div> : <CompareItem 
            
            id={ data[index].id }
            
            type={ type }
            
            actions={ { view: compare, set: setCompare } } /> );

        }

        return collection.map ( (sample, index) => <div key={index} className="content">

            { sample }

        </div> );

    };

    /** @return component */

    return ( <div className="table">

        <div className="title">{ title }</div>

        <div className="items">

            <Items />

        </div>

    </div> );

}

/** @export Table */
 
export default Table;