import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import Cookies from 'js-cookie'; // Ensure you have `js-cookie` installed

const LanguageHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname, search } = location;
    const searchParams = new URLSearchParams(search);
    const urlLang = searchParams.get('lang'); // Get 'lang' from the URL
    const cookieLang = Cookies.get('i18next'); // Read the current language from cookies

    if (urlLang) {
      // If `lang` is in the URL, check against the cookie
      if (urlLang !== cookieLang) {
        i18next.changeLanguage(urlLang); // Switch language
        Cookies.set('i18next', urlLang); // Update the cookie
      }
    } else {
      // If `lang` is not in the URL, append it based on the cookie or default language
      const defaultLang = cookieLang || 'lv'; // Fallback to 'en' if no cookie is set
      searchParams.set('lang', defaultLang);
      navigate(`${pathname}?${searchParams.toString()}`, { replace: true });
    }
  }, [location, navigate]);

  return null; // This component doesn't render anything
};

export default LanguageHandler;
