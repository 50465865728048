/** @import modules */

import { Link }                 from "react-router-dom";

import { useTranslation }       from "react-i18next";

import { useContext }           from "react";

import { useState }             from "react";

/** @import hooks */

import { qtySum }               from "../../../Hooks/QtySum";

/** @import misc */

import { 
    
    TyrePermalinks, 
    
    RimPermalinks, 
    
    BatteryPermalinks }         from "../../../Misc/Permalinks";

import { productRemoveFromCart } from "../../../Misc/DataLayer";

/** @import contexts */

import { CartContext }          from "../../../Contexts/CartContext";

/** @import assets */

import NoTire                   from "../../../Assets/no-tyres.jpg";

import NoRim                    from "../../../Assets/no-rims.jpg";

import NoBattery                from "../../../Assets/no-batteries.jpg";

/** @import icons */

import { FaRegTrashAlt }        from "react-icons/fa";

import { 
    
    AiOutlinePlus, 
    
    AiOutlineMinus }            from "react-icons/ai";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Single item for orders
 * 
 */

const OrdersItem = ( { product } ) => { const { t } = useTranslation (),

    { cart, setCart } = useContext ( CartContext ),

    maxQty = qtySum ( product.productVendors ),

    [ quantity, setQuantity ] = useState ( cart.find( x => x.id === product.id ).qty ),

    removeItem = id => {

        productRemoveFromCart ( product );

        cart.splice ( cart.map ( item => item.id ).indexOf ( id ), 1 );

        return setCart ( [...cart ] );

    },

    productQuantity = action => {

        switch ( action ) {

            case "add" : 

                if ( quantity < maxQty ) { 

                    cart.filter ( x => x.id === product.id ).map ( x => {

                        x.qty = parseInt ( x.qty ) + 1;

                        setQuantity ( quantity + 1 );

                        return setCart ( [...cart ] );

                    } );
                
                }
            
                break;

            case "remove" : 

                if ( quantity > 1 ) {

                    cart.filter ( x => x.id === product.id ).map ( x => {

                        x.qty = parseInt ( x.qty ) - 1;

                        setQuantity ( quantity - 1 );

                        return setCart ( [...cart ] );

                    } );

                };
            
                break;

            default : break;

        }

    },

    QtyController = event => {

        if ( parseInt ( event.target.value ) > maxQty ) return null;

        if ( parseInt ( event.target.value ) < 1 ) return null;

        setQuantity ( event.target.value );

    },
    
    NoProduct = type => {

        if ( type === "tire" ) return NoTire;

        if ( type === "rim" ) return NoRim;

        if ( type === "battery" ) return NoBattery;

    },

    Permalinks = type => {

        if ( type === "tire" ) return TyrePermalinks ( product );

        if ( type === "rim" ) return RimPermalinks ( product );

        if ( type === "battery" ) return BatteryPermalinks ( product );

    }

    /** @return component */

    return ( <div className="item">

        <div className="data">
            
            <div className="thumbnail">
                
                <Link to={ Permalinks ( product.productType ) }>

                    { ( ! product.currentImage ) ? 
                    
                    <img src={ NoProduct ( product.productType ) } alt={ product.model } /> : 
                    
                    <img src={ product.currentImage } alt={ product.model } onError={ ( { currentTarget } ) => {

                        currentTarget.src = NoProduct ( product.productType );
            
                    } } /> }

                </Link>

            </div>

            <div className="name">

                <div className="product">

                    <Link to={ Permalinks ( product.productType ) }>

                        { `${ product.manufacturer.name.toUpperCase () } ${ product.model }` }

                    </Link>

                </div>

                <div className="parameters">

                    { product.productType === "tire" && <>

                        <span>{ t ( "tyre" ) }: </span> 
                        
                        { `${ product.width } | ${ product.height } | R${ parseInt ( product.diameter ) }${ product?.isCTire ? "C" : "" }` }

                    </> }

                    { product.productType === "rim" && <>

                        <span>{ t ( "rim" ) }: </span> 
                        
                        { `${ product.width } | R${ parseInt ( product.diameter ) }` }

                    </> }

                    { product.productType === "battery" && <>

                        <span>{ t ( "battery" ) }: </span> 
                        
                        { `${ product.voltage }V | ${ product.current } A | ${ product.capacity } Ah` }

                    </> }

                </div>

            </div>

            <div className="quantity">

                <div className="down">

                    <button onClick={ () => productQuantity ( "remove" ) }>

                        <AiOutlineMinus />

                    </button>

                </div>

                <div className="quantity">

                    <input type="number" value={ quantity } onChange={ e => QtyController ( e ) } disabled={ ! maxQty } />

                </div>

                <div className="up">

                    <button onClick={ () => productQuantity ( "add" ) }>

                        <AiOutlinePlus />

                    </button>

                </div>

            </div>

            <div className="price">

                <div className="heading">

                    { t ( "set_price_vat" ) }

                </div>

                <div className="pricing">

                    &euro; { parseFloat ( product.priceList.retail_price * quantity ).toFixed ( 2 ) }

                </div>

            </div>
            
        </div>

        <div className="remove">
            
            <button onClick={ () => removeItem ( product.id ) }>

                <FaRegTrashAlt />

            </button>
            
        </div>

    </div> );

}

/** @export component */
 
export default OrdersItem;