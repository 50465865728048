import { useQuery }                         from "@apollo/client";

import { useTranslation }                   from "react-i18next";

import { useParams, useNavigate }           from "react-router-dom";

/** @module                                 React-Icons */

import { FaCheck }                      from "react-icons/fa";

import { BatteriesNavigator } from "../../../../../Hooks/BatteriesNavigator";

import { AVAILABILITY_TYPE_ENUM }           from "../../../../../Queries/Products";

import ComponentLoader                      from "../../../../Layout/Loaders/Loader";

const 

AvailabilityFilter = () => { const

    { t } = useTranslation (),

    { loading, data } = useQuery ( AVAILABILITY_TYPE_ENUM ),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    params              = new URLSearchParams ( parameters ),

    Filter = ( event ) => { const 

        object = { f : 12, l : null, a : null, b : null, av : params.get ( "av" ) ? params.get ( "av" ).split ( "," ) : [], loc: window.scrollY };

        if ( event.target.checked ) { object.av.push ( event.target.value );

            return navigate (  
            
                `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_batteries__" ) }/${ 
                
                    new URLSearchParams ( BatteriesNavigator ( parameters, object ) ).toString () }` );
        
        } else { object.av.splice ( params.get ( "av" ).split ( "," ).indexOf ( event.target.value ), 1);

            return navigate (  
            
                `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_batteries__" ) }/${ 
                
                    new URLSearchParams ( BatteriesNavigator ( parameters, object ) ).toString () }` );

        }

    };

    return ( 
    
        <div className="content seasons">

            { loading ? <ComponentLoader minified={ true } /> : 

                data.__type.enumValues.map ( ( option, key ) =>  

                    <label htmlFor={ option.name } className="checkbox" key={ key }>

                        <input 
                        
                            type="checkbox" 
                        
                            id={ option.name } 
                        
                            value={ option.name } 
                        
                            onChange={ e => Filter ( e ) } 
                            
                            checked={ params.get ( "av" ) && ( params.get ( "av" ).split ( "," ).indexOf ( option.name ) !== -1 ) } />

                        <span className="check">

                            <span className="checked">

                                <FaCheck />

                            </span>

                        </span>

                        <span className="name">

                            { t ( `av_${ option.name }` ) }

                        </span>

                    </label>

                )
            
                // <label className="select shadow-select">

                //     <select onChange={ e => Filter ( e ) } defaultValue={ new URLSearchParams ( parameters ).get ( "av" ) || "default" }>

                //         <option value="default">

                //             { t ( "select_option" ) }

                //         </option>

                //         { data.__type.enumValues.map ( ( option, key ) => <option 
                            
                //             key={ key } 
                            
                //             value={ option.name }>

                //                 { t ( `av_${ option.name }` ) }

                //         </option> ) }

                //     </select>

                //     <span>
                        
                //         <BsFillCaretDownFill />
                        
                //     </span>

                // </label>
                
            }

        </div> 
    
    );

}
 
export default AvailabilityFilter;