/** @import styles */

import "../../../Styles/Products.css";

/** @import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

/** @import models */

import StringSearchResults      from "../../../Models/QuickSearch";

/** @import components */

import Breadcrumbs              from "../../../Components/Layout/Header/Breadcrumbs";

import ComponentLoader          from "../../../Components/Layout/Loaders/Loader";

import Results                  from "../../../Components/Products/QuickSearch/Results";

import Pagination               from "../../../Components/Products/QuickSearch/Pagination";

/**
 * 
 * @returns quick search results page
 * 
 */

const ProductQuickSearch = () => { const { t } = useTranslation (),

    decode = useParams ().keywords,

    keywords = new URLSearchParams ( decode ).get ( "search" ),

    { data, loading, refetch } = StringSearchResults ( keywords ),

    Products = () => {

        if ( loading ) return <ComponentLoader />

        const navigator = page => {

            if ( page.hasNextPage === true || page.hasPreviousPage === true ) {

                return true;

            } else { return false; }

        }

        return <div className="results">

            <Results 
            
                products={ data.singleStringSearch.edges } />

            { navigator ( data.singleStringSearch.pageInfo ) && 
            
                <Pagination 
                
                    pageinfo={ data.singleStringSearch.pageInfo }
                    
                    update={ refetch } /> }

        </div>

    };

    /** @returns component */

    return (<div className="container quick-search max-content-width">

        <Breadcrumbs collection={ [

            { 
                
                name    : t ( "search_results" ), 
            
                URI     : `/shop/products/search/${ keywords }`
            
            }

        ] } />

        <div className="title">

            <h2>

                { t ( "search_results" ) }: { keywords }

            </h2>

        </div>

        <Products />

    </div>);

}

/** @exports ProductQuickSearch */
 
export default ProductQuickSearch;