/** Import icons */

import { GrClose } from "react-icons/gr";

const MobileNavClose = ( { action } ) => {

    /** Return component */

    return (<div className="close">

        <button onClick={ () => action.set ( false ) }>

            <GrClose />

        </button>

    </div>);
    
}

/** Export component */
 
export default MobileNavClose;