/** Import assets */

import "../../Styles/Home.css";

/** Import components */

import HomeProductSearch    from "../../Components/Home/Search";

import HomeServices         from "../../Components/Home/Services";

import HomeBanner           from "../../Components/Home/Banner";

import HomeBlog             from "../../Components/Home/Blog";

import Benefits             from "../../Components/Home/Benefits";

import Categories           from "../../Components/Home/Categories";

import HomeStaticBanner     from "../../Components/Home/StaticBanner";

const Home = () => {

    /** Render component */

    return (<div className="home">

        <HomeProductSearch />

        <HomeStaticBanner />

        <Benefits />

        <Categories />

        <HomeServices />

        <HomeBanner />

        {/* <HomeSale />

        <HomeBanner />

        <HomeTeam /> */}

        <HomeBlog />

        {/* <LIAA /> */}

    </div>);

}

/** Export component */
 
export default Home;