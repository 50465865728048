/** Import modules */

import { NavLink }          from "react-router-dom";

/** Import datasets */

import ContentsDatasets     from "../../Datasets/Contents";

/** Import assets */

import "../../Styles/Contents.css";

/**
 * 
 * @param {title, children} param0 
 * 
 * @returns Policies layout component
 * 
 */

const ContentsLayout = ( { title, children } ) => {

    /** Return component */

    return ( <div className="content contents">

        <div className="title">
            
            <h2>{ title }</h2>
            
        </div>
    
        <div className="layout">

            <aside>

                <ul>

                    { ContentsDatasets ().Routes.map ( ( policy, index ) => <li key={ index }>

                        <NavLink 
                        
                            to={ policy.URI } 
                        
                            className={ ( { isActive } ) => ( isActive ? 'active' : 'inactive' ) }>

                                { policy.name }

                        </NavLink>

                    </li> ) }

                </ul>

            </aside>

            <section>{ children }</section>

        </div>
    
    </div> );

}

/** Export component */
 
export default ContentsLayout;