/** @import assets */

import "../../Styles/Services.css";

/** @import modules */

import { Widgets }                  from "../../Datasets/Services";

/** @import components */

import NavigationSelect             from "./Navigation";

/**
 * 
 * @param { title, children } 
 * 
 * @returns contents page layout with sidebar widgets
 * 
 */

const Layout = ( { title, children } ) => {

    /** @return component */

    return ( <div className="content services">

        <div className="title">
            
            <h2>{ title }</h2>
            
        </div>

        <div className="layout">

            <aside>

                { Widgets ().map ( ( widget, index ) => <div className="widget" key={ index }>
                    
                    { widget.widget }

                </div> ) }

            </aside>

            <section>

                <NavigationSelect />
                
                { children }
                
            </section>

        </div>

    </div> );

}

/** @export Layout */
 
export default Layout;