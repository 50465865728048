/** @import styles */

import "../../../Styles/Layout.css";

/** @import icons */

import { FaRegLightbulb } from "react-icons/fa";

const NoticeNeutral = ( { notice } ) => {

    /** @return component */

    return ( <div className="notice neutral">

        <span className="icon">

            <FaRegLightbulb />

        </span>

        { notice }

    </div> );

}

/** @export component */
 
export default NoticeNeutral;