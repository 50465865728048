/** @import models */

import TechInfoModel                    from "../../Models/TechnicalModel";

import { useTranslation }               from "react-i18next";

import cookies                          from "js-cookie";

import { Link }                         from "react-router-dom";

/** @import components */

import ComponentLoader                  from "../Layout/Loaders/Loader";

import NoImage                          from "../../Assets/no-tyres.jpg";

const List = () => { const { t } = useTranslation (),

    { loading, data } = TechInfoModel (),

    Collection = ( { object, status } ) => {

        if ( status ) return <ComponentLoader />

        const filter = object.technicalInformation.edges.filter ( obj => obj.node.language === cookies.get ( 'i18next' ) && obj.node.isPublished === true );

        return filter.map ( ( tech, index ) => <Link to={ `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_techinfo__" ) }/${ tech.node.id }/${ encodeURIComponent ( tech.node.title ) }` } key={ index }>

            <div className="image">

                <img src={ tech.node.image } alt={ tech.node.title } onError={ ( { currentTarget } ) => {

                    currentTarget.onerror = null;

                    currentTarget.src = NoImage;

                } } />

            </div>

            <div className="tech-container">

                <h3 className="title">

                    { tech.node.title }

                </h3>

                <div className="excerpt">

                    { tech.node.description }

                </div>

            </div>

            {/* <img src={ service.node.image } alt={ service.node.title } onError={ ( { currentTarget } ) => {

                currentTarget.onerror = null;

                currentTarget.src = NoImage;

            } } />

            <div className="title">

                { service.node.title }

            </div> */}

        </Link> );

    };

    /** @return component */

    return ( <div className="tech-list">

        <Collection object={ data } status={ loading } />

    </div> );

}

/** @export List */
 
export default List;