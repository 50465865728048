/** @import modules */

import { useState, useRef }         from "react";

import { useMutation }              from "@apollo/client";

import { useTranslation }           from "react-i18next";

import { Link }                     from "react-router-dom";

/** @import models */

import { AppointmentModel }         from "../../../Models/Appointments";

/** @import components */

import ComponentLoader              from "../../Layout/Loaders/Loader";

/** @import datasets */

import { RemoveAppointmentData }    from "../../../Datasets/Montage";

/** @import mutations */

import { REMOVE_APPOINTMENT }       from "../../../Mutations/Appointment";

/** @import icons */

import { AiOutlineLeftCircle }      from "react-icons/ai";

/**
 * 
 * @param { appointment } 
 * 
 * @returns appointment information for appointment elimination page
 * 
 */

const EliminateAppointment = ( { appointment } ) => { const { t } = useTranslation (),

    { data, loading } = AppointmentModel ( appointment ),

    submitButton = useRef ( null ),

    [ eliminated, setEliminated ] = useState ( false ),

    [ RemoveAppointment ] = useMutation ( REMOVE_APPOINTMENT, {

        onCompleted () { setEliminated ( true ); },

        onError ( err ) { window.location.reload (); }

    } ),

    removeAppointment = () => {

        submitButton.current.setAttribute ( "disabled", "disabled" );

        submitButton.current.innerHTML = t ( "submitting" );

        RemoveAppointment ( { variables: { uuid : appointment } } );

    },

    /** @returns conditional appointment component */

    Layout = () => {

        /** @return component loader */

        if ( loading ) return <ComponentLoader />

        /** @return invalid UUID component */

        if ( typeof data === "undefined" ) return <div className="invalid-appointment">

            { t ( "appointment_not_found" ) }

        </div>

        /** @return eliminated appointment message */

        if ( eliminated === true ) return <div className="appointment-eliminated">
            
            <div className="message">

                { t ( "appointment_eliminated" ) }

            </div>

            <div className="actions">

                <Link to="/">

                    <AiOutlineLeftCircle />
                    
                    <span>

                        { t ( "return_to_homepage" ) }

                    </span>
                    
                </Link>

            </div>
            
        </div>

        const appointment_data = RemoveAppointmentData ( data );

        /** @return appointment data table */

        return <div className="remove-appointment">

            <div className="appointment-table">
            
                { appointment_data.map ( ( value, index ) => <div className="appointment-row" key={ index }>

                    <div className="appointment-data-text">
                        
                        { value.text }
                    
                    </div>

                    <div className="appointment-data-value">
                        
                        { value.value }
                    
                    </div>

                </div> ) }
                
            </div>

            <button onClick={ () => removeAppointment () } ref={ submitButton }>

                { t ( "delete_appointment" ) }

            </button>

        </div>

    };

    /** @return component */

    return ( <Layout /> );

}

/** @export component */
 
export default EliminateAppointment;