/** @import modules */

import { gql } from "@apollo/client";

const 

/** Services collection */

ServicesList = gql`query { services { edges { node {

    id

    title

    isActive

    language

    image

} } } }`,

/** Services navigation */

ServicesNavigation = gql`query { services { edges { node {

    id

    title

    isActive

    language

} } } }`,

SingleService = gql`query { services { edges { node {

    id

    title

    isActive

    language

    content

} } } }`;

/** @export queries */

export { ServicesList, ServicesNavigation, SingleService }