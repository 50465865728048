/** @import modules */

import { useState               } from "react";

import { useTranslation, Trans  } from "react-i18next";

import { Link                   } from "react-router-dom";

import { FaCheck                } from "react-icons/fa";

import { useMutation            } from "@apollo/client";

/** @import mutations */

import { SEND_MESSAGE           } from "../../Mutations/Message";

/** @returns contacts form component */

const ContactsForm = () => { const { t } = useTranslation (),

    [ name,         setName         ] = useState ( "" ),

    [ email,        setEmail        ] = useState ( "" ),

    [ phone,        setPhone        ] = useState ( "" ),

    [ message,      setMessage      ] = useState ( "" ),

    [ policy,       setPolicy       ] = useState ( false ),

    [ subscribe,    setSubscribe    ] = useState ( false ),

    [ status,       setStatus       ] = useState ( false ),

    [ mError,       setError        ] = useState ( false ),

    [ SendMessage ] = useMutation ( SEND_MESSAGE, {

        onCompleted ( data ) {

            setName ( "" ); setEmail ( "" ); setPhone ( "" );

            setMessage ( "" );

            setPolicy ( false ); setSubscribe ( false );

            setStatus ( true );

            const resetFields = document.querySelectorAll ( "#contact-form input, #contact-form textarea" );

            document.querySelector ( "#policy_checkbox > label > span.check" ).classList.remove ( "error" );

            resetFields.forEach ( element => element.removeAttribute ( "disabled" ) );

        }, onError ( error ) {

            setError ( error.message );

            const resetFields = document.querySelectorAll ( "#contact-form input, #contact-form textarea" );

            resetFields.forEach ( element => element.removeAttribute ( "disabled" ) );

        }

    } ),

    submit = e => { e.preventDefault (); const 

        fields = e.target.querySelectorAll ( "input, textarea" ),

        submit = e.target.querySelector ( "input[type='submit']" ),

        errors = [];

        setError ( false );

        fields.forEach ( element => element.classList.remove ( "error" ) );

        /** Validation */

        if ( ! e.target.querySelector ( "#name" ).value ) { 
            
            errors.push ( e.target.querySelector ( "#name" ) );

            e.target.querySelector ( "#name" ).classList.add ( "error" );
        
        }

        if ( ! e.target.querySelector ( "#email" ).value ) { 
            
            errors.push ( e.target.querySelector ( "#email" ) );

            e.target.querySelector ( "#email" ).classList.add ( "error" );
        
        }

        if ( ! e.target.querySelector ( "#message" ).value ) { 
            
            errors.push ( e.target.querySelector ( "#message" ) );

            e.target.querySelector ( "#message" ).classList.add ( "error" );
        
        }

        if ( ! policy ) { 
            
            errors.push ( document.querySelector ( "#policy_checkbox > label > span.check" ) );

            e.target.querySelector ( "#policy_checkbox > label > span.check" ).classList.add ( "error" );
        
        }

        if ( errors.length ) return;

        fields.forEach ( element => element.setAttribute ( "disabled", "disabled" ) );

        submit.value = t ( "loading" );

        SendMessage ( { variables: {

            name: name, 
            
            email: email, 
            
            phoneNr: phone,

            message: message

        } } );

    };

    /** @return */

    return ( <div className="contacts-form">

        <form onSubmit={ e => submit ( e ) } id="contact-form">

            <div className="title">

                { t ( "contact_form" ) }

            </div>

            { mError && <div className="send-failed">

                { t ( mError ) }

            </div> }

            { status && <div className="send-successful">

                { t ( "message_sent" ) }

            </div> }

            <div className="field-group">

                <div className="field">

                    <label 
                    
                        htmlFor="name" 
                    
                        className="required">

                            { t ( "name" ) }

                    </label>

                    <input 
                    
                        type="text" 
                    
                        name="name" 
                    
                        id="name" 
                    
                        value={ name } 
                    
                        onChange={ e => setName ( e.target.value ) } />

                </div>

                <div className="field">

                    <label 
                    
                        htmlFor="email" 
                    
                        className="required">

                            { t ( "email" ) }

                    </label>

                    <input 
                    
                        type="email" 
                    
                        name="email" 
                    
                        id="email" 
                    
                        value={ email } 
                    
                        onChange={ e => setEmail ( e.target.value ) } />

                </div>

                <div className="field">

                    <label htmlFor="phone">

                        { t ( "phone" ) }

                    </label>

                    <input 
                    
                        type="tel" 
                    
                        name="phone" 
                    
                        id="phone" 
                    
                        value={ phone } 
                    
                        onChange={ e => setPhone ( e.target.value ) } />

                </div>

            </div>

            <div className="textarea">

                <div className="field">

                    <label 
                    
                        htmlFor="message" 
                    
                        className="required">

                            { t ( "message" ) }

                    </label>

                    <textarea 
                    
                        name="message" 
                    
                        id="message" 
                    
                        value={ message } 
                    
                        onChange={ e => setMessage ( e.target.value ) }>

                            { message }

                    </textarea>

                </div>

            </div>

            <div className="checkbox-group" id="policy_checkbox">

                <label htmlFor="policy" className="checkbox">

                    <input 

                        type="checkbox" 

                        id="policy"

                        value={ policy }

                        onChange={ () => ( policy === true ) ? setPolicy ( false ) : setPolicy ( true ) }
                        
                        checked={ ( policy ) ? true : false } />

                    <span className="check">

                        <span className="checked">

                            <FaCheck />

                        </span>

                    </span>

                    <span className="name">

                        <Trans i18nKey="checkout_policy">

                            I have read and agree to the page <Link to="/policies/terms-of-use" target="_blank" rel="noopener noreferrer">terms and conditions.</Link>

                        </Trans>

                    </span>

                </label>

                <label htmlFor="subscribe" className="checkbox">

                    <input 

                        type="checkbox" 

                        id="subscribe"

                        value={ subscribe }

                        onChange={ () => ( subscribe === true ) ? setSubscribe ( false ) : setSubscribe ( true ) }
                        
                        checked={ ( subscribe ) ? true : false } />

                    <span className="check">

                        <span className="checked">

                            <FaCheck />

                        </span>

                    </span>

                    <span className="name">

                        { t ( "newsletter_subscribe_check" ) }

                    </span>

                </label>

            </div>

            <input type="submit" value={ t ( "send" ) } />

        </form>

    </div> );

}
 
/** @export ContactsForm */

export default ContactsForm;