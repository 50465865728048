/** Import modules */

import { 
    
    useNavigate, 
    
    useParams }                 from "react-router-dom";

import { useTranslation }       from "react-i18next";

/** Import datasets */

import { ProductsPagination }   from "../../../../Datasets/Products";

/** Import hooks */

import { RimsNavigator }        from "../../../../Hooks/RimsNavigator";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Product pagination component
 * 
 */

const Pagination = ( { pageInfo } ) => { const 

    { t }                   = useTranslation (),
    
    navigate                = useNavigate   (),
    
    { parameters }          = useParams     ();

    /** Return component */

    return ( <div className="pagination">

        { ProductsPagination ( pageInfo ).map ( ( button, index ) => button.disableState && <button 
        
        key={ index } 
        
        onClick={ () => navigate (

            `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }/${ new URLSearchParams ( RimsNavigator ( parameters, button.query ) ).toString () }`

        ) } disabled={ button.state }>

            { button.icon }

        </button> ) }

    </div> );

}

/** Export component */
 
export default Pagination;