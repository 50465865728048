/** @import modules */

import { useTranslation } from "react-i18next";

const Specifications = ( { data } ) => { const { t } = useTranslation ();

    /** @returns component */

    return ( <div className="product-specifications">

        <h3>{ t ( "product_specification" ) }</h3>

        <div className="list">

            { data.map ( ( property, index ) => <div className="item" key={ index }>

                <div className="name">

                    { property.name }

                </div>

                <div className={ `value ${ property.style }` }>

                    { ( ! property.value ) ? "-" : property.value }

                </div>

            </div> ) }

        </div>

    </div> );

}

/** @exports Specifications */
 
export default Specifications;