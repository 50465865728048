/** Import modules */

import { useTranslation } from "react-i18next";

/**
 * 
 * @param { description } param0 
 * 
 * @returns Single product description component
 * 
 */

const Description = ( { description } ) => { const 

    { t } = useTranslation (),

    /** @returns Evaluated description component */

    LoadDescription = ( { desc } ) => {

        if ( ! desc ) return t ( "missing_description" );

        return desc;

    };

    /** Return component */

    return ( <div className="description">

        <h3>{ t ( "description" ) }</h3>
        
        <div className="paragraph">

            <LoadDescription desc={ description } />

        </div>

    </div> );

}

/** Export component */
 
export default Description;