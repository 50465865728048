const HomeHeading = ( { title, description } ) => {

    /** Return components */

    return (<div className="home-heading">

        <h2>{ title }</h2>

        <p>{ description }</p>

    </div>);

}

/** Export component */
 
export default HomeHeading;