/** @import datasets */

import { ProductsPagination } from "../../../Datasets/Products";

/** @import hooks */

import { RefetchAttributes } from "../../../Hooks/QuickSearchNavigator";

/**
 * 
 * @param { pageinfo, update } 
 * 
 * @returns quick search pagination component
 * 
 */

const Pagination = ( { pageinfo, update } ) => {

    /** @returns component */

    return ( <div className="pagination">

        { ProductsPagination ( pageinfo ).map ( ( button, index ) => button.disableState && <button 
        
            key={ index } 

            onClick={ () => update ( RefetchAttributes ( button.query ) ) }
            
            disabled={ button.state }>

                { button.icon }

        </button> ) }

    </div> );

}

/** @export Pagination */
 
export default Pagination;