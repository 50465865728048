/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import icons */

import { 
    
    AiOutlineCheck, 
    
    AiOutlineClose }        from "react-icons/ai";

/**
 * 
 * @param { quantity } param0 
 * 
 * @returns Single product quantity data component
 * 
 */

const Stock = ( { quantity } ) => { const 

    { t } = useTranslation (),

    /** @returns Quantity text */

    Quantity = ( { qty } ) => { const

        /** Icon */

        icon = ( qty ) ? <AiOutlineCheck /> : <AiOutlineClose />,

        text = ( qty ) ? ( qty > 9 ) ? `${ t ( "available" ) }: 9+` : `${ t ( "available" ) }: ${ qty }` : t ( "unavailable" );

        /** @returns Stock component content */

        return <div className="stock">

            <span className={ qty ? "icon color-in-stock" : "icon color-out-of-stock" }>

                { icon }

            </span>

            <span className={ ! qty ? "notice" : undefined }>

                { text }

            </span>

        </div>

    };

    /** Return component */

    return ( <Quantity qty={ quantity } /> );

}

/** Export component */
 
export default Stock;