/** @import models */

import { ServicesModel }                from "../../Models/Services";

import { useTranslation }               from "react-i18next";

import cookies                          from "js-cookie";

import { Link }                         from "react-router-dom";

/** @import components */

import ComponentLoader                  from "../Layout/Loaders/Loader";

import NoImage                          from "../../Assets/no-image.jpg";

const List = () => { const { t } = useTranslation (),

    { loading, dataset } = ServicesModel (),

    Collection = ( { object, status } ) => {

        if ( status ) return <ComponentLoader />

        const filter = object.services.edges.filter ( obj => obj.node.language === cookies.get ( 'i18next' ) && obj.node.isActive === true );

        return filter.map ( ( service, index ) => <Link to={ `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_services__" ) }/${ service.node.id }/${ encodeURIComponent ( service.node.title ) }` } key={ index }>

            <img src={ service.node.image } alt={ service.node.title } onError={ ( { currentTarget } ) => {

                currentTarget.onerror = null;

                currentTarget.src = NoImage;

            } } />

            <div className="title">

                { service.node.title }

            </div>

        </Link> );

    };

    /** @return component */

    return ( <div className="list">

        <Collection object={ dataset } status={ loading } />

    </div> );

}

/** @export List */
 
export default List;