/** @import modules */

import { useTranslation }   from "react-i18next";

import { useState }         from "react";

import { useNavigate }      from "react-router-dom";

/** @import models */

import StringSearchResults  from "../../../Models/QuickSearch";

/** Import icons */

import { GiCarWheel }       from "react-icons/gi";

import { FaSearch }         from "react-icons/fa";

/**
 * 
 * @returns Breadcrumbs quick search form
 * 
 */

const QuickSearch = () => { const { t } = useTranslation (),

    [ search, setSearch ] = useState ( "" ),

    { refetch } = StringSearchResults (),

    navigate = useNavigate (),

    Search = ( event ) => {

        event.preventDefault ();

        refetch ( { first: 12, query: search } );

        return navigate (

            `/shop/products/search/${ new URLSearchParams ( { search: search } ).toString () }`

        );

    }

    /** Return component */

    return (<div className="quick-search">

        <form onSubmit={ e => Search ( e ) }>

            <div className="icon">

                <GiCarWheel />

            </div>

            <div className="input">

                <input 
                
                    type="text" 
                
                    value={ search } 

                    placeholder={ t ( "string_search_placeholder" ) }
                
                    onChange={ e => setSearch ( e.target.value ) } />

            </div>

            <div className="submit">

                <button>
                    
                    <FaSearch />
                    
                </button>

            </div>

        </form>

    </div>);

}

/** Export component */
 
export default QuickSearch;