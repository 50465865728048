/** @import modules */

import { useTranslation }       from "react-i18next";

/**
 * 
 * @param { data } 
 * 
 * @returns Requisites compontent for contacts page
 * 
 */

const Requisites = ( { data } ) => { const 

    { t } = useTranslation (),

    List = [

        { name: t ( "company_name" ),               value: data.company_name,           marked: false   },

        { name: t ( "reg_no" ),                     value: data.reg_no,                 marked: true    },

        { name: t ( "legal_address" ),              value: data.legal_address,          marked: true    },

        { name: t ( "factual_address" ),            value: data.factual_address,        marked: true    },

        { name: t ( "bank_type" ),                  value: data.bank,                   marked: true    },

        { name: t ( "bank_account_number" ),        value: data.bank_account_number,    marked: true    }

    ],

    Collection = ( { param } ) => <ul>
        
        { param.map ( ( req, index ) => <li key={ index }>

            { ( req.marked ) ? `${ req.name }: ${ req.value }` : `${ req.value }` }

        </li> ) }
        
    </ul>;

    /** @return component */

    return ( <div className="requisites">

        <div className="headline">

            { t ( "requisites" ) }

        </div>

        <div className="list">

            <Collection param={ List } />

        </div>

    </div> );

}
 
/** @export Requisites */

export default Requisites;