/** @import modules */

import { useTranslation } from "react-i18next";

/** @import icons */

import { FaSadTear } from "react-icons/fa";

/**
 * 
 * @returns no product found notice
 * 
 */

const NoProductsFound = () => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="no-products-found">

        <span>

            <FaSadTear />

        </span>

        <div>

            <div>{ t ( "found_nothing" ) }</div>

            <div>{ t ( "no_product_found" ) }</div>

        </div>

    </div> );

}

/** @exports NoProductsFound */
 
export default NoProductsFound;