/** @import components */

import Thumbnail from "./Thumbnail";

/**
 * 
 * @param { children, data } 
 * 
 * @returns Single product data layout component
 * 
 */

const SingleProductData = ( { children, data } ) => {

    /** Return component */

    return ( <div className="product">

        <Thumbnail data={ data } />

        <div className="data">

            { children }
            
        </div>

    </div> );

}

/** Export component */
 
export default SingleProductData; 