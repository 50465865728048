/** @import modules */

import { Fragment } from "react";

import { useTranslation } from "react-i18next";

const Features = ( { product } ) => { const { t } = useTranslation (),

    /** Features list */

    collection = [

        { value: product.season, case: true, tooltip: `${ t ( "season" ) }: ${ t ( product.season ) }` },

        { value: ( product.specifications.length ) ? "OE" : false, case: true, tooltip: product.specifications.join ( ', ' ) },

        { value: product?.year ? "DOT" : null, case: false, tooltip: `${ t ( "manufacturing_year" ) }: ${ t ( product?.year || "-" ) }` },

        { value: product?.runFlat ? "RFT" : null, case: false, tooltip: `${ t ( "runFlat" ) }` },

    ];

    /** Return component */

    return ( <div className="features">
        
        { collection.map ( ( feature, index ) => <Fragment key={ index }>
        
            { feature.value && 
            
                <div className={ `feature ${ feature.case && "uppercase" }` } key={ index }>

                    <div className="feature-tooltip">

                        { feature.tooltip }

                    </div>

                    { feature.value }

                </div> 

            } 
            
        </Fragment> ) }
        
    </div>  );

}

/** Export component */
 
export default Features;