/**
 * 
 * @param { product } Object
 * 
 * @returns Product click event
 * 
 * @description This function is used to track the product click event.
 * 
 */

export const productClick = product => {

    /* 1. Clear the previous ecommerce object. */

    window.dataLayer.push ( { ecommerce: null } );

    /* 2. Push the new ecommerce object. */

    window.dataLayer.push ( {

        event: 'productClick',

        ecommerce: {

            click: {

                actionField: { list: `${ product.productType } catalog` },

                products: [ {

                    name: product.model,

                    id: product.id,

                    price: parseFloat ( product.priceList.retail_price ).toFixed ( 2 ),

                    brand: product.manufacturer.name.toUpperCase (),

                    category: product.productType

                } ]

            }

        },

    } );

};

/**
 * 
 * @param { product } Object
 * 
 * @param { qty } Number
 * 
 * @returns Product add to cart event
 * 
 * @description This function is used to track the product add to cart event.
 * 
 */

export const productAddToCart = ( product, qty ) => {

    /* 1. Clear the previous ecommerce object. */

    window.dataLayer.push ( { ecommerce: null } );

    /* 2. Push the new ecommerce object. */

    window.dataLayer.push ( {

        event: 'addToCart',

        ecommerce: {

            currencyCode: 'EUR',

            add: {

                products: [ {

                    name: product.model,

                    id: product.id,

                    price: parseFloat ( product.priceList.retail_price ).toFixed ( 2 ),

                    brand: product.manufacturer.name.toUpperCase (),

                    category: product.productType,

                    quantity: qty

                } ]

            }

        }

    } );

};

/**
 * 
 * @param { products } Array
 * 
 * @param { cart } Array
 * 
 * @returns Checkout click event
 * 
 * @description This function is used to track the checkout click event.
 * 
 */

export const onCheckoutClick = ( navigate, products = [], cart = [] ) => {

    /* 1. Clear the previous ecommerce object. */

    window.dataLayer.push ( { ecommerce: null } );

    /* 2. Push the new ecommerce object. */

    window.dataLayer.push ( {

        event: 'checkout',

        ecommerce: {

            checkout: {

                actionField: { step: 1 },

                products: products.map ( product => ( {

                    name: product.node.model,

                    id: product.node.id,

                    price: parseFloat ( product.node.priceList.retail_price ).toFixed ( 2 ),

                    brand: product.node.manufacturer.name.toUpperCase (),

                    category: product.node.productType,

                    quantity: cart.find ( item => item.id === product.node.id ).qty

                } ) )

            }

        },

        eventCallback: () => navigate ( "/orders/checkout" )

    } );

};

/**
 * 
 * @param { product } Object
 * 
 * @returns Product remove from cart event
 * 
 * @description This function is used to track the product remove from cart event.
 * 
 */

export const productRemoveFromCart = product => {

    /* 1. Clear the previous ecommerce object. */

    window.dataLayer.push ( { ecommerce: null } );

    /* 2. Push the new ecommerce object. */

    window.dataLayer.push ( {

        event: 'removeFromCart',

        ecommerce: {

            currencyCode: 'EUR',

            remove: {

                products: [ {

                    name: product.model,

                    id: product.id,

                    price: parseFloat ( product.priceList.retail_price ).toFixed ( 2 ),

                    brand: product.manufacturer.name.toUpperCase (),

                    category: product.productType

                } ]

            }

        }

    } );

};

/**
 * 
 * @param { step } String
 * 
 * @param { option } String
 * 
 * @returns Checkout option event
 * 
 * @description This function is used to track the checkout option event.
 * 
 */

export const onCheckoutOption = ( step, option ) => {

    /* 1. Clear the previous ecommerce object. */

    window.dataLayer.push ( { ecommerce: null } );

    /* 2. Push the new ecommerce object. */

    window.dataLayer.push ( {

        event: 'checkoutOption',

        ecommerce: {

            checkout_option: {

                actionField: { step, option }

            }

        }

    } );

}

/**
 * 
 * @param { order } Object
 * 
 * @returns Purchase event
 * 
 * @description This function is used to track the purchase event.
 * 
 */

export const onPurchase = order => {

    /* 1. Clear the previous ecommerce object. */

    window.dataLayer.push ( { ecommerce: null } );

    /* 2. Push the new ecommerce object. */

    window.dataLayer.push ( {

        event: 'purchase',

        ecommerce: {

            purchase: {

                actionField: {

                    id: order.id,

                    affiliation: order.orderType,

                    revenue: parseFloat ( order.price ).toFixed ( 2 ),

                    tax: parseFloat ( order.price * 0.21 ).toFixed ( 2 ),

                    shipping: parseFloat ( order.deliveryPrice ).toFixed ( 2 )

                },

                products: order.orderProductVendors.map ( product => ( {

                    name: product.product.model,

                    id: product.product.id,

                    price: parseFloat ( product.price ).toFixed ( 2 ),

                    brand: product.product.manufacturer.name.toUpperCase (),

                    category: product.product.productType,

                    quantity: product.count

                } ) )

            }

        }

    } );

};