/** Import modules */

import { useQuery } from "@apollo/client";

/** Import queries */

import { Articles } from "../Queries/Blog";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Blog posts collection model
 * 
 */

const Collection = ( params ) => { const 
    
    decode = new URLSearchParams ( params ),

    { loading, data } = useQuery ( Articles, { variables: ( ! params ) ? { first: 6 } : {

        first   : parseInt ( decode.get( 'f' ) ),
    
        last    : parseInt ( decode.get( 'l' ) ),
    
        before  : decode.get ( 'b' ),
    
        after   : decode.get ( 'a' )
    
    } } );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default Collection;