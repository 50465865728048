/** @import modules */

import { useTranslation             } from "react-i18next";

/** @import icons */

import { FaShuttleVan               } from "react-icons/fa";

import { RiSecurePaymentFill        } from "react-icons/ri";

import { GiCarWheel                 } from "react-icons/gi";

/**
 * 
 * @returns benefits component
 * 
 */

const Benefits = () => { const { t } = useTranslation (),

    sections = [

        {

            icon        : <FaShuttleVan />,

            title       : t ( "free_delivery" ),

            description : t ( "free_delivery_desc" )

        },
        
        {

            icon        : <RiSecurePaymentFill />,

            title       : t ( "safe_payments" ),

            description : t ( "safe_payments_desc" )

        },

        {

            icon        : <GiCarWheel />,

            title       : t ( "tire_montage" ),

            description : t ( "tire_montage_desc" )

        }

    ];

    /** @returns */

    return ( <div className="benefits">

        <div className="max-content-width">

            <div className="list">

                { sections.map ( ( section, index ) => <div className="section" key={ index }>

                    <div className="icon">

                        { section.icon }

                    </div>

                    <div className="title">

                        { section.title }

                    </div>

                    <div className="description">

                        { section.description }

                    </div>

                </div> ) }

            </div>

        </div>

    </div> );

}

/** @exports Benefits */
 
export default Benefits;