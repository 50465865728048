/** @import modules */

import { gql } from "@apollo/client";

const 

/** Banners */

BANNERS = gql`query { banners {

    id

    image

    isActive

    language

    location

    link

    position

    title

    tillDate

    fromDate

} }`;

/** Export queries */

export { BANNERS };