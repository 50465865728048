/** Import hooks */

import { isArray } from "@apollo/client/cache/inmemory/helpers";
import { Cleaner } from "../Misc/ObjectCleaner";

const

/** @returns URL parameters */

RimsNavigator = ( params, update ) => { const parameters = new URLSearchParams ( params ),

    /** Register attributes */

    attributes = {

        f       : parseInt      ( parameters.get ( "f" ) ),

        l       : parseInt      ( parameters.get ( "l" ) ),

        b       : parameters.get ( "b" ),

        a       : parameters.get ( "a" ),

        m       : parameters.get ( "m" ),

        mo      : parameters.get ( "mo" ),
        
        pcd     : parameters.get ( "pcd" ),

        o       : parameters.get ( "o" ),

        pr      : parameters.get ( "pr" ),

        r       : parameters.get ( "r" ),

        sort    : parameters.get ( "sort" ),

        av      : parameters.get ( "av" )

    };

    /** Optimize, clean & return attributes */

    return Cleaner ( { ...attributes, ...update } );

},

/** @returns Query attributes based on URL parameters */

QueryAttributes = ( params ) => { const parameters = new URLSearchParams ( params );

    /** Optimize, clean & return query attributes */

    return Cleaner ( {

        first                       : parseInt      ( parameters.get ( "f" ) ),

        last                        : parseInt      ( parameters.get ( "l" ) ),

        before                      : parameters.get ( "b" ),

        after                       : parameters.get ( "a" ),

        sortOrder                   : parameters.get ( "sort" ),

        manufacturer                : parameters.get ( "m" ) ? parameters.get ( "m" ).split ( "," ) : [],

        diameter                    : parameters.get ( "r" ) ? parameters.get ( "r" ).split ( "," ).map ( radius => radius ) : [],

        model                       : parameters.get ( "mo" ) ? parameters.get ( "mo" ).split ( "," ) : [],

        offset                      : parseInt ( parameters.get ( "o" ) ),

        upperPriceRange             : ( params.get("pr") ) ? parseInt ( params.get("pr").split ( "," )[1] ) : null,
        
        lowerPriceRange             : ( params.get("pr") ) ? parseInt ( params.get("pr").split ( "," )[0] ) : null,

        pitchCircleDiameter         : parameters.get ( "pcd" ),

        availability                : parameters.get ( "av" ) ? parameters.get ( "av" ).split ( "," ) : []

    } );

};

/** Export hooks */
 
export { RimsNavigator, QueryAttributes };