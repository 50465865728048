/** @imports modules */

import { Fragment }         from "react";

/** @imports components */

import Banners              from "../../Models/Banners";

import ComponentLoader      from "../Layout/Loaders/Loader";

/**
 * 
 * @returns front-page banners
 * 
 */

const HomeBanner = () => { const { data, loading } = Banners (),

    validateBanner = banner => { const 

        topBanners = banner.filter ( ( curr ) => { 
                
            return Date.parse ( curr.tillDate ) > new Date () 
            
            && 
            
            Date.parse ( curr.fromDate ) < new Date () 

            &&
            
            curr.isActive === true

            &&

            curr.position === "top"
        
        } ),

        bottomBanners = banner.filter ( ( curr ) => { 
                
            return Date.parse ( curr.tillDate ) > new Date () 
            
            && 
            
            Date.parse ( curr.fromDate ) < new Date () 

            &&

            curr.isActive === true

            &&

            curr.position === "bottom"
        
        } ),
        
        combined = topBanners.concat ( bottomBanners );

        return ( combined.length > 0 ) ? combined[ Math.floor ( Math.random () * combined.length ) ] : false;

    },

    Banner = () => {

        if ( loading ) return <ComponentLoader />

        const banners = validateBanner ( data.banners );

        return ( banners ) ? <div className="banner">

            <div className="max-content-width">

                <a href={ banners.link }>

                    <img src={ banners.image } alt={ banners.title } />

                </a>

            </div>

        </div> : <Fragment />

    };

    /** @returns */

    return ( <Banner /> );

}

/** @exports HomeBanner */
 
export default HomeBanner;