/** Import hooks */

import { Cleaner } from "../Misc/ObjectCleaner";

const

/** @returns URL parameters */

BatteriesNavigator = ( params, update ) => { const parameters = new URLSearchParams ( params ),

    /** Register attributes */

    attributes = {

        f       : parseInt      ( parameters.get ( "f" ) ),

        l       : parseInt      ( parameters.get ( "l" ) ),

        b       : parameters.get ( "b" ),

        a       : parameters.get ( "a" ),

        v       : parameters.get ( "v" ),

        c       : parameters.get ( "c" ),

        m       : parameters.get ( "m" ),

        ca      : parameters.get ( "ca" ),

        pr      : parameters.get ( "pr" ),

        sort    : parameters.get ( "sort" ),

        av      : parameters.get ( "av" )

    };

    /** Optimize, clean & return attributes */

    return Cleaner ( { ...attributes, ...update } );

},

/** @returns Query attributes based on URL parameters */

QueryAttributes = ( params ) => { const parameters = new URLSearchParams ( params );

    /** Optimize, clean & return query attributes */

    return Cleaner ( {

        first                       : parseInt      ( parameters.get ( "f" ) ),

        last                        : parseInt      ( parameters.get ( "l" ) ),

        before                      : parameters.get ( "b" ),

        after                       : parameters.get ( "a" ),

        voltage                     : parameters.get ( "v" ) ? parameters.get ( "v" ).split ( "," ).toString () : [],

        current                     : parameters.get ( "c" ),

        capacity                    : parameters.get ( "ca" ),

        manufacturer                : parameters.get ( "m" ) ? parameters.get ( "m" ).split ( "," ) : [],

        upperPriceRange             : ( params.get("pr") ) ? parseInt ( params.get("pr").split ( "," )[1] ) : null,
        
        lowerPriceRange             : ( params.get("pr") ) ? parseInt ( params.get("pr").split ( "," )[0] ) : null,

        sortOrder                   : parameters.get ( "sort" ),

        availability                : parameters.get ( "av" ) ? parameters.get ( "av" ).split ( "," ) : []

    } );

};

/** Export hooks */
 
export { BatteriesNavigator, QueryAttributes };