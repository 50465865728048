/** Import modules */

import { useTranslation }   from "react-i18next";

import { useQuery }         from "@apollo/client";

import { FrontEndPages }    from "../../Queries/Policies";

import ComponentLoader from "../../Components/Layout/Loaders/Loader";

import cookies              from "js-cookie";

import parse               from "html-react-parser";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import ContentsLayout       from "../../Components/Contents/Layout";

/**
 * 
 * @returns About us page
 * 
 */

const About = () => { const { t } = useTranslation (),

    { data, loading } = useQuery ( FrontEndPages, { variables: { pageType: "about", language: cookies.get ( 'i18next' ) } } );

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "about_us" ), URI: "/content/about" }

        ] } />

        <ContentsLayout title={ t ( "about_us" ) }>
            
            { 

                loading ? <ComponentLoader /> :

                    data?.frontendPages?.edges[0]?.node?.content ?
            
                        parse ( data.frontendPages.edges[0].node.content ) :

                        <p>Unable to retrive data.</p>
                
            }

        </ContentsLayout>

        
        
    </div> );

}

/** Export component */
 
export default About;