/** @import icons */

import { LuGaugeCircle }            from "react-icons/lu";

import { AiOutlineColumnWidth       } from "react-icons/ai";

import { BsCloudRainFill            } from "react-icons/bs";

import { MdLocalGasStation          } from "react-icons/md";

import { GiVibratingBall            } from "react-icons/gi";

const 

productProperties = ( product, t ) => {

    /** @returns tires */

    if ( product.productType === "tire" ) return [

        { 
            
            icon: <BsCloudRainFill />, 

            type: "chart",
            
            color: ( product.wetBreakingEfficiency ) ? product.wetBreakingEfficiency.toLowerCase () : null, 
            
            value: ( product.wetBreakingEfficiency ) ? product.wetBreakingEfficiency.toLowerCase () : null, 

            label: ( product.wetBreakingEfficiency ) ? product.wetBreakingEfficiency.toLowerCase () : null,
            
            tooltip: t ( "wet_breaking" ),
        
            case: "uppercase" },

        { 
            
            icon: <MdLocalGasStation />, 

            type: "chart",
            
            color: ( product.fuelEfficiency ) ? product.fuelEfficiency.toLowerCase () : null, 
            
            value: ( product.fuelEfficiency ) ? product.fuelEfficiency.toLowerCase () : null, 

            label: ( product.fuelEfficiency ) ? product.fuelEfficiency.toLowerCase () : null,
            
            tooltip: t ( "fuel_efficiency" ),
        
            case: "uppercase" },

        { 
            
            icon: <GiVibratingBall />, 

            type: "tag",
            
            value: ( product.noiseIndex === 0 ) ? null : product.noiseIndex, 

            label: `${ product.noiseIndex } dB`,
            
            tooltip: t ( "noise_index" ),
        
            case: "lowercase" },

    ];

    /** @returns rims */

    if ( product.productType === "rim" ) return [

        { 
            
            icon: <AiOutlineColumnWidth />, 

            type: "tag",
            
            value: ( product.width === 0 ) ? null : product.width, 

            label: product.width,
            
            tooltip: t ( "width" ),
        
            case: "lowercase" },

        { 
            
            icon: <LuGaugeCircle />, 

            type: "tag",
            
            value: ( product.diameter === 0 ) ? null : product.diameter, 

            label: `R${ parseInt ( product.diameter ) }`,
            
            tooltip: t ( "radius" ),
        
            case: "lowercase" }

    ];

    /** @returns batteries */

    if ( product.productType === "battery" ) return [

        { 

            type: "text",
            
            value: ( product.voltage === 0 ) ? null : parseFloat ( product.voltage ), 

            label: `${ product.voltage } V`,
            
            tooltip: t ( "voltages" ),
        
            case: "lowercase" },

        { 

            type: "text",
            
            value: ( product.current === 0 ) ? null : parseFloat ( product.current ), 

            label: `${ product.current } A`,
            
            tooltip: t ( "currents" ),
        
            case: "lowercase" },

        {  

            type: "text",
            
            value: ( product.capacity === 0 ) ? null : parseFloat ( product.capacity ), 

            label: `${ product.capacity } Ah`,
            
            tooltip: t ( "capacities" ),
        
            case: "lowercase" }

    ];

    /** @returns default */

    return [];

}

/** @exports */

export { productProperties }