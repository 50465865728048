import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports DocumentParameters
 * 
 * @description document parameters
 * 
 * @param { Object } props
 * 
 * @param { Object } props.order
 * 
 * @returns JSX.Element
 * 
 * @example <DocumentParameters order={ order } />
 * 
 */

export default function DocumentParameters ( { state } ) {

    const 

    generateDate = ( current = true ) => {

        const 
        
        currentDate = new Date (),

        currentDueDate = new Date ( currentDate.getTime () + 172800000 ),

        day = current ? String ( currentDate.getDate () ).padStart ( 2, '0' ) : String ( currentDueDate.getDate () ).padStart ( 2, '0' ),

        month = current ? String ( currentDate.getMonth () + 1 ).padStart ( 2, '0' ) : String ( currentDueDate.getMonth () + 1 ).padStart ( 2, '0' ),

        year = current ? currentDate.getFullYear () : currentDueDate.getFullYear (),

        hours = String ( currentDate.getHours () ).padStart ( 2, '0' ),

        minutes = String ( currentDate.getMinutes () ).padStart ( 2, '0' );

        return current ? `${ day }.${ month }.${ year } - ${ hours }:${ minutes }` : `${ day }.${ month }.${ year }`;

    };

    /**
     * 
     * @description
     * 
     * Render document parameters
     * 
     * @returns JSX.Element
     * 
     */

    return (

        <View style={ { flexDirection: "row", gap: 5, justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap" } }>

            <Text style={ { fontWeight: 500 } }>Nr: { state.invoiceNumber }</Text>

            <Text>Izveidots: { generateDate () }</Text>

            <Text>Apmaksāt līdz: { generateDate ( false ) }</Text>

            <Text render={ ( { pageNumber, totalPages } ) => `${ pageNumber } / ${ totalPages }` } />

        </View>

    );

};