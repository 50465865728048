/** Import modules */

import { useQuery }             from "@apollo/client";

/** Import queries */

import { Appointments           } from "../Queries/Appointments";

import { Appointment            } from "../Queries/Appointments";

import { AppointmentExtended    } from "../Queries/Appointments";

import { WORKING_DAYS           } from "../Queries/Appointments";

/**
 * 
 * @param { parameters } 
 * 
 * @returns appointments list model
 * 
 */

const AppointmentsModel = ( parameters ) => { const

    { loading, data } = useQuery ( Appointments, { variables: {

        transportType   : parameters.type,

        year            : parseInt ( parameters.year ),

        month           : parseInt ( parameters.month ),

        day             : parseInt ( parameters.day )

    }, fetchPolicy: "no-cache", nextFetchPolicy: "no-cache" } );

    /** @return model */

    return { loading, data }

};

/**
 * 
 * @param { uuid }
 *  
 * @returns shortened appointment data list
 * 
 */

const AppointmentModel = ( uuid ) => { const 

    { loading, data } = useQuery ( Appointment, { variables: { uuid } } );

    /** @return model */

    return { loading, data }

};

const ExtendedAppointmentModel = uuid => { const 

    { loading, data } = useQuery ( AppointmentExtended, { variables: { uuid } } );

    /** @return model */

    return { loading, data } 

};

/** @returns montage working days */

const WorkingDays = () => { const

    { loading, data } = useQuery ( WORKING_DAYS );

    /** Return model */

    return { loading, data }

}

/** @export models */

export { 
    
    AppointmentsModel, 
    
    AppointmentModel,

    ExtendedAppointmentModel,

    WorkingDays

};