/** Import modules */
import { 
    ApolloClient, 
    InMemoryCache, 
    from 
} from "@apollo/client";

import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';


import { relayStylePagination } from '@apollo/client/utilities';

/** Configure apollo client */
const Apollo = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    singleStringSearch: relayStylePagination()
                }
            }
        }
    }),

    link: from([
        createUploadLink({ 
            uri: process.env.REACT_APP_GQL_URI,
            // Include credentials and headers if needed
            // credentials: 'same-origin',
            // headers: {
            //     authorization: `Basic ${window.btoa("tireshop:a5a0c481050d4218475cd15a8fe54957")}`
            // }
        })
    ])
});

/** Export apollo configuration */
export default Apollo;
