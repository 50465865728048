/** @import modules */

import { 
    
    Trans, 
    
    useTranslation }        from "react-i18next";

import { Link }             from "react-router-dom";

/** @import models */

import Articles             from "../../Models/HomeArticles";

/** @import components */

import HomeHeading          from "./Heading";

import ComponentLoader      from "../Layout/Loaders/Loader";

import Article              from "../Blog/Article";

import { Swiper, SwiperSlide }      from "swiper/react";

import { Navigation, Autoplay } from "swiper";

import "swiper/css";

import "swiper/css/navigation";

/** Middleware */

//SwiperCore.use ( [ Navigation, Pagination ] );

/**
 * 
 * @returns Home sale component
 * 
 */

const HomeBlog = () => { const { t } = useTranslation (),

    { data, loading } = Articles (),

    Layout = () => {

        if ( loading ) return <ComponentLoader />

        return <div className="articles">
            
            { data.blogItems.edges.map ( ( post, index ) => 

                <Article post={ post.node } key={ index } />

            ) }
        
        </div>

    },

    SwiperLayout = () => {

        if ( loading ) return <ComponentLoader />

        return <Swiper 
        
            slidesPerView={ 1 } 
            
            loop={ true } 
            
            autoplay={ {

                delay: 2500,

                disableOnInteraction: false,

            } } 
          
            spaceBetween={ 24 } 
            
            navigation={ true } 
            
            modules={ [ Navigation, Autoplay ] } 
            
            className="swiper-blog-posts">
            
            { data.blogItems.edges.map ( ( post, index ) => <SwiperSlide key={ index }>

                <Article post={ post.node } />

            </SwiperSlide> ) }

        </Swiper>

    }

    /** Return component */

    return ( <div className="max-content-width" >
    
        <div className="blog">

            <HomeHeading 
                
                title={ t ( "news_on_blog" ) }
            
                description={ <Trans>

                    { t ( "news_on_blog_desc" ) }

                    <Link to="/blog">{ t ( "got_to_blog" ) }</Link>

                </Trans> } />

            <div className="content">

                <SwiperLayout />

                <Layout />

            </div>

        </div>
    
    </div>);

}

/** Export component */
 
export default HomeBlog;