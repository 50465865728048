/** @import modules */

import { useTranslation }       from "react-i18next";

/** @import components */

import Manufactures             from "../Components/Products/Rims/Catalog/Filters/Manufacturers";

import Offset                   from "../Components/Products/Rims/Catalog/Filters/Offset";

import Models                   from "../Components/Products/Rims/Catalog/Filters/Models";

import PitchCircleDiameter      from "../Components/Products/Rims/Catalog/Filters/PitchCircle";

import PriceRange               from "../Components/Products/Rims/Catalog/Filters/Price";

import Radius                   from "../Components/Products/Rims/Catalog/Filters/Radius";

import AvailabilityFilter       from "../Components/Products/Rims/Catalog/Filters/Availability";

const 

/** @return filter widgets */

FiltersCollection = ( data ) => { const { t } = useTranslation (),

    { 

        manufacturers,

        offsets,

        models,

        pitch_circle_diameters,

        min_price,

        max_price,

        radiuses
    
    } = data.searchAttributes.rims[0],

    Manufacturers = [ ...manufacturers ].sort( ( a, b ) => a.text.localeCompare ( b.text ) ),

    RimModels = [ ...models ].sort( ( a, b ) => a.text.localeCompare ( b.text ) ),

    Offsets = [ ...offsets ].sort ( ( a, b ) => parseInt ( a.value ) - parseInt ( b.value ) );

    return [

        { 
            
            title: t ( "availability" ), 
        
            content: <AvailabilityFilter />, 
        
            toggle: true,
        
            position: "open" },

        { 

            title: t ( "radius" ), 
        
            content: <Radius attributes={ radiuses } />, 
        
            toggle: true },

        { 
    
            title: t ( "pcd" ), 
        
            content: <PitchCircleDiameter attributes={ pitch_circle_diameters } />, 
        
            toggle: true },

        { 
        
            title: t ( "offset" ), 
        
            content: <Offset attributes={ Offsets } />, 
        
            toggle: true },

        { 
            
            title: t ( "manufacturer" ), 
        
            content: <Manufactures attributes={ Manufacturers } />, 
        
            toggle: true },

        { 
        
            title: t ( "model" ), 
        
            content: <Models attributes={ RimModels } />, 
        
            toggle: true },

        { 
    
            title: t ( "price_range" ), 
        
            content: <PriceRange min={ min_price } max={ max_price } />, 
        
            toggle: true,
        
            position: "open" }

    ];

};

/** @export datasets */

export { 
    
    FiltersCollection

}