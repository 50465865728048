/** @import modules */

import { useContext }       from "react";

import { useTranslation }   from "react-i18next";

/** @import contexts */

import { MontageContext }   from "../../Contexts/MontageContext";

/** @import components */

import MontageCalendar      from "./Forms/Calendar";

import Datetime             from "./Forms/Datetime";

import Form                 from "./Forms/Form";

import NoticeNeutral        from "../Layout/Notices/Neutral";

const Forms = () => { const { Type } = useContext ( MontageContext ),

    { t } = useTranslation ();

    /** @return component */

    return ( <div className="montage-application">

        { Type.view ? <>

            <MontageCalendar />

            <Datetime />

            <Form />

        </> : <NoticeNeutral notice={ t ( "select_service_type" ) } /> }

    </div> );

}

/** @export Forms */
 
export default Forms;