/** @import modules */

import { useTranslation }       from "react-i18next";

/** @import components */

import ContactsLayout           from "../Components/Contacts/ContactsLayout";

import ContactsTeam             from "../Components/Contacts/Team";

/** @import icons */

import { 
    
    AiFillYoutube, 
    
    AiFillFacebook, 
    
    AiFillInstagram }           from "react-icons/ai";

/**
 * 
 * @returns Contacts datasets
 * 
 */

const ContactsDataset = () => { const { t } = useTranslation ();

    /** Return datasets */

    return {

        /** Contacts page sections */

        Sections: [

            { 
                
                title: t ( "contacts" ), 
            
                content: <ContactsLayout /> },

            { 
                
                title: t ( "team" ), 
            
                content: <ContactsTeam /> }

        ]

    }

},

SocialNetworks = () => {

    return [

        { 
            
            name    : "Facebook", 
        
            URL     : "https://www.facebook.com/tireshopriga/", 
            
            icon    : <AiFillFacebook /> },

        { 
            
            name    : "Instagram", 
        
            URL     : "https://www.instagram.com/tireshop_riga", 
            
            icon    : <AiFillInstagram /> },

        { 
            
            name    : "Youtube", 
        
            URL     : "https://www.youtube.com/channel/UCaRIWrjlKhXR7NVis1TidvA", 
            
            icon    : <AiFillYoutube /> }

    ];

}

/** Export datasets */

export { ContactsDataset, SocialNetworks };