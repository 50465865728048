/** @module */

import { SocialNetworks } from "../../../Datasets/Contacts";

/**
 * 
 * @returns footer social media links
 * 
 */

const Socials = () => {

    /** @returns */

    return ( <div className="social-networks">

        { SocialNetworks ().map ( ( network, index ) => <a href={ network.URL } key={ index } target="_blank" rel="noreferrer">
        
            { network.icon }
        
        </a> ) }

    </div> );

}

/** @exports Socials */
 
export default Socials;