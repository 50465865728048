/** Import icons */

import { FaList }           from "react-icons/fa";

import { BsGrid3X3GapFill }   from "react-icons/bs";

/**
 * 
 * @param { layout } param0 
 * 
 * @returns Product layout component
 * 
 */

const Display = ( { layout } ) => {

    /** Return component */

    return ( <div className="display">

        <button onClick={ () => layout.set ( "list" ) } disabled={ layout.view === "list" }>
            
            <FaList />
            
        </button>

        <button onClick={ () => layout.set ( "grid" ) } disabled={ layout.view === "grid" }>
            
            <BsGrid3X3GapFill />
            
        </button>

    </div> );

}

/** Export component */
 
export default Display;