/** Import modules */

import { useState } from "react";

import { useNavigate, useParams }       from "react-router-dom";

import { useTranslation } from "react-i18next";

/** @import components */

import { Slider }                       from "@mui/material";

/** Import hooks */

import { TyresNavigator }               from "../../../../../Hooks/TyresNavigator";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Seasons filter component
 * 
 */

const PriceRange = ( { min, max } ) => { const 

    { t }               = useTranslation    (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    params              = new URLSearchParams ( parameters ),

    [ price, setPrice ] = useState ( params.get ( "pr" ) ?  
    
    [ parseInt ( params.get ( "pr" ).split ( "," )[0] ), parseInt ( params.get ( "pr" ).split ( "," )[1] ) ]
    
    : [ min, max ] ),

    handleChange = ( event, newValue) => {
        
        setPrice ( newValue );
    
    },

    loadRange = () => { const

        object = { f : 12, l : null, a : null, b : null, pr : price, loc: window.pageYOffset };

        return navigate (  
            
            `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
            
                new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

    };

    /** Return component */

    return ( <div className="content price">

        <div className="range">

            <Slider

                value={ price }

                onChange={ handleChange }

                onChangeCommitted={ () => { loadRange () } }

                min={ min }

                max={ max }

                step={ 5 }

                valueLabelDisplay="auto"
                
                color="primary" />

        </div>

        <div className="output">

            <input type="number" value={ price[ 0 ] } readOnly />

            <input type="number" value={ price[ 1 ] } readOnly />
            
        </div>

    </div> );

}

/** Export component */
 
export default PriceRange;