/** @import modules */

import { useQuery }             from "@apollo/client";

/** @import queries */

import { FilterAttributes }     from "../Queries/Batteries";

/**
 * 
 * @returns Batteries product collection model
 * 
 */

const BatteriesAttributes = () => { const

    { loading, data } = useQuery ( FilterAttributes );

    /** @returns model */

    return { loading, data }

}

/** @export model */

export default BatteriesAttributes;