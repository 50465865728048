/** @import components */

import Tyre from "../Components/Products/QuickSearch/Items/Tyre";

import Rim from "../Components/Products/QuickSearch/Items/Rim";

import Battery from "../Components/Products/QuickSearch/Items/Battery";

/** @returns component according to product type */

const ItemComponents = ( { productType, product, notice } ) => {

    if ( productType === "tire"     ) return <Tyre      product={ product } notice={ notice } />

    if ( productType === "rim"      ) return <Rim       product={ product } />

    if ( productType === "battery"  ) return <Battery   product={ product } />

}

/** @export datasets */

export { 
    
    ItemComponents

}