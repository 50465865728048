const 

/** @returns tyre season based on month */

seasonSelector = () => { const 

    currentDate = new Date ().getMonth ();
    
    /** @returns */

    return ( currentDate >= 2 && currentDate < 7 ) ? "s" : "w";

};

/** @exports seasonSelector */

export default seasonSelector;