/** @module                         Hooks */

import { Trans }                    from "react-i18next";

import { Link }                     from "react-router-dom";

/** @module                         React-Icons */

import { FaCheck }                  from "react-icons/fa";

const 

Agreement = ( { view, set, __ } ) => {

    /** @returns */

    return (

        <div className="agreement">

            <p>

                <Trans i18nKey="checkout_policy_message">

                    More information about our data processing policy can be found on the 
                    
                    <Link to="/policies/privacy-policy" target="_blank" rel="noopener noreferrer">

                        privacy policy page.    
                        
                    </Link>.

                </Trans>

            </p>

            <label htmlFor="policy" className="checkbox">

                <input 
                
                    type="checkbox" 
                
                    id="policy"
                
                    value={ view }
                
                    onChange={ () => set ( ! view ) } />

                <span className="check">

                    <span className="checked">

                        <FaCheck />

                    </span>

                </span>

                <span className="name">

                    <Trans i18nKey="checkout_policy">

                        I have read and agree to the page <Link to="/policies/terms-of-use" target="_blank" rel="noopener noreferrer">terms and conditions.</Link>

                    </Trans>

                </span>

            </label>

        </div>

    );

}

/** @exports Agreement */
 
export default Agreement;