/** Import datasets */

import HeaderDatasets       from "../../../Datasets/Header";

/** Import modules */

import { NavLink }          from "react-router-dom";

// import { useTranslation }   from "react-i18next";

/** Import icons */

// import { GiCarWheel }       from "react-icons/gi";

/**
 * 
 * @returns Header navigation component
 * 
 */

const HeaderNavigation = () => { // const { t } = useTranslation ();

    /** Return component */

    return (<div className="navigation">

        <nav>

            <ul>

                { HeaderDatasets ().Shop.map ( ( nav, index ) => <li key={ index }>

                    <NavLink to={ nav.route } className={ `${ ({ isActive }) => (isActive ? 'active' : 'inactive') } ${ ( nav.accent ) ? "accented" : "default" }` }>

                        { nav.name }

                    </NavLink>

                </li> ) }

            </ul>

        </nav>

        {/* <div className="clearance">

            <NavLink to="/shop/products/sale" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>

                { t ( "sale" ) }

                <span>

                    <GiCarWheel />

                </span>

            </NavLink>

        </div> */}

    </div>);

}

/** Export component */
 
export default HeaderNavigation;