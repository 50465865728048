/** @import modules */

import { useTranslation }   from "react-i18next";

import { Helmet }           from "react-helmet-async";

/** @import contexts */

import { MontageContext }   from "../../Contexts/MontageContext";

/** @import hookes */

import { MontageHook }      from "../../Hooks/Montage";

/** @import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import MontageLayout        from "../../Components/Montage/Layout";

import Type                 from "../../Components/Montage/Type";

import Forms                from "../../Components/Montage/Forms";

/**
 * 
 * @returns montage page
 * 
 */

const Montage = () => { const { t } = useTranslation ();

    /** @return component */

    return ( <div className="container max-content-width">

        <Helmet>

            <title>{ `Tireshop.lv | ${ t ( "montage" ) }` }</title>

            <link rel="canonical" href="/content/montage" />

        </Helmet>

        <Breadcrumbs collection={ [

            { name: t ( "montage" ), URI: "/content/montage" }

        ] } />

        <MontageLayout>

            <MontageContext.Provider value={ MontageHook () }>

                <Type />

                <Forms />

            </MontageContext.Provider>

        </MontageLayout>
        
    </div> );

}

/** @export Montage */
 
export default Montage;