/** @module                         GQL */

import { gql }                      from "@apollo/client";

const 

/** @name AVAILABILITY_TYPE_ENUM */

AVAILABILITY_TYPE_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "AvailabilityTypeEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @exports Products */

export { 
    
    AVAILABILITY_TYPE_ENUM

};