import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports function DocumentNotices
 * 
 * @description Export the notices of the document as a React Component
 * 
 * @returns { JSX.Element }
 * 
 */

export default function DocumentNotices () {

    /**
     * 
     * @description 
     * 
     * Render the notices of the document as a React Component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View>

            <Text>Lai paātrinātu pasūtījuma apstrādes procesu,</Text>

            <Text>lūgums, pēc iespējas ātrāk nosūtīt mums maksājuma uzdevumu!</Text>

        </View>

    );

};