/** @import modules */

import { useTranslation }               from "react-i18next";

/** @import models */

import { ExtendedAppointmentModel }     from "../../Models/Appointments";

/** @import datasets */

import { AppointmentData }              from "../../Datasets/Montage";

/** @import components */

import ComponentLoader                  from "../Layout/Loaders/Loader";

/**
 * 
 * @param { appointment } 
 * 
 * @returns appointment information after creation
 * 
 */

const AppointmentCreated = ( { appointment } ) => { const { t } = useTranslation (),

    { loading, data } = ExtendedAppointmentModel ( appointment ),

    /** @returns appointment report layout */

    Layout = () => {

        if ( loading ) return <ComponentLoader />

        /** @return invalid UUID component */

        if ( typeof data === "undefined" ) return <div className="invalid-appointment">

            { t ( "appointment_not_found" ) }

        </div>

        const appointment_data = AppointmentData ( data );

        return <div className="appointment-table">

            { appointment_data.map ( ( attr, index ) => <div className="appointment-row" key={ index }>

                <div className="appointment-data-text">
                    
                    { attr.text }
                
                </div>

                <div className="appointment-data-value">
                    
                    { attr.value }
                
                </div>

            </div> ) }

        </div>

    };

    /** @returns component */

    return ( <Layout /> );

}

/** @export component */
 
export default AppointmentCreated;