/** Import modules */

import { useNavigate, useParams }       from "react-router-dom";

import { useEffect, useState }          from "react";

import { useTranslation }               from "react-i18next";

/** Import hooks */

import { RimsNavigator }                from "../../../../../Hooks/RimsNavigator";

/** Import icons */

import { FaCheck }                      from "react-icons/fa";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Manufacturers filter component
 * 
 */

const Models = ( { attributes } ) => { const 

    { t }                   = useTranslation    (),

    navigate                = useNavigate       (),

    { parameters }          = useParams         (),

    params                  = new URLSearchParams ( parameters ),

    [ search, setSearch ]   = useState ( "" ),

    /** On model change update the query */

    LoadModels = event => { const 

        object = { f : 12, l : null, a : null, b : null, mo : params.get ( "mo" ) ? params.get ( "mo" ).split ( "," ) : [], loc: window.pageYOffset };

        if ( event.target.checked ) { object.mo.push ( event.target.value );

            return navigate (  
            
                `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }/${ 
                
                    new URLSearchParams ( RimsNavigator ( parameters, object ) ).toString () }` );
        
        } else { object.mo.splice ( params.get ( "mo" ).split ( "," ).indexOf ( event.target.value ), 1 );

            return navigate (  
            
                `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }/${ 
                
                    new URLSearchParams ( RimsNavigator ( parameters, object ) ).toString () }` );

        }

    }

    /** Filter manufactures by keyword */

    useEffect ( () => { const collection = document.querySelectorAll ( "#collection label" );

        for ( let index = 0; collection.length > index; index++ ) { const 
            
            current = collection[ index ].querySelector ( "input" );

            if ( current.defaultValue.toUpperCase ().includes ( search.toUpperCase () ) ) {

                collection[ index ].style.display = "";

            } else { collection[ index ].style.display = "none"; }

        }

    }, [ search ] )

    /** Return component */

    return ( <div className="content manufacturers">

        <div className="attribute-filter">

            <input 
            
                type="text" 
            
                placeholder={ t ( "input_name_search" ) } 
            
                value={ search } 
            
                onChange={ e => setSearch ( e.target.value ) } />

            <div className="collection-container">

                <div className="collection" id="collection">

                    { sortPrams ( attributes, params, "mo" ).map ( ( model, index ) => <label 
                    
                        htmlFor={ model.value } 
                        
                        className="checkbox" 
                        
                        key={ index }>

                        <input 

                            type="checkbox" 

                            id={ model.value } 

                            value={ model.text } 

                            onChange={ e => LoadModels ( e ) } 
                            
                            checked={ params.get ( "mo" ) && ( params.get ( "mo" ).split ( "," ).indexOf ( model.text ) !== -1 ) } />

                        <span className="check">

                            <span className="checked">

                                <FaCheck />

                            </span>

                        </span>

                        <span className="name">

                            { model.text.charAt ( 0 ).toUpperCase () + model.text.slice ( 1 ) }

                        </span>

                    </label> ) }

                </div>

            </div>

        </div>

    </div> );

},

/**
 * 
 * @param { attributes } param0
 * 
 * @param { params } param1
 * 
 * @param { key } param2
 * 
 * @returns sorted params
 * 
 * Sort params by checked values
 * 
 * If checked values exist, move them to the top of the list
 * 
 * If checked values don't exist, return the list as it is
 * 
 */

sortPrams = ( attributes, params, key ) => { const

    /** Get checked values */

    checkedValues = params.get ( key ) ? params.get ( key ).split ( "," ) : [],

    /** Get unchecked values */

    loadIndexes = attributes.filter ( attribute => !checkedValues.includes ( attribute.text ) );

    /** If checked values exist, move them to the top of the list */

    if ( checkedValues.length ) { checkedValues.forEach ( checkedValue => { const

        index = attributes.findIndex ( attribute => attribute.text === checkedValue );

        loadIndexes.unshift ( attributes [ index ] );

    } ); }

    /** Return the list */

    return loadIndexes;

}

/** @exports Models */
 
export default Models;