/** Import modules */

import { useParams }        from "react-router-dom";

/** Import models */

import Collection           from "../../Models/BlogCollection";

/** Import components */

import BlogPagination       from "./Pagination";

import ComponentLoader      from "../Layout/Loaders/Loader";

import Article              from "./Article";

/**
 * 
 * @returns Blog posts component
 * 
 */

const BlogCollection = () => { const { loading, data } = Collection ( useParams ().page ),

    Posts = () => {

        if ( loading ) return <ComponentLoader />

        return <div className="articles">
            
            { data.blogItems.edges.map ( ( post, index ) => 

                <Article post={ post.node } key={ index } />

            ) }
        
        </div>

    };

    /** Return component */

    return ( <div className="collection">
            
        <Posts />

        { ! loading && <BlogPagination pageInfo={ data.blogItems.pageInfo } /> }

    </div> );

}

/** Export component */
 
export default BlogCollection;