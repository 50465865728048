/** @import modules */

import { useTranslation }               from 'react-i18next';

/** @import misc */

import seasonSelector                   from '../Misc/SeasonSelector';

/** @import icons */

import { IoChatbubbleEllipsesSharp }    from 'react-icons/io5';

import { MdBarChart }                   from 'react-icons/md';

import { FaShoppingCart }               from 'react-icons/fa';

import { SiGooglestreetview }           from "react-icons/si";

import { FaWaze }                       from "react-icons/fa";

import { BsBriefcaseFill }              from "react-icons/bs";

import { GiCarWheel }                   from "react-icons/gi";

/** Register datasets */

const HeaderDatasets = ( cart, compare ) => { const { t } = useTranslation (),

    Shop = [

        { route: `/shop/products/tyres/f=12&s=${ seasonSelector () }&sort=ASC`,            name: t ( "tyres" ), accent:false         },

        { route: `/shop/products/rims/f=12&sort=ASC`,             name: t ( "rims" ), accent:false          },

        { route: `/shop/products/batteries/f=12&sort=ASC`,        name: t ( "batteries" ), accent:false     },

        { route: `content/services`,               name: t ( "services" ), accent:false      },

        { route: "/content/montage",                name: t ( "montage" ), accent:true      }

    ],

    ShopCollection = [

        { route: `/shop/products/tyres/f=12&sort=ASC`,            name: t ( "tyres" ), accent: false         },

        { route: `/shop/products/rims/f=12&sort=ASC`,             name: t ( "rims" ), accent: false          },

        { route: `/shop/products/batteries/f=12&sort=ASC`,        name: t ( "batteries" ), accent: false     },

        { route: `/content/services`,               name: t ( "services" ), accent: false      },

        { route: "/content/montage",                name: t ( "montage" ), accent: true       },

    ],

    Actions = [

        { 
            id          : "backup",
            
            type        : "labeled", 
        
            name        : t ( "old.tireshop.lv" ), 
        
            icon        : <GiCarWheel />, 
        
            URI         : "https://old.tireshop.lv", 

            link        : "external",
        
            counter     : false,
        
            display     : "desktop",
        
            label       : t ( "old.tireshop.lv" ) },

        { 
            id          : "b2b",
            
            type        : "labeled", 
        
            name        : "B2B", 
        
            icon        : <BsBriefcaseFill />, 
        
            URI         : "http://b2b.tireshop.lv/lv/sign_in", 

            link        : "external",
        
            counter     : false,
        
            display     : "desktop",
        
            label       : t ( "B2B" ) },

        { 
            id          : "contacts",
            
            type        : "labeled", 
        
            name        : t ( "contacts" ), 
        
            icon        : <IoChatbubbleEllipsesSharp />, 
        
            URI         : "/content/contacts", 

            link        : "internal",
        
            counter     : false,
        
            display     : "desktop",
        
            label       : t ( "contacts" ) },

        { 

            id          : "compare",
            
            type        : "glyphed", 
        
            name        : null, 
        
            icon        : <MdBarChart />, 
        
            URI         : "/orders/compare", 

            link        : "internal",
        
            counter     : true,
        
            display     : "mobile",
        
            label       : t ( "comparison" ) },

        { 

            id          : "cart",
            
            type        : "glyphed", 
        
            name        : null, 
        
            icon        : <FaShoppingCart />, 
        
            URI         : "/orders/cart", 

            link        : "internal",
        
            counter     : true,
        
            display     : "mobile",
        
            label       : t ( "shopping_cart" ) },

    ],

    Locations = [

        { 
            
            icon: <FaWaze />, 
        
            URI: "https://ul.waze.com/ul?place=ChIJU7oCGgDS7kYRWG5njTFSuJI&ll=56.92684850%2C24.18595930&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location" },

        { 
        
            icon: <SiGooglestreetview />, 
        
            URI: "https://g.page/tireshopriga?share" },

    ],

    Hours = [

        { name: t ( "weekdays"  ), content: "09:00 - 18:00" },

        { name: t ( "saturdays" ), content: "10:00 - 16:00" },

        { name: t ( "sundays"   ), content: t ( "closed" )  }

    ];

    return {

        Actions, 
        
        Locations, 
        
        Hours, 
        
        ShopCollection, 
        
        Shop

    }

}

/** Export datasets */

export default HeaderDatasets;