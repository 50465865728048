/** Import assets */

import "../../Styles/Footer.css";

/** Import datasets */

import FooterDatasets from "../../Datasets/Footer";

/** Import components */

import ReturnBackTOP from "./Footer/ReturnTop";

/**
 * 
 * @returns Footer component
 * 
 */

const Footer = () => {

    return ( <footer>

        <div className="footer">

            <ReturnBackTOP />

            <div className="max-content-width">

                <div className="footer-layout">

                    { FooterDatasets ().map ( ( footer, index ) =>

                        <div className="pane" key={ index }>

                            { footer.render.map ( pane => 
                            
                                <div key={ pane.id } className="pane-content">{ pane.content }</div> 
                                
                            ) }

                        </div>

                    ) }

                </div>

                <div className="mobile-copyrights">

                    &copy; TIRESHOP { new Date ().getFullYear () }

                </div>

            </div>

        </div>

    </footer>);

}

/** Export component */
 
export default Footer;