/** Import modules */

import { useTranslation }   from "react-i18next";

import { useQuery }         from "@apollo/client";

import { FrontEndPages }    from "../../Queries/Policies";

import ComponentLoader from "../../Components/Layout/Loaders/Loader";

import cookies              from "js-cookie";

import parse               from "html-react-parser";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import PoliciesLayout       from "../../Components/Policies/Layout";

/**
 * 
 * @returns Terms & conditions page
 * 
 */

const TermsOfUse = () => { const { t } = useTranslation (),

    { data, loading } = useQuery ( FrontEndPages, { variables: { pageType: "terms_of_use", language: cookies.get ( 'i18next' ) } } );

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "terms_and_conditions" ), URI: "/policies/terms-of-use" }

        ] } />

        <PoliciesLayout title={ t ( "terms_and_conditions" ) }>

            { 

                loading ? <ComponentLoader /> :

                    data?.frontendPages?.edges[0]?.node?.content ?
            
                        parse ( data.frontendPages.edges[0].node.content ) :

                        <p>Unable to retrive data.</p>
                
            }

        </PoliciesLayout>
        
    </div> );

}

/** Export component */
 
export default TermsOfUse;