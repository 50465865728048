/** @import modules */

import { useTranslation }       from "react-i18next";

import { Fragment }             from "react";

/** @import icons */

import { AiFillThunderbolt }    from "react-icons/ai";

import { FaTruckLoading }       from "react-icons/fa";

const 

/** @returns */

Delivery = ( { product } ) => { const { t } = useTranslation (),

    delivery = [

        { 
            
            name: t ( ( product.productType === "battery" ? "available_stock" : "current_stock" ), { QTY: product.productVendors[0].qty } ), 
            
            quantity: product.productVendors[0].qty, 
            
            icon: <AiFillThunderbolt />, 
            
            style: "delivery_option",
            
            accented: true,

            tooltip: "tooltip_delivery_qty"
        
        },

        { 
            
            name: t ( "express_delivery", { QTY: product.productVendors[0].qtyHour } ), 
            
            quantity: product.productVendors[0].qtyHour, 
            
            icon: <FaTruckLoading />, 
            
            style: "delivery_option default",
            
            accented: true,

            tooltip: "tooltip_delivery_qtyHour"
        
        },

        { 
            
            name: t ( "days_delivery", { QTY: product.productVendors[0].qtyDays } ), 
            
            quantity: product.productVendors[0].qtyDays, 
            
            icon: <FaTruckLoading />, 
            
            style: "delivery_option default",

            accented: false,

            tooltip: "tooltip_delivery_qtyDays"
        
        },

        { 
            
            name: t ( "weeks_delivery", { QTY: product.productVendors[0].qtyWeeks } ), 
            
            quantity: product.productVendors[0].qtyWeeks, 
            
            icon: <FaTruckLoading />, 
            
            style: "delivery_option default", 

            accented: false,

            tooltip: "tooltip_delivery_qtyWeeks"
        
        },

    ];

    return ( <div className="delivery">

        { delivery.map ( ( option, index ) => <Fragment key={ index }>

            { option.quantity > 0 &&
            
                <div className={ option.style } key={ index }>

                    <span className="tooltip">

                        { t ( option.tooltip ) }

                    </span>

                    <div className={ ( option.accented ) ? "title accented" : "title" }>

                        <span className="icon">

                            { option.icon }

                        </span>

                        <div className="name">

                            { option.name }

                        </div>

                    </div>

                    <div className="stock">

                        { option.quantity > 0 ? `${ option.quantity } ${ t ( "pieces" ) }` : t ( "out_of_stock" ) }

                    </div>

                </div> 

            }
        
        </Fragment> ) }

    </div> );

}

/** @exports Delivery */
 
export default Delivery;