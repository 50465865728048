/** @import modules */

import { useTranslation }       from "react-i18next";

import { Link, useParams }      from "react-router-dom";

/** @import components */

import Breadcrumbs              from "../../Components/Layout/Header/Breadcrumbs";

import MontageLayout            from "../../Components/Montage/Layout";

import AppointmentCreated       from "../../Components/Montage/Success";

/**
 * 
 * @returns montage page
 * 
 */

const AppointmentSuccessful = () => { const { t } = useTranslation (),

    { uuid } = useParams ();

    /** @return component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "montage" ), URI: "/content/montage" },

            { name: t ( "appointment_created" ), URI: "#" }

        ] } />

        <MontageLayout>

            <div className="title">
                
                <h2>

                    { t ( "appointment_created" ) }

                </h2>
                
            </div>

            <AppointmentCreated appointment={ uuid } />

            <div className="successful-appointment-actions">

                <Link to="/" className="button">

                    { t ( "return_to_homepage" ) }

                </Link>

                <Link to={ `/content/montage/appointment/${ uuid }/eliminate` } className="link">

                    { t ( "delete" ) }

                </Link>

            </div>

        </MontageLayout>
        
    </div> );

}

/** @export component */
 
export default AppointmentSuccessful;