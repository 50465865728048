/** Import modules */

import { useNavigate }                  from "react-router-dom";

import { useTranslation }               from "react-i18next";

/** Import hooks */

import { TyresNavigator }               from "../../../../../Hooks/TyresNavigator";

/**
 * 
 * @returns Filters sanitization component
 * 
 */

const ClearFilters = () => { const 

    { t } = useTranslation (),

    navigate = useNavigate (),

    /** Clear all filters */

    Sanitize = () => {

        return navigate ( `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ new URLSearchParams ( TyresNavigator ( "", {

            f       : 12,

            l       : null,

            b       : null,

            a       : null,

            w       : null,

            h       : null,

            d       : null,

            s       : null,

            m       : null,

            sort    : null,

            loc: window.pageYOffset

        } ) ).toString () }` );

    };

    /** Return component */

    return ( <div className="clear-filters">

        <button onClick={ () => Sanitize () }>

            { t ( "clear_filters" ) }

        </button>

    </div> );

}

/** Export component */
 
export default ClearFilters;