/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import datasets */

import HeaderDatasets       from "../../../../Datasets/Header";

/**
 * 
 * @returns Header mobile navigation location component
 */

const MobileNavLocation = () => { const { t } = useTranslation ();

    /** Return component */

    return (<div className="location">

        <div className="location-name">

            { t ( "use_map_navigation" ) }

        </div>

        <div className="location-list">

            <ul>

                { HeaderDatasets ().Locations.map ( ( location, index ) => <li key={ index }>

                    <a href={ location.URI } target="_blank" rel="noreferrer">

                        { location.icon }

                    </a>

                </li> ) }

            </ul>

        </div>

    </div>);

}

/** Export component */
 
export default MobileNavLocation;