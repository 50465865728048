/** @import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

/**
 * 
 * @returns no quick search results found component
 * 
 */

const NoResults = () => { const 
    
    { t }       = useTranslation (),

    decode      = useParams ().keywords,

    keywords    = new URLSearchParams ( decode ).get ( "search" );

    /** @returns component */

    return ( <div className="no-results">

        { t ( "no_search_results_found", { keywords } ) }

    </div> );

}

/** @exports NoResults */
 
export default NoResults;