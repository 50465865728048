/** @import styles */

import "../../../Styles/Calendar.css";

/** @import modules */

import Calendar             from "react-calendar";

import { useContext, useEffect, useState }       from "react";

import cookies              from "js-cookie";

import { Trans }                    from "react-i18next";

/** @import contexts */

import { MontageContext }   from "../../../Contexts/MontageContext";

/** @import models */

import { WorkingDays        } from "../../../Models/Appointments";

/** @import miscs */

import { scrollElement }    from "../../../Misc/ScrollElement";

/**
 * 
 * @returns montage calendar component
 * 
 */

const MontageCalendar = () => { const 

    { data, loading } = WorkingDays (),

    { SelectedDate, TimeState, Type, DateState } = useContext ( MontageContext ),

    [ restrictions, setRestrictions ] = useState ( [ 0, 1, 2, 3, 4, 5, 6 ] );

    useEffect ( () => {

        if ( loading === false ) { const 

            availableDates = [],
            
            dates = [

                { id: 1, state: data.workingOnDays.monday },

                { id: 2, state: data.workingOnDays.tuesday },

                { id: 3, state: data.workingOnDays.wednesday },

                { id: 4, state: data.workingOnDays.thursday },

                { id: 5, state: data.workingOnDays.friday },

                { id: 6, state: data.workingOnDays.saturday },

                { id: 0, state: data.workingOnDays.sunday }

            ];

            dates.forEach ( element => {

                if ( element.state === false ) { availableDates.push ( element.id ); }

            } );

            setRestrictions ( availableDates );

        }

    }, [ loading, data ] );

    /** @return component */

    return ( <div className="calendar">

        { Type.view &&

            <Calendar 
            
                minDate={ new Date () } 
            
                tileDisabled={({date}) => restrictions.includes(date.getDay())} 
            
                showNeighboringMonth={ false } 

                locale={ cookies.get ( 'i18next' ) || "lv" }
            
                onChange={ date => {

                    SelectedDate.set ( new Date ( date ) );

                    TimeState.set ( "" );

                    DateState.set ( true );

                    scrollElement ( ".datetime" );

                } } /> 
            
        }

        <div className="notification">

            <Trans i18nKey="montage_invitation_msg">

                Gadījumā, ja neatrodi sev vēlamo laiku, zvani uz <a href="tel:+37126606262">

                +371 2 660 626 2

                </a>, vai dodies uz mūsu servisu un iestājies dzīvajā rindā.

            </Trans>

        </div>

    </div> );

}

/** @export MontageCalendar */
 
export default MontageCalendar;