/** @import modules */

import { useQuery }         from "@apollo/client";

import { useContext }       from "react";

/** @import contexts */

import { CartContext }      from "../Contexts/CartContext";

/** @import queries */

import { CartSummary }      from "../Queries/Orders";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Cart items collection model
 * 
 */

const OrderSummary = ( location = "Riga", country = "LV", delivery = "pickup" ) => { const

    { cart } = useContext ( CartContext ),

    { loading, data, error, refetch } = useQuery ( CartSummary, { variables: { 
        
        productParams: cart || [], 
        
        city: location, 
        
        countryCode: country, 
        
        deliveryPrice: 0,

        servicePrice: 0,

        vat: true
    
    } } )

    /** Return model */

    return { loading, data, error, refetch }

}

/** Export model */

export default OrderSummary;