/** @import components */

import Navigation           from "../Components/Technical/Aside/Services";

import AsideBanners         from "../Components/Shared/AsideBanners";

/**
 * 
 * @returns Contents datasets
 * 
 */

const 

Widgets = () => {

    return [

        { widget: <Navigation /> },

        { widget: <AsideBanners /> }

    ];

}
 
/** @export datasets */

export { Widgets };