/** @import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import parse                    from 'html-react-parser';

import { Helmet }               from "react-helmet-async";

/** @import models */

import { SingleServiceModel }   from "../../Models/Services";

/** @import components */

import Breadcrumbs              from "../../Components/Layout/Header/Breadcrumbs";

import Layout                   from "../../Components/Services/Layout";

import ComponentLoader          from "../../Components/Layout/Loaders/Loader";

/**
 * 
 * @returns Services page
 * 
 */

const SingleService = () => { const { t } = useTranslation (),

    { serviceId } = useParams (),

    { loading, data } = SingleServiceModel ();

    /** @return component */

    return ( <div className="container max-content-width">

        <Helmet>

            <title>{ `Tireshop.lv | ${ ! loading ? data.services.edges.filter ( obj => obj.node.id === serviceId )[0].node.title : t ( "services" ) }` }</title>

            <link rel="canonical" href={ ! loading ? `/content/services/${ data.services.edges.filter ( obj => obj.node.id === serviceId )[0].node.id }` : "/content/services" } />

        </Helmet>

        <Breadcrumbs collection={ [

            { name: t ( "services" ), URI: `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_services__" ) }` },

            { name: loading ? `${ t ( "loading" ) }...` : data.services.edges.filter ( obj => obj.node.id === serviceId )[0].node.name, URI: `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_services__" ) }` }

        ] } type="basic" />

        <Layout title={ t ( "services" ) }>

            <div className="paragraph">

                { loading ? <ComponentLoader /> : parse ( data.services.edges.filter ( obj => obj.node.id === serviceId )[0].node.content ) }

            </div>

        </Layout>
        
    </div> );

}

/** @export SingleService */
 
export default SingleService;