/** Import modules */
import i18next from "i18next";
import cookies from "js-cookie";
import { useState, useEffect } from "react";

/** Import icons */
import { FaGlobeEurope, FaCaretDown } from "react-icons/fa";

/**
 * Language selector component
 */
const LanguageSelector = () => {
  const Languages = [
    { code: "lv", name: "Latviešu" },
    { code: "en", name: "English" },
    { code: "lt", name: "Lithuanian" },
    { code: "ee", name: "Estonian" },
    { code: "fi", name: "Finnish" },
    { code: "se", name: "Swedish" },
    { code: "no", name: "Norwegian" },
    // { code: "ru", name: "Русский" }
  ];

  const [language, setLanguage] = useState(cookies.get("i18next") || "lv");
  const [dropdown, setDropdown] = useState(false);

  // Sync the language state with i18next changes
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };

    i18next.on("languageChanged", handleLanguageChange);

    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, []);

  /** Return component */
  return (
    <div className="language-selector">
      <div
        className="selector"
        onClick={() => setDropdown(!dropdown)}
      >
        <FaGlobeEurope className="hide-768" />
        <span>{language}</span>
        <FaCaretDown />
      </div>

      {dropdown && (
        <div className="dropdown">
          <ul>
            {Languages.map(({ code, name }) => (
              <li key={code}>
                <button
                  onClick={() => {
                    i18next.changeLanguage(code);
                    cookies.set("i18next", code); // Update the cookie
                    setDropdown(false);
                  }}
                  disabled={code === language}
                >
                  {name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

/** Export component */
export default LanguageSelector;
