/** Import modules */

import { useQuery }             from "@apollo/client";

/** Import hooks */

import { QueryAttributes }      from "../Hooks/TyresNavigator";

/** Import queries */

import { TyresCatalog }         from "../Queries/Tyres";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Tyres product collection model
 * 
 */

const Collection = ( params ) => { 
    
    const 
    
    decode = new URLSearchParams ( params );

    if ( ! decode.get ( "pr" ) ) { decode.append ( "pr", [ 20, 2000 ] ) }

    const { loading, data } = useQuery ( TyresCatalog, { variables: ( ! params ) ? { first: 12 } : QueryAttributes ( decode ) } );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default Collection;