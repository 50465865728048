/** @module                         Hooks */

import { useParams, useNavigate }   from "react-router-dom";

import { useTranslation }           from "react-i18next";

/** @module                         Utilities */

import { TyresNavigator }           from "../../../../../Hooks/TyresNavigator";

/** @module                         React-Icons */

import { FaCheck }                  from "react-icons/fa";

const 

SingleQtyTiresFilter = () => { const 

    { t } = useTranslation (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    params              = new URLSearchParams ( parameters ),

    loadSingleTires = event => { const

        object = { f : 12, l : null, a : null, b : null, loc: window.scrollY };

        event.target.checked ? object.ss = true : object.ss = null;

        return navigate (  
        
            `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
            
                new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

    }

    /** @returns */

    return (

        <div className="single_tire_filter show-768">

            <label htmlFor={ "showSinglesFilter" } className="checkbox">

                <input 
                
                    type="checkbox" 
                
                    id={ "showSinglesFilter" } 
                
                    value={ "showSinglesFilter" } 
                
                    onChange={ e => loadSingleTires ( e ) } 
                    
                    checked={ params.get ( "ss" ) && ( ( params.get ( "ss" ) == "true" ) ? true : false ) } />

                <span className="check">

                    <span className="checked">

                        <FaCheck />

                    </span>

                </span>

                <span className="name">

                    { t ( "single_tires" ) }

                </span>

            </label>

        </div>

    );

}
 
/** @exports SingleQtyTiresFilter */

export default SingleQtyTiresFilter;