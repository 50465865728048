import { gql } from "@apollo/client";

const 

/** Contacts team members */

Team = gql`query { 
  
    contacts {

        id

        image

        title

        firstName

        lastName

        email

        phone

    } 
  
}`,

Contacts = gql`query { contactInfo }`,

SHOP_CONTACTS = gql`query { shopContacts {

    address

    email

    mobilePhoneNr

} }`;

/** Export queries */

export { Team, Contacts, SHOP_CONTACTS }