/** @import modules */

import { useTranslation }           from "react-i18next";

import { Link }                     from "react-router-dom";

import { useNavigate }              from "react-router-dom";

import { useContext }               from "react";

import { onCheckoutClick }          from "../../Misc/DataLayer";

/** @import models */

import Collection                   from "../../Models/CartProducts";

/** @import contexts */

import { CartContext }              from "../../Contexts/CartContext";

/** @import components */

import Breadcrumbs                  from "../../Components/Layout/Header/Breadcrumbs";

import CartLayout                   from "../../Components/Orders/Cart/Layout";

import ComponentLoader              from "../../Components/Layout/Loaders/Loader";

import OrdersItem                   from "../../Components/Orders/Cart/Item";

import CartSummary                  from "../../Components/Orders/Cart/Summary";

import UpdateCart                   from "../../Components/Orders/Cart/Update";

import CartEmpty                    from "../../Components/Orders/Cart/Empty";

import { Fragment }                 from "react";

const Cart = () => { const { t } = useTranslation (),

    navigate = useNavigate (),

    { cart } = useContext ( CartContext ),

    { loading, data } = Collection (),

    Items = () => {

        if ( loading ) return <ComponentLoader />

        return <div className="items">

            { data.product.edges.map ( ( product, index ) => <OrdersItem product={ product.node } key={ index } /> ) }

        </div>

    }

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "shopping_cart" ), URI: "/orders/cart" }

        ] } />

        <CartLayout title={ t ( "shopping_cart" ) }>

            <div className="title">
                
                <h2>{ t ( "shopping_cart" ) }</h2>
                
            </div>

            { ! cart.length && <CartEmpty /> }

            { cart.length > 0 && <>

                <Items />

                <UpdateCart />

                <CartSummary />

                <div className="checkout-actions">

                    <Fragment>

                        { 
                        
                            loading ? <ComponentLoader /> : 
                        
                                <button className="btn btn_cart_checkout" onClick={ () => onCheckoutClick ( navigate, data.product.edges, cart ) }>
                                    
                                    { t ( "navigate_to_checkout" ) }
                                    
                                </button> 
                            
                        }

                    </Fragment>

                </div>

            </> }

        </CartLayout>
        
    </div> );

}

/** Export component */
 
export default Cart;