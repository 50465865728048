/** Import modules */

import { useState }                 from "react";

/** Import icons */

import { BsFillCaretDownFill }      from "react-icons/bs";

/**
 * 
 * @param { name, toggle, children } param0 
 * 
 * @returns Single filter template component
 * 
 */

const SingleFilter = ( { name, toggle, children } ) => { const 

    [ state, setState ] = useState ( "open" );

    /** Return component */

    return ( <div className="filter">

        <div className="title">

            <div className="name">{ name }</div>

            { toggle && <button 
            
                className={ state } 
            
                onClick={ () => ( state === "open" ) ? setState ( "close" ) : setState ( "open" ) }>

                    <BsFillCaretDownFill />

            </button> }

        </div>

        <div className={ `container filter-${ state }` }>

            { children }

        </div>

    </div> );

}

/** Export component */
 
export default SingleFilter;