/** @import modules */

import { useQuery }         from "@apollo/client";

import { useContext }       from "react";

/** @import contexts */

import { CartContext }      from "../Contexts/CartContext";

/** @import queries */

import { CartProducts }     from "../Queries/Orders";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Cart items collection model
 * 
 */

const Collection = () => { const

    { cart } = useContext ( CartContext ),

    { loading, data } = useQuery ( CartProducts, { variables: { id: cart.map ( node => parseInt( node.id ) ) } } )

    /** Return model */

    return { loading, data }

}

/** Export model */

export default Collection;