import { Text, View, Image } from '@react-pdf/renderer';

import NoImage from "../../../../../../Assets/missing_image.jpg";

export default function DocumentProducts ( { order, products } ) {

    return (

        <View>

            <View style={ { flexDirection: "row", flexWrap: "nowrap", borderBottom: "1px solid #999" } }>

                <Text style={ { flex: 0.15, fontWeight: 500 } }>Nr.</Text>

                <Text style={ { flex: 1 } }>Prece</Text>

                <Text style={ { flex: 1, fontWeight: 500 } }>Apraksts</Text>

                <Text style={ { flex: 0.5, fontWeight: 500 } }>Daudzums</Text>

                <Text style={ { flex: 1, fontWeight: 500 } }>Cena</Text>

                <Text style={ { flex: 1, fontWeight: 500 } }>Kopējā cena</Text>

            </View>

            {

                order?.products?.map ( ( product, index ) =>  {

                    const currentProduct = products.find(prod => prod?.node?.id === product?.id?.toString());

                    const productCount = product?.price / currentProduct?.node?.priceList?.retail_price;

                    const productTypes = {

                        tire: `${ currentProduct?.node?.width } x ${ currentProduct?.node?.height } x R${ parseInt ( currentProduct?.node?.diameter ) }${ currentProduct?.node?.isCTire ? "C" : "" } | ${ currentProduct?.node?.loadIndex }${ currentProduct?.node?.speedRating?.toUpperCase () }`,
                
                        rim: `${ currentProduct?.node?.width || "-" }J | R${ parseInt ( currentProduct?.node?.diameter ) || "-" } | ${ currentProduct?.node?.pitchCircleDiameter || "-" } | ET${ currentProduct?.node?.offset || "-" } | CB${ currentProduct?.node?.centreBore || "-" }`,
                
                        battery: `${ currentProduct?.node?.voltage } V | ${ currentProduct?.node?.current } A | ${ currentProduct?.node?.capacity } Ah`,
                
                    };

                    return (

                        <View key={ product.id } style={ { flexDirection: "row", flexWrap: "nowrap", borderBottom: "1px solid #999" } }>

                            <View style={ { flex: 0.15, display: "flex", justifyContent: "center" } }>

                                <Text>{ index + 1 }</Text>

                            </View>

                            <Image style={ { flex: 0.25, display: "flex", justifyContent: "center", padding: "5" } } src={ currentProduct?.node?.currentImage || NoImage } />

                            <View style={ { flex: 1, display: "flex", justifyContent: "center" } }>

                                <View style={ { display: "block" } }>
                                    
                                    <Text>

                                        { [ currentProduct.node.manufacturer.name.toUpperCase (), currentProduct.node.model ].join ( " " ) }

                                    </Text>
                                    
                                </View>

                                <View style={ { display: "block" } }>
                                    
                                    <Text>{ productTypes [ currentProduct.node.productType ] }</Text>
                                    
                                </View>

                                <View style={ { display: "block" } }>
                                    
                                    <Text style={ { color: "#bbb" } }>ID: { product.id }</Text>
                                    
                                </View>

                            </View>

                            <View style={ { flex: 0.5, display: "flex", justifyContent: "center" } }>

                                <Text>{ productCount || "-" }</Text>

                            </View>

                            <View style={ { flex: 1, display: "flex", justifyContent: "center" } }>

                                <View style={ { display: "block" } }>
                                    
                                    <Text>bez PVN: { ( currentProduct.node.priceList.retail_price / ( 1 + 21 / 100 ) ).toFixed ( 2 ) } EUR</Text>
                                    
                                </View>

                                <View style={ { display: "block" } }>
                                    
                                    <Text>ar PVN: { currentProduct.node.priceList.retail_price } EUR</Text>
                                    
                                </View>

                            </View>

                            <View style={ { flex: 1, display: "flex", justifyContent: "center" } }>

                                <View style={ { display: "block" } }>
                                    
                                    <Text>bez PVN: { ( currentProduct.node.priceList.retail_price / ( 1 + 21 / 100 ) ).toFixed ( 2 ) * productCount } EUR</Text>
                                    
                                </View>

                                <View style={ { display: "block" } }>
                                    
                                    <Text>ar PVN: { currentProduct.node.priceList.retail_price * productCount } EUR</Text>
                                    
                                </View>

                            </View>

                        </View>

                    )

                } )


            }

            <View style={ { flexDirection: "row", alignSelf: "flex-end", fontSize: 10 } }>

                <View style={ { width: 50 + "%" } }>


                    <View style={ { flexDirection: "row", borderBottom: "1px solid #999" } }>

                        <Text style={{ flex: 1 }}>Piegāde</Text>

                        <Text style={{ flex: 1 }}>{ order?.delivery?.toFixed ( 2 ) || "0.00" } EUR</Text>

                    </View>

                    <View style={ { flexDirection: "row" } }>

                        <Text style={{ flex: 1 }}>Kopsumma bez PVN</Text>

                        <Text style={{ flex: 1 }}>{ order?.total_execl_vat?.toFixed ( 2 ) || "0.00" } EUR</Text>

                    </View>

                    <View style={ { flexDirection: "row" } }>

                        <Text style={{ flex: 1 }}>PVN</Text>

                        <Text style={{ flex: 1 }}>{ order?.vat?.toFixed ( 2 ) || "0.00" } EUR</Text>

                    </View>

                    <View style={ { flexDirection: "row", borderBottom: "1px solid #999", borderTop: "1px solid #999", fontWeight: 500, fontSize: 11 } }>

                        <Text style={{ flex: 1 }}>Kopsumma apmaksai</Text>

                        <Text style={{ flex: 1 }}>{ order?.total?.toFixed ( 2 ) || "0.00" } EUR</Text>

                    </View>

                </View>

            </View>

        </View>

    );

};