/** @import modules */

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { useContext } from "react";

/** @import models */

import Product from "../../../Models/SingleTyre";

/** @import contexts */

import { CartContext } from "../../../Contexts/CartContext";

/** @import hooks */

import { qtySum } from "../../../Hooks/QtySum";

/** @import components */

import ComponentLoader from "../../Layout/Loaders/Loader";

/** @import datasets */

import { compareProperties } from "../../../Datasets/Compare";

/** @import misc */

import { TyrePermalinks } from "../../../Misc/Permalinks";

import { RimPermalinks } from "../../../Misc/Permalinks";

import { BatteryPermalinks } from "../../../Misc/Permalinks";

/** @import icons */

import { AiFillCloseCircle } from "react-icons/ai";

import { FaCartArrowDown, FaEye } from "react-icons/fa";

/** @import assets */

import NoTyre from "../../../Assets/no-tyres.jpg";

import NoRim from "../../../Assets/no-rims.jpg";

import NoBattery from "../../../Assets/no-batteries.jpg";

/**
 * 
 * @param { id } param0 
 * 
 * @returns compare product item component
 * 
 */

const CompareItem = ( { id = 0, type, actions } ) => { const { t } = useTranslation (),

    { cart, setCart } = useContext ( CartContext ),

    navigate = useNavigate (),

    { loading, data } = Product ( id ),

    /** @returns redirection to product page */

    navigateProduct = () => {

        const product = data.product.edges[ 0 ].node;

        if ( type === "tire" ) return navigate ( TyrePermalinks ( product ) );

        if ( type === "rim" ) return navigate ( RimPermalinks ( product ) );

        if ( type === "battery" ) return navigate ( BatteryPermalinks ( product ) );

    },

    /** @returns add product to cart */

    addCart = () => {

        const product = data.product.edges[ 0 ].node,

        maxQty = qtySum ( product.productVendors );

        if ( maxQty > 0 ) {

            return setCart ( [...cart, { id, qty : 1 } ] );

        }

    },

    Item = () => { if ( loading ) return <ComponentLoader />

        const product = data.product.edges[ 0 ].node, 

        properties = compareProperties ( type, product ),

        noImage = productType => {

            if ( productType === "tire" ) return NoTyre;

            if ( productType === "rim" ) return NoRim;

            if ( productType === "battery" ) return NoBattery;

        }

        return <div className="item">

            <button className="removeCompare" onClick={ () => {

                actions.view.splice ( actions.view.map ( item => item.id ).indexOf ( id ), 1 );

                return actions.set ( [...actions.view ] );

            } }>

                <AiFillCloseCircle />

            </button>

            <div className="thumbnail">

                { ( ! product.currentImage ) ? 
                
                <img src={ noImage ( type ) } alt={ product.model } /> : 
                
                <img src={ product.currentImage } alt={ product.model } onError={ ( { currentTarget } ) => {

                    currentTarget.src = noImage ( type );
        
                } } /> }

            </div>

            { properties.map ( ( prop, index ) => <div className={ `property ${ prop.slug }` } key={ index }>

                <div className="name">{ t ( prop.name ) }</div>

                <div className="value">{ prop.value }</div>

            </div> ) }

            <div className="compare-actions">

                <button className="view-product" onClick={ () => navigateProduct () }>

                    <FaEye />

                </button>

                <button className="add-to-cart" disabled={ ( cart.find ( x => x.id === id ) ) ? true : false } onClick={ () => addCart () }>

                    <FaCartArrowDown />

                </button>

            </div>

        </div>
        
    };

    /** @return component */

    return ( <Item /> );

}

/** @export CompareItem */
 
export default CompareItem;