/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

/** @module                         React-Icons */

import { GiCarWheel }               from "react-icons/gi";

/** @module                         Components */

import { Link }                     from "react-router-dom";

const 

MontageNotification = () => { const { t } = useTranslation ();

    /** @returns */

    return ( 
    
        <div className="montage-notification">

            <div className="title">
                
                <GiCarWheel size={ 20 } /> { t ( "montage_today" ) }
                
            </div>

            <div className="description">

                { t ( "montage_today_desc" ) }

            </div>

        </div> 
    
    );

}

/** @exports MontageNotification */
 
export default MontageNotification;