/** Import modules */

import { useQuery }         from "@apollo/client";

/** Import queries */

import { HomeArticles }     from "../Queries/Blog";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Blog posts collection model
 * 
 */

const Articles = () => { const 

    { loading, data } = useQuery ( HomeArticles, { variables: { first: 4 } } );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default Articles;