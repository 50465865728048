/** @import modules */

import { useTranslation } from "react-i18next";

/** @returns empty shopping cart notice */

const CartEmpty = () => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="shopping-cart-empty">

        { t ( "cart_empty" ) }

    </div> );

}

/** @exports CartEmpty */
 
export default CartEmpty;