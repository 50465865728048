/** Import modules */

import { gql } from "@apollo/client";

const 

/** Fetch front-page articles */

HomeArticles = gql`query Articles ( $first: Int ) { 
        
    blogItems ( first: $first ) {
        
        edges {
        
            node {
                
                id
            
                title
                
                description
                
                image
                
                isPublished
                
                language
                
            }
        
        }
        
    }

}`,

/** Fetch all articles */

Articles = gql`query Articles ( $first: Int, $last: Int, $after: String, $before: String ) { 
        
    blogItems ( first: $first, last: $last, before: $before, after: $after ) {
        
        edges {
        
            node {
                
                id
            
                title
                
                description
                
                image
                
                isPublished
                
                language
                
            }
        
        }
        
        pageInfo {
        
            endCursor
            
            startCursor
            
            hasNextPage
            
            hasPreviousPage
        
        }
        
    }

}`,

/** Fetch individual article by ID */

SingleArticle = gql`query SingleArticle ( $id: ID! ) { 
    
    blogItem ( id: $id ) {

        content

        createdAt

        description

        id

        image

        isPublished

        language

        title

        updatedAt

    } 

}`;

export { Articles, SingleArticle, HomeArticles }