import { gql } from '@apollo/client';

const 

SEND_MESSAGE = gql`mutation SendMessage ( 

        $name: String!

        $email: String!

        $phoneNr: String

        $message: String!

    ) {

    contactForm ( input: { 
        
        name: $name,

        email: $email,

        phoneNr: $phoneNr,

        message: $message
    
    } ) {
        
        clientMutationId

    }

}`;

/** @export mutations */

export { SEND_MESSAGE }