/** Import modules */

import { useQuery }             from "@apollo/client";

/** Import hooks */

import { QueryAttributes }      from "../Hooks/BatteriesNavigator";

/** Import queries */

import { BatteriesCatalog }     from "../Queries/Batteries";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Tyres product collection model
 * 
 */

const Collection = ( params ) => { const 
    
    decode = new URLSearchParams ( params ),

    { loading, data } = useQuery ( BatteriesCatalog, { variables: ( ! params ) ? { first: 12 } : QueryAttributes ( decode ) } );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default Collection;