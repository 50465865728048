/** @import modules */

import { gql } from "@apollo/client";

const 

/** Batteries collection */

BatteriesCatalog = gql`query productQuery ( 
    
    $first                  : Int, 
    
    $last                   : Int, 
    
    $after                  : String, 
    
    $before                 : String, 
    
    $sortOrder              : String,

    $manufacturer           : [String!]
    
    $voltage                : [String!],
    
    $current                : [String!],

    $upperPriceRange        : Int,

    $lowerPriceRange        : Int,
    
    $capacity               : [String!],
    
    $availability           : [AvailabilityTypeEnum!]
    
    ) {
    
        multiFieldProductSearch ( 
        
            first                   : $first, 
            
            last                    : $last, 
            
            productType             : "battery", 
        
            after                   : $after, 
        
            before                  : $before, 
            
            sortOrder               : $sortOrder,

            manufacturer            : $manufacturer,

            voltage                 : $voltage,

            current                 : $current,

            capacity                : $capacity,

            upperPriceRange         : $upperPriceRange,

            lowerPriceRange         : $lowerPriceRange,

            availability            : $availability
            
        ) { 
            
        edges { 
            
            node {

                awsImageUrl

                currentImage
        
                boltsIncluded
                
                createdAt
                
                description
                
                hashId
                
                id
                
                image
                
                productVendors {

                    price
          
                    qty 
                    
                    qtyDays
                    
                    qtyHour
                    
                    qtyWeeks

                }
                
                manufacturer {
                
                name
                
                }
                
                manufacturerId
                
                model
                
                priceList
                
                productType
                
                uid
                
                updatedAt
                
                visible

                capacity

                voltage

                current

            } 
    
        }
            
        pageInfo { 
            
            hasNextPage 
            
            hasPreviousPage 
            
            startCursor 
            
            endCursor 
        
        }

    }

}`,

/** Single product */

SingleProduct = gql`query SingleProductQuery ( $id: [ID!]! ) { product ( id: $id ) { 

    edges { node {

        awsImageUrl
        
        currentImage
        
        boltsIncluded
        
        createdAt
        
        description
        
        hashId
        
        id
        
        image
        
        productVendors {

            price
          
            qty 
            
            qtyDays
            
            qtyHour
            
            qtyWeeks

        }
        
        manufacturer {
          
          name
          
        }
        
        manufacturerId
        
        model
        
        priceList
        
        productType
        
        uid
        
        updatedAt
        
        visible

        capacity

        voltage

        current

    } }
          
} }`,

/** Tyre filter attributes */

FilterAttributes = gql`query { searchAttributes }`;

/** Export queries */

export { 
    
    BatteriesCatalog, 
    
    FilterAttributes, 
    
    SingleProduct 

};