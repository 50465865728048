const TextareaController = ( { id, label, state } ) => {

    /** @returns component */

    return ( <div className="fields">

        <div className="form-fields">

            <label htmlFor={ id }>
                
                { label }
                
            </label>

            <textarea 
            
                name={ id } 
            
                id={ id } 
            
                onChange={ e => state.set ( e.target.value ) } 
            
                defaultValue={ state.view }></textarea>

        </div>

    </div>  );

}

/** @exports TextareaController */
 
export default TextareaController;