/**
 * 
 * @param { object } object 
 * 
 * @returns Object with filtered null key values
 * 
 */

const Cleaner = object => {

    for ( const key in object ) { 

        /** Remove NULL */

       if ( object[ key ] === null ) { delete object[ key ]; }

       /** Remove NaN integers */

       if ( isNaN (  object[ key ] ) && typeof object[key] === "number" ) { delete object[ key ];  }

       /** Remove empty arrays */

       if ( Array.isArray ( object[ key ] ) && object[ key ].length === 0 ) { delete object[ key ];  }
    
    }

    /** Return cleaned object */

    return object;

}

/** Export object cleaner */

export { Cleaner }