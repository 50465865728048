/** Import modules */

import { useTranslation } from "react-i18next";

/**
 * 
 * @returns Component loader
 * 
 */

const ComponentLoader = ( { minified } ) => { const { t } = useTranslation ();

    return (<div className="component-loader">
            
        <div className="component-loader-container component-loader-wave"></div>

        { ! minified && <span>{ t ( "loading" ) }...</span> }

    </div>);

}

/** Export component */
 
export default ComponentLoader;