const 

ErrorsCheck = data => { const 

    local_errors = [];

    if ( ! data.vehicle[ 0 ].value.view.length  ) { local_errors.push ( { err : "missing_numberplate"   } ); }

    if ( ! data.vehicle[ 1 ].value.view.length  ) { local_errors.push ( { err : "missing_brand"         } ); }

    if ( ! data.vehicle[ 2 ].value.view.length  ) { local_errors.push ( { err : "missing_model"         } ); }

    if ( ! data.customer[ 0 ].value.view.length ) { local_errors.push ( { err : "missing_name"          } ); }

    if ( ! data.customer[ 1 ].value.view.length ) { local_errors.push ( { err : "missing_phone"         } ); }

    if ( ! data.customer[ 2 ].value.view.length ) { local_errors.push ( { err : "missing_email"         } ); }

    if ( data.type.view !== "cooler" ) {

        if ( data.wantTires.view === false && data.tiresOwn.view === false && data.tiresOther.view === false && data.stored.view === false && data.bought.view === false ) {

            local_errors.push ( { err : "missing_tires_options" } );

        }

        if ( data.wantTires.view === true && ! data.wantNotes.view.length ) {

            local_errors.push ( { err : "missing_tires_notes" } );

        }

        if ( data.tiresOwn.view === true && ! data.tiresOwnNotes.view.length ) {

            local_errors.push ( { err : "missing_tires_own_notes" } );

        }

        if ( data.tiresOther.view === true && ! data.tiresOtherNotes.view.length ) {

            local_errors.push ( { err : "missing_tires_other_notes" } );

        }

        if ( data.stored.view === true && ! data.storedNotes.view.length ) {

            local_errors.push ( { err : "missing_tires_stored_notes" } );

        }

        if ( data.bought.view === true && ! data.boughtNotes.view.length ) {

            local_errors.push ( { err : "missing_tires_bought_notes" } );

        }

    }

    if ( data.type.view !== "cooler" ) {

        if ( data.tiresReturned.view === false && data.tiresStoredAfter.view === false && data.tiresDisposed.view === false && data.afterMontageOther.view === false ) {

            local_errors.push ( { err : "missing_tires_after_options" } );

        }

        if ( data.afterMontageOther.view === true && ! data.afterMontageOtherNotes.view.length ) {

            local_errors.push ( { err : "missing_tires_after_montage_notes" } );

        }

    }

    /** Update error state */

    if ( local_errors.length ) {

        data.state.set ( local_errors );

    }

    /** @returns errors */

    return local_errors;

};

/** @exports */

export {

    ErrorsCheck

}