import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports function Links
 * 
 * @description Export links for the footer of the document as a React Component
 * 
 * @returns { JSX.Element }
 * 
 */

export default function Links () {

    /**
     * 
     * @description 
     * 
     * Render links for the footer of the document
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View style={ { alignItems: "center" } }>

            <Text style={ { fontSize: 12, fontWeight: 500 } }>www.tireshop.lv</Text>

            <Text>

                B2B e-komercijas platforma:

            </Text>

            <Text style={ { textDecoration: "underline" } }>https://b2b.tireshop.lv</Text>

        </View>

    );

};