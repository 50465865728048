/** Import assets */

import "../../Styles/Header.css";

/** Import components */

import HeaderActions        from "./Header/Actions";

import HeaderLogotype       from "./Header/Logotype";

import HeaderNavigation     from "./Header/Navigation";

import SwiperNavigation     from "./Header/SwiperNavigation";

const Header = () => {

    return (<header>

        <div className="container">

            <div className="max-content-width">

                <div className="store-actions">

                    <HeaderLogotype />

                    <HeaderActions />

                </div>

                <HeaderNavigation />

            </div>

                <SwiperNavigation />

        </div>

    </header>);

}

/** Export component */
 
export default Header;