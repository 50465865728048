/** Import modules */

import { useQuery } from "@apollo/client";

/** Import queries */

import { SingleArticle } from "../Queries/Blog";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Single blog post model
 * 
 */

const Post = ( articleId ) => { const 

    { loading, data } = useQuery ( SingleArticle, { variables: { id: parseInt ( articleId ) } } );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default Post;