/** Import hooks */

import { FiltersCollection }        from "../../../../Datasets/Rims";

/** Import models */

import RimsAttributes               from "../../../../Models/RimsFilters";

/** Import components */

import SingleFilter                 from "./Filters/Filter";

import ComponentLoader              from "../../../Layout/Loaders/Loader";

import ClearFilters                 from "./Filters/ClearFilters";

/** @import icons */

import { GrClose }                  from "react-icons/gr";

import { FaAngleLeft }              from "react-icons/fa";

/**
 * 
 * @returns Filters list component
 * 
 */

const Filters = ( { attributes } ) => { const 

    { loading, data } = RimsAttributes (),

    LoadFilters = () => {

        if ( loading ) return <ComponentLoader minified={ true } />

        return FiltersCollection ( data ).map ( ( filter, index ) => <SingleFilter 
        
            key={ index } 
        
            name={ filter.title } 
        
            toggle={ filter.toggle }>

                { filter.content }

        </SingleFilter> )

    }

    /** Return component */

    return ( <div className="widget" ref={ attributes.element }>

        <div className="close-mobile-filters">

            <button onClick={ () => {

                ( attributes.view ) ? attributes.set ( false ) : attributes.set ( true );

                return attributes.element.current.classList.remove ( "mobile-filters-state" );

            } }>

                <GrClose />

            </button>

        </div>

        <button className="sticky-close" onClick={ () => {

            ( attributes.view ) ? attributes.set ( false ) : attributes.set ( true );

            return attributes.element.current.classList.remove ( "mobile-filters-state" );

        } }>

            <FaAngleLeft />

        </button>

        <ClearFilters />

        <LoadFilters />

        <ClearFilters />

    </div> );

}

/** Export component */
 
export default Filters;