/** Import modules */

import { useQuery }             from "@apollo/client";

/** Import queries */

import { TECH_INFO }            from "../Queries/Technical";

/**
 * 
 * @returns Tyres product collection model
 * 
 */

const TechInfoModel = () => { const

    { loading, data } = useQuery ( TECH_INFO );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default TechInfoModel;