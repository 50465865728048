/** Import modules */

import { Link }                 from "react-router-dom";

/** @import components */

import Properties               from "../Properties";

/** Import icons */

import { AiFillEye }            from "react-icons/ai";

/** Import misc */

import { BatteryPermalinks }    from "../../../../../Misc/Permalinks";

import { productClick }         from "../../../../../Misc/DataLayer";

/** @import assets */

import NoProduct from "../../../../../Assets/no-batteries.jpg";

import Availability from "../../../Tyres/Catalog/Item/Availability";

import AddCart from "./AddCart";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Catalog list item component
 * 
 */

const ListItem = ( { product, notice } ) => { const permalink = BatteryPermalinks ( product );

    /** Return component */

    return ( <div className="item">

        <Link to={ permalink } onClick={ () => { productClick ( product ) } }>

            <div className="thumbnail batteries">

                { ( ! product.currentImage ) ? 
                
                <img src={ NoProduct } alt={ product.model } /> : 
                
                <img src={ product.currentImage } alt={ product.model } onError={ ( { currentTarget } ) => {

                    currentTarget.src = NoProduct;
        
                } } /> }

            </div>

        </Link>

        <div className="name">
            
            <div className="model">

                <Link to={ permalink } onClick={ () => { productClick ( product ) } }>

                    <span>
                            
                        { product.manufacturer.name }
    
                    </span>

                    { product.model }

                </Link>

            </div>
            
        </div>

        <Properties product={ product } />

        <div className="price">

            <Availability counter={ product.productVendors[0] } product={ product } />

            <AddCart product={ product } link={ permalink } notice={ notice } />

            <div className="product-price">
            
                &euro; { parseFloat ( product.priceList.retail_price ).toFixed ( 2 ) }

            </div>
            
        </div>

    </div> );

}

/** Export component */
 
export default ListItem;