/** @import assets */

import salidzinilv          from "../../../Assets/salidzinilv.png";

/** @import modules */

import { useTranslation }   from "react-i18next";

const Salidzini = () => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="footer-salidzini">

    <a href="https://www.salidzini.lv/" target="_blank">
    
        <img border="0" alt="Salidzini.lv - preču cenas interneta veikalos logotips" title="Interneta veikali. Labākā cena" src={ salidzinilv } />

    </a>


    </div> );

}

/** @exports Salidzini */

export default Salidzini;