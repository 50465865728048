/**
 * 
 * @param {title} param0 
 * 
 * @returns Contacts section component
 * 
 */

const ContactsSection = ( { title, children } ) => {

    /** Return component */

    return (<section className="section">

        <h2>{ title }</h2>

        <div className="content">

            { children }

        </div>

    </section>);

}

/** Export component */
 
export default ContactsSection;