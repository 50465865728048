/** Import styles */

import "../../Styles/Orders.css";

/** Import modules */

import { useTranslation }       from "react-i18next";

/** Import components */

import Breadcrumbs              from "../../Components/Layout/Header/Breadcrumbs";

import Layout                   from "../../Components/Orders/Compare/Layout";

import Table                    from "../../Components/Orders/Compare/Table";

/**
 * 
 * @returns Compare page
 * 
 */

const Compare = () => { const { t } = useTranslation ();

    /** @returns component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "comparison" ), URI: "/orders/compare" }

        ] } />

        <Layout title={ t ( "comparison" ) }>

            <Table title={ t ( "tyres" ) } type="tire" />

            <Table title={ t ( "rims" ) } type="rim" />

            <Table title={ t ( "batteries" ) } type="battery" />

        </Layout>

    </div> );

}

/** @export component */
 
export default Compare;