/** Import modules */

import { useState }                 from "react";

/** Import icons */

import { BsFillCaretDownFill }      from "react-icons/bs";

/**
 * 
 * @param { name, toggle, children } param0 
 * 
 * @returns Single filter template component
 * 
 */

const SingleFilter = ( { name, toggle, children, position, header = true } ) => { const 

    [ state, setState ] = useState ( position );

    /** Return component */

    return ( <div className="filter">

        { header && 

            <div className="title" onClick={ () => ( state === "open" ) ? setState ( "close" ) : setState ( "open" ) }>

                <div className="name">{ name }</div>

                { toggle && <button 
                
                    className={ state }>

                        <BsFillCaretDownFill />

                </button> }

            </div>

        }

        <div className={ `container filter-${ state }` }>

            { children }

        </div>

    </div> );

}

/** Export component */
 
export default SingleFilter;