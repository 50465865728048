/** @import modules */

import { useTranslation } from "react-i18next";

/**
 * 
 * @param { data } 
 * 
 * @returns single product action notification message
 * 
 */

const ActionNotification = ( { data } ) => { const { t } = useTranslation ();

    /** @returns component */

    return ( <div className={ `product-action-notification ${ data[0].type }` }>

        { t ( data[0].msg ) }

    </div> );

}

/** @exports ActionNotification */
 
export default ActionNotification;