const MontageFieldGroup = ( { fields, title } ) => {

    /** @returns component */

    return ( <>
    
        <div className="title">

            { title }

        </div>

        { fields.map ( ( field, index ) => <div className="fields" key={ index }>

            <div className="form-fields">

                <label htmlFor={ field.name }>
                    
                    { field.label }
                    
                </label>

                <input 
                
                    type={ field.type } 
                
                    name={ field.name } 
                
                    id={ field.name } 
                
                    defaultValue={ field.value.view } 
                
                    onChange={ e => field.value.set ( e.target.value ) } />

            </div>

        </div> ) }
    
    </> );

}

/** @exports MontageFieldGroup */
 
export default MontageFieldGroup;