/** @import modules */

import { useTranslation }   from "react-i18next";

/** @import models */

import ShopContacts         from "../Models/shopContacts";

import ContactsModel        from "../Models/Contacts";

/** @import components */

import Copyrights           from "../Components/Layout/Footer/Copyrights";

import FooterInfo           from "../Components/Layout/Footer/Information";

import Gateway              from "../Components/Layout/Footer/Gateway";

import FooterNavigation     from "../Components/Layout/Footer/Navigation";

import Socials              from "../Components/Layout/Footer/Social";

import Salidzini            from "../Components/Layout/Footer/Salidzini";

// import Subscription         from "../Components/Layout/Footer/Subscription";

/** Register datasets */

const FooterDatasets = () => { const { t } = useTranslation (),

    { data, loading } = ShopContacts (),

    { data : contactsData, loading : loadingContacts } = ContactsModel ();

    /** Contacts */

    const Contacts = [

        { 
            
            name        : t ( "weekdays" ), 
        
            content     : ( loadingContacts ) ? `${ "loading" }...` : contactsData.contactInfo.working_hours.monday, 
        
            display     : "default",
        
            link        : false,
        
            URI         : null },

        { 
        
            name        : t ( "saturday" ) + ":", 
        
            content     : ( loadingContacts ) ? `${ "loading" }...` : contactsData.contactInfo.working_hours.saturday, 

            // content     : "Atpūšamies", 
        
            display     : "default",
        
            link        : false,
        
            URI         : null },

        { 
    
            name        : t ( "sunday" ) + ":", 
        
            content     : ( loadingContacts ) ? `${ "loading" }...` : contactsData.contactInfo.working_hours.sunday, 
        
            display     : "default",
        
            link        : false,
        
            URI         : null },

        { 
            
            name        : t ( "call" ), 
        
            content     : ( loading ) ? `${ "loading" }...` : data.shopContacts.mobilePhoneNr, 
        
            display     : "default",
        
            link        : true,
            
            external    : true,

            action      : true,
        
            URI         : ( loading ) ? "#" : `tel:${ data.shopContacts.mobilePhoneNr }` },

        { 
        
            name        : t ( "service_number" ), 
        
            content     : ( loading ) ? `${ "loading" }...` : data.shopContacts.serviceNumber, 
        
            display     : "default",
        
            link        : true,
            
            external    : true,

            action      : true,
        
            URI         : ( loading ) ? "#" : `tel:${ data.shopContacts.serviceNumber }` },

        { 
            
            name        : t ( "write" ), 
        
            content     : ( loading ) ? `${ "loading" }...` : data.shopContacts.email, 
        
            display     : "accented",
        
            link        : true,
            
            external    : true,

            action      : true,
        
            URI         : ( loading ) ? "#" : `mailto:${ data.shopContacts.email }` }

    ],

    /** Location */

    Location = [

        { 
            
            name        : t ( "address" ), 
        
            content     : ( loading ) ? `${ "loading" }...` : data.shopContacts.address, 
        
            link        : false, 
        
            URI         : null,

            external    : false,

            action      : false,
        
            display     : "default" },

        { 
            
            name        : "Waze:", 
        
            content     : t ( "use_waze" ), 
        
            link        : true, 

            external    : true,

            action      : false,
        
            URI         : "https://ul.waze.com/ul?place=ChIJU7oCGgDS7kYRWG5njTFSuJI&ll=56.92684850%2C24.18595930&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location",
        
            display     : "default" }

    ],

    /** Policies routes */

    Policies = [

        { route: "/policies/privacy-policy",        name: t ( "privacy_policy" )            },

        { route: "/policies/cookie-policy",         name: t ( "cookies" )                   },

        { route: "/policies/terms-of-use",          name: t ( "terms_and_conditions" )      }

    ],

    Contents = [

        { route: "/content/about",                  name: t ( "about_us" )                  },

        { route: "/content/delivery",               name: t ( "delivery_information" )      },

        { route: "/content/payments",               name: t ( "payments" )                  },

        // { route: "/content/collaboration",          name: t ( "collaboration" )             },

        { route: `/content/technical-information`,               name: t ( "technical_info" )                  },

        { route: "/blog",                           name: t ( "blog" )                      },

        // { route: "/content/gallery",                name: t ( "gallery" )                   }

    ],

    Shop = [

        { route: `/shop/products/tyres`,            name: t ( "tyres" )                     },

        { route: `/shop/products/rims`,             name: t ( "rims" )                      },

        { route: `/shop/products/batteries`,        name: t ( "batteries" )                 },

        { route: `/content/services`,               name: t ( "services" )                  },

        { route: "/content/montage",                name: t ( "montage" )                   },

        // { route: "/shop/products/sale",             name: t ( "sale" )                      }

    ];

    /** Footer panes */

    return [

        { render: [

            // {   content: <Subscription />, 
            
            //     id: "subscribe" },

            {   content: <Gateway />, 
            
                id: "gateway" },

            {   content: <Salidzini />, 
            
                id: "salidzinilv" },

            {   content: <Copyrights />, 
            
                id: "copyrights" }

        ] },

        { render: [

            {   content: <FooterInfo 
                
                    data={ Contacts } />, 
                
                id: "contacts" },

            {   content: <FooterInfo 
                
                    data={ Location } />, 
                
                id: "location" },

            {   content: <Socials />, 
                
                id: "socials" }
                
        ] },

        { render: [ 
            
            {   content: <FooterNavigation 
            
                    routes={ Shop } />, 
                
                id: "shop" } ] },

        { render: [ 
            
            {   content: <FooterNavigation 
            
                    routes={ Contents } />, 
                
                id: "contents" } ] },

        { render: [ 
            
            {   content: <FooterNavigation 
            
                    routes={ Policies } />, 
                
                id: "policies" } ] },

    ];



}

/** Export datasets */

export default FooterDatasets;