/** Import modules */

import { useQuery }             from "@apollo/client";

/** Import queries */

import { Contacts }             from "../Queries/Contacts";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Tyres product collection model
 * 
 */

const ContactsModel = () => { const

    { loading, data } = useQuery ( Contacts );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default ContactsModel;