/** @import assets */

import NoTires from "../../../../Assets/no-tyres.jpg";

import NoRims from "../../../../Assets/no-rims.jpg";

import NoBatteries from "../../../../Assets/no-batteries.jpg";

/**
 * 
 * @param { data } param0 
 * 
 * @returns Single product image component
 * 
 */

const Thumbnail = ( { data } ) => { const 

    { image, model, type } = data,

    noImage = () => {

        if ( type === "tire" ) return NoTires;

        if ( type === "rim" ) return NoRims;

        if ( type === "battery" ) return NoBatteries;

    };

    /** Return component */

    return ( <div className="image">

        { ( ! image ) ? 
                
                    <img src={ noImage } alt={ model } /> : 
                    
                    <img src={ image } alt={ model } onError={ ( { currentTarget } ) => {

                        if ( type === "tire" ) return currentTarget.src = NoTires;

                        if ( type === "rim" ) return currentTarget.src = NoRims;

                        if ( type === "battery" ) return currentTarget.src = NoBatteries;
            
                    } } /> }

    </div> );

}

/** Export component */
 
export default Thumbnail; 