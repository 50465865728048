/** Import modules */

import { useEffect, useState } from "react"

/**
 * 
 * @returns Product display layout value
 * 
 */

const Display = () => { const 

    /** Register value */

    [ layout, setLayout ] = useState ( localStorage.getItem ( "product_layout" ) || "grid" );

    /** Switch value */

    useEffect ( () => {

        ( localStorage.getItem ( "product_layout" ) !== layout ) &&

            localStorage.setItem ( "product_layout", layout )

    }, [ layout ] )

    /** Return hook */

    return { layout, setLayout }

}

/** Export hook */

export default Display;