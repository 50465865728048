/** @import modules */

import { useState, useEffect }  from "react";

import { useTranslation }       from "react-i18next";

/** @import models */

import OrderSummary             from "../../../Models/OrderSummary";

/**
 * 
 * @returns shopping cart summary
 * 
 */

const totalsCalc = values => { let calculations = 0;

    values.forEach ( element => {

        calculations = calculations + parseFloat ( element.price );

    } );

    return  parseFloat ( calculations.toFixed ( 2 ) );

}

const CartSummary = () => { const { t } = useTranslation (),

    { loading,  data, error  } = OrderSummary ( "Riga", "LV", "tireshop_delivery" );

    if ( error ) {

        localStorage.removeItem ( "cart" );

        return window.location.reload ();

    }

    /** @return component */

    return ( <div className="cart_totals">
            
        <ul>

            <li>{ t ( "cart_subtotals" ) }: { loading ? t ( "calculating" ) : 
            
             ( data.orderPriceCalculator.total - data.orderPriceCalculator.vat ).toFixed ( 2 ) } &euro;</li>

            <li>{ t ( "cart_vat" ) }: { loading ? t ( "calculating" ) : data.orderPriceCalculator.vat.toFixed ( 2 ) } &euro;</li>

            <li className="highlighted">{ t ( "cart_totals" ) }: { loading ? t ( "calculating" ) : data.orderPriceCalculator.total.toFixed ( 2 ) } &euro;</li>

        </ul>
        
    </div> );

}

/** @export component */
 
export default CartSummary;