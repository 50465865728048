/** @import modules */

import { useTranslation }           from "react-i18next";

import { useState }                 from "react";

/** @import hooks */

import { qtySum }                   from "../../../../Hooks/QtySum";

/** @import components */

import Modal                        from "./Application/Modal";

/** @import icons */

import { 
    
    AiOutlinePlus, 
    
    AiOutlineMinus }                from "react-icons/ai";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Manager review application
 * 
 */

const Application = ( { product, notifications } ) => { const { t } = useTranslation (),

    maxQty = qtySum ( product.productVendors ),

    [ modal, setModal ] = useState ( false ),

    [ quantity, setQuantity ] = useState ( ( maxQty === 0 ) ? 0 : 1 ),

    /** @returns Product added to cart */

    addToCart = ( id, qty ) => {

        return setModal ( true );

    },

    /** @returns Limit max and min values of quantity input */

    QtyController = event => {

        if ( parseInt ( event.target.value ) > maxQty ) return null;

        if ( parseInt ( event.target.value ) < 1 ) return null;

        setQuantity ( event.target.value );

    },

    /** 
     * 
     * @param { action } param
     * 
     * @returns Product quantity for adding to cart */

    productQuantity = action => {

        switch ( action ) {

            case "add" : 

                if ( quantity < maxQty ) return setQuantity ( quantity + 1 );
            
                break;

            case "remove" : 

                if ( quantity > 1 ) return setQuantity ( quantity - 1 );
            
                break;

            default : break;

        }

    };

    /** @returns component */

    return ( <div className="add-to-cart">

        { modal && <Modal controls={ { view: modal, set: setModal } } id={ product.id } qty={ quantity } notification={ notifications } /> }

        <div className="label">

            { t ( "qty" ) }

        </div>

        <div className="quantity-content">

            <div className="down">

                <button onClick={ () => productQuantity ( "remove" ) }>

                    <AiOutlineMinus />

                </button>

            </div>

            <div className="quantity">

                <input type="number" value={ quantity } onChange={ e => QtyController ( e ) } disabled={ ! maxQty } />

            </div>

            <div className="up">

                <button onClick={ () => productQuantity ( "add" ) }>

                    <AiOutlinePlus />
                    
                </button>

            </div>

        </div>

        <div className="add">

            <button onClick={ () => addToCart ( product.id, quantity ) } disabled={ ! maxQty }>
                
                { t ( "request_offer" ) }
                
            </button>

        </div>

    </div> );

}

/** Export component */
 
export default Application;