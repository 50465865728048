/**
 * 
 * @param { product } param0 
 * 
 * @returns Single product mobile title component
 * 
 */

import { Fragment } from "react";

const Title = ( { product } ) => {

    /** @return component */

    return ( <div className="mobile-title">

        <div className="manufacturer">

            { product.manufacturer.name.toUpperCase () }

        </div>

        <div className="model">

            { product.model }

        </div>

        { product.productType !== "battery" &&

            <div className="size">

                { product.productType === "rim" && <Fragment>

                    { `${ product.width }J | R${ parseInt ( product.diameter ) } | ${ product.pitchCircleDiameter } | ET${ product.offset }` }

                </Fragment> }

                { product.productType === "tire" && <Fragment>

                    { `${ product.width } | ${ product.height } | R${ parseInt ( product.diameter ) }${ product?.isCTire ? "C" : "" }` }

                </Fragment> }

            </div>

        }

        <div className="product_id">

            ID: { product.id }

        </div>

    </div> );

}

/** @export component */
 
export default Title;