import { useState } from "react";

/**
 * 
 * @exports useRandomOrderId
 * 
 * @description Generate random order ID hook for checkout process
 * 
 * @returns { String } order ID
 * 
 * @example const orderId = useRandomOrderId ();
 * 
 */

export default function useRandomOrderId () {

    const 
    
    [ timestamp ] = useState ( Date.now () ),

    /**
     * 
     * @description Format timestamp to order ID format (TS-YYYY-MM-DD-HH-MM)
     * 
     * @param { Number } timestamp
     * 
     * @returns { String } formatted timestamp
     * 
     * @example formatTimestamp ( 1633200000000 )
     * 
     * @returns TS-XXXX-XXX-XXX-XXX
     * 
     */

    formatTimestamp = timestamp => {

        const 
        
        value = timestamp.toString ();

        return `TS-${ value.slice ( 0, 4 ) }-${ value.slice ( 4, 7 ) }-${ value.slice ( 7, 10 ) }-${ value.slice ( 10, 13 ) }`;

    };

    /**
     * 
     * @description
     * 
     * Generate random order ID
     * 
     * @returns { String } order ID
     * 
     */

    return formatTimestamp ( timestamp );

};