import { gql } from '@apollo/client';

const 

PRODUCT_OFFER = gql`mutation CreateProductOffer ( 

        $productId: Int!

        $count: Int

        $fullName: String!

        $email: String!

        $phone: String!

        $comment: String

    ) {

    requestProduct ( input: { 
        
        productId: $productId,

        count: $count,

        fullName: $fullName,

        email: $email,

        phone: $phone,

        comment: $comment
    
    } )

}`;

/** @export mutations */

export { PRODUCT_OFFER }