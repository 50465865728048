/** @import modules */

import { useTranslation } from "react-i18next";

/**
 * 
 * @param { errors } 
 * 
 * @returns montage form errors component
 * 
 */

const ErrorsController = ( { errors } ) => { const { t } = useTranslation ();

    /** @returns component */

    return ( <>
    
        { errors.length > 0 && <div id="montage-errors" className="montage-errors">

            { errors.map ( ( err, index ) => <div key={ index }>

                { t ( err.err ) }

            </div> ) }

        </div> }
    
    </> );

}

/** @exports ErrorsController */

export default ErrorsController;