/** Import icons */

import { FaArrowUp }    from 'react-icons/fa';

/**
 * 
 * @returns Footer back to top action component
 * 
 */

const ReturnBackTOP = () => { const 

    returnTOP = () => window.scroll ( { top: 0, left: 0, behavior: 'smooth' } );

    /** Return component */

    return (<button className="return-top" onClick={ returnTOP }>

        <FaArrowUp />

    </button>);

}

/** Export component */
 
export default ReturnBackTOP;