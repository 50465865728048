/** @import modules */

import { useContext }           from "react";

import { useTranslation }       from "react-i18next";

import { ADD_APPOINTMENT }      from "../../../Mutations/Appointment";

import { useMutation }          from '@apollo/client';

/** @import contexts */

import { MontageContext }       from "../../../Contexts/MontageContext";

/** @import misc */

import { TimeConverter }        from "../../../Misc/Montage/TimeConverter";

import { scrollElement }        from "../../../Misc/ScrollElement";

/** @import components */

import ComponentLoader          from "../../Layout/Loaders/Loader";

/** @import models */

import { AppointmentsModel }    from "../../../Models/Appointments";

/**
 * 
 * @returns montage appointment time selector component
 * 
 */

const Datetime = () => { const { t } = useTranslation (),

    { 
        
        SHD, 
        
        SMD, 
        
        EHD, 
        
        EMD, 
        
        TimeState, 
        
        Type, 
        
        SelectedDate,

        DateState
    
    } = useContext ( MontageContext ),

    { loading, data } = AppointmentsModel ( {

        year    : SelectedDate.view.getFullYear () || new Date ().getFullYear (),

        day     : SelectedDate.view.getDate () || new Date ().getDate (),

        month   : SelectedDate.view.getMonth () + 1 || new Date ().getMonth () + 1,

        type    : Type.view

    } ),

    /** @update hook action */

    Time = ( time, id ) => {

        TimeState.set ( id );

        SHD.set ( time.startHour     );

        SMD.set ( time.startMinute   );

        EHD.set ( time.endHour       );

        EMD.set ( time.endMinute     );

    },

    availabilityCheck = ( b1, b2, b3 ) => {

        let available = false;

        if ( ! b1 ) return true;

        if ( ! b2 ) return true;

        if ( ! b3 ) return true;

        return available;

    },

    [ CreateReservation ] = useMutation ( ADD_APPOINTMENT, {

        onCompleted ( data ) {

            console.log ( data );

        },

        onError ( error ) { console.log ( error ) }

    } ),

    submitReservation = ( current, n ) => { const 

        appointmentStart = new Date ( 
            
            SelectedDate.view.getFullYear (), 
            
            SelectedDate.view.getMonth (), 
            
            SelectedDate.view.getDate (), 
            
            current.startHour, 
            
            current.startMinute 
            
        ),
    
        appointmentEnd = new Date ( 
            
            SelectedDate.view.getFullYear (), 
            
            SelectedDate.view.getMonth (), 
            
            SelectedDate.view.getDate (), 
            
            current.endHour, 
            
            current.endMinute
            
        );

        Time (current, n + 1 ); 

        CreateReservation ( { variables:{

            autoNumber: "TS_RESERVATION",

            autoBrand: "TS_RESERVATION",

            autoModel: "TS_RESERVATION",

            fullName: "TS_RESERVATION",

            phoneNumber: "26606262",

            diskDiameter: undefined,

            email: "info@tireshop.lv",

            notes: undefined,

            transportType: Type.view,

            tiresStoredNotes: undefined,

            tiresBoughtNotes: undefined,

            tiresStored: false,

            tiresBought: false,

            startTime: appointmentStart.toISOString (),

            endTime: appointmentEnd.toISOString (),
            
            isReservation: true

        } } );

        return scrollElement ( ".montage-form" );

    },

    Appointments = () => {

        if ( loading ) return <ComponentLoader />

        return <div className="datetime">

        { ( typeof data != "undefined" ) && DateState.view &&

            data.appointmentsForDay.map ( ( item, index ) => 
            
                <div className="card" key={ index }>
                
                    <input 
                    
                        type="radio" 
                        
                        id={ index + 1 } 
                        
                        name="time" 
                        
                        disabled={ ( item.status === "active" ) ? false : true } 
                        
                        defaultChecked={ ( index + 1 === TimeState.view ) ? true : false } onClick={ () => submitReservation ( item, index ) } />
                    
                    <label htmlFor={ index + 1 } className={ item.status }>

                        <div className="time">

                            { item.status === "active" ? TimeConverter ( item ) : t ( "time_expired" ) }

                        </div>

                        { ( availabilityCheck ( item.serviceBox1Busy, item.serviceBox2Busy, item.serviceBox3Busy ) ) ? 

                            ( item.status === "active" ) &&
                        
                                <div className="box">

                                    <span className={ "available" }></span>

                                    { t ( "free" ) }

                                </div>
                            
                                : 

                            ( item.status === "active" ) &&
                                
                                <div className="box">

                                    <span className={ "unavailable" }></span>

                                    { t ( "busy" ) }

                                </div> 
                            
                        }

                    </label>

                </div> 
            
            )

        }

        

        </div>

    }

    /** @return component */

    return ( <Appointments /> );

}

/** @export Datetime */
 
export default Datetime;