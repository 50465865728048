/** @module                         GQL */

import { useQuery }                 from "@apollo/client";

import { BANNERS }                  from "../../Queries/Banners";

import ComponentLoader              from "../Layout/Loaders/Loader";

import { Link }                     from "react-router-dom";

import BannerGIF                    from "../../Assets/7c9dd740-d75f-451c-9e8e-c365b90a1b95.gif";

const 

/**
 * 
 * @returns front-page static banner
 * 
 */

HomeStaticBanner = () => { const 

    { loading, data } = useQuery ( BANNERS );

    return (

        ( loading ) ? <ComponentLoader /> :

            // ( data.banners.filter( value => value.location === "catalogue" ).length > 0 && 
                
            //     <div className="banner">

            //         <div className="max-content-width">

            //             <Banner banner={ data.banners.filter( value => value.location === "catalogue" ) } />

            //         </div>

            //     </div>
                
            // )

            <div className="banner">

                <div className="max-content-width">

                    <Link to="/content/montage">

                        <img src={ BannerGIF } alt="Riepu montāža" />

                    </Link>

                </div>

            </div>

    );

},

/**
 * 
 * @param { object } banner
 * 
 * @returns banner
 * 
 */

Banner = ( { banner } ) => {

    /** @returns */

    return (

        <a href={ banner[0].link }>

            <img src={ banner[0].image } alt={ banner[0].title } />

        </a>

    );

}

/** @exports HomeStaticBanner */
 
export default HomeStaticBanner;