/** @module                         Hooks */

import { useParams, useNavigate }   from "react-router-dom";

import { useTranslation }           from "react-i18next";

/** @module                         Utilities */

import { TyresNavigator }           from "../../../../../Hooks/TyresNavigator";

/** @module                         React-Icons */

import { FaCheck }                  from "react-icons/fa";

const 

ShowSetFilter = () => { const 

    { t } = useTranslation (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    params              = new URLSearchParams ( parameters ),

    loadSetTires = event => { const

        object = { f : 12, l : null, a : null, b : null, loc: window.scrollY };

        event.target.checked ? object.sset = true : object.sset = null;

        return navigate (  
        
            `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
            
                new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

    }

    /** @returns */

    return (

        <div className="single_tire_filter hide-768">

            <label htmlFor={ "showSet" } className="checkbox">

                <input 
                
                    type="checkbox" 
                
                    id={ "showSet" } 
                
                    value={ "showSet" } 
                
                    onChange={ e => loadSetTires ( e ) } 
                    
                    checked={ params.get ( "sset" ) && ( ( params.get ( "sset" ) == "true" ) ? true : false ) } />

                <span className="check">

                    <span className="checked">

                        <FaCheck />

                    </span>

                </span>

                <span className="name">

                    { t ( "show_set" ) }

                </span>

            </label>

        </div>

    );

}
 
/** @exports ShowSetFilter */

export default ShowSetFilter;