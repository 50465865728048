import { Text, View, Image } from '@react-pdf/renderer';

import Logotype from "../../../../../../Assets/tireshop-logo.png";

import Links from './Footer/Links';

import Address from './Footer/Address';

import ContactsInformation from './Footer/Contacts';

import Hours from './Footer/Hours';

/**
 * 
 * @exports function DocumentFooter
 * 
 * @description Export the footer of the document as a React Component
 * 
 * @param { Object } contacts - contact information of the company
 * 
 * @param { Object } hours - working hours of the company
 * 
 * @returns { JSX.Element }
 * 
 */

export default function DocumentFooter ( { contacts } ) {

    /**
     * 
     * @description
     * 
     * Render the footer of the document as a React Component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View style={ { gap: 10 } }>

            <Image src={ Logotype } style={ { width: 150 } } />

            <View style={ { flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between", gap: 5 } }>

                <ContactsInformation contacts={ contacts } />

                <Hours contacts={ contacts } />

                <Address contacts={ contacts } />

                <Links />

            </View>
            
        </View>

    );

};