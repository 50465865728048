/** @import modules */

import { Link }                 from "react-router-dom";

import { useTranslation }       from "react-i18next";

/** @import misc */

import { BatteryPermalinks }    from "../../../../../Misc/Permalinks";

import { productClick }         from "../../../../../Misc/DataLayer";

/** @import components */

import Properties               from "../Properties";

/** @import assets */

import NoProduct                from "../../../../../Assets/no-batteries.jpg";

import Availability             from "../../../Tyres/Catalog/Item/Availability";

import AddCart from "./AddCart";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Catalog grid item component
 * 
 */

const GridItem = ( { product, notice } ) => { const permalink = BatteryPermalinks ( product ),

    { t } = useTranslation ();

    /** Return component */

    return ( <div className="item">

        { ( notice.view === product.id ) && <div className="cart-added-notice">
            
            { t ( "item_added_to_cart" ) }
            
        </div> }

        <Link to={ permalink } onClick={ () => { productClick ( product ) } }>

            <div className="thumbnail batteries">

                { ( ! product.currentImage ) ? 
                
                    <img src={ NoProduct } alt={ product.model } /> : 
                
                    <img src={ product.currentImage } alt={ product.model } onError={ ( { currentTarget } ) => {

                        currentTarget.src = NoProduct;
            
                    } } /> }

            </div>

        </Link>

        <Link to={ permalink } onClick={ () => { productClick ( product ) } }>

            <div className="name">
                
                <div className="model">

                    <span>{ product.manufacturer.name }</span>

                    { product.model }

                </div>
                
            </div>

        </Link>

            <Properties product={ product } />

            <Availability counter={ product.productVendors[0] } product={ product } />

            <AddCart product={ product } link={ permalink } notice={ notice } />

            <div className="price">
                
                &euro; { parseFloat ( product.priceList.retail_price ).toFixed ( 2 ) }
                
            </div>

    </div> );

}

/** Export component */
 
export default GridItem;