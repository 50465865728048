/** Import modules */

import { useNavigate, useParams }       from "react-router-dom";

import { useTranslation }               from "react-i18next";

/** Import hooks */

import { TyresNavigator }               from "../../../../../Hooks/TyresNavigator";

/** Import icons */

import { FaCheck }                      from "react-icons/fa";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Manufacturers filter component
 * 
 */

const LoadIndex = ( { attributes } ) => { const 

    { t }                   = useTranslation    (),

    navigate                = useNavigate       (),

    { parameters }          = useParams         (),

    params                  = new URLSearchParams ( parameters ),

    /** On manufacturer change update the query */

    loadIndexes = event => { const 

        object = { f : 12, l : null, a : null, b : null, li : params.get ( "li" ) ? params.get ( "li" ).split ( "," ) : [], loc: window.pageYOffset };

        if ( event.target.checked ) { object.li.push ( event.target.value );

            return navigate (  
            
                `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );
        
        } else { object.li.splice ( params.get ( "li" ).split ( "," ).indexOf ( event.target.value ), 1 );

            return navigate (  
            
                `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

        }

    }

    /** Return component */

    return ( <div className="content manufacturers">

        <div className="attribute-filter">

            <div className="collection-container">

                <div className="collection" id="collection">

                     { sortPrams ( attributes, params, "li" ).map ( ( loadIndex, index ) => 

                        <label 
                        
                            htmlFor={ `load_${ loadIndex.value }` } 
                            
                            className="checkbox" 
                            
                            key={ index }>

                            <input 

                                type="checkbox" 

                                id={ `load_${ loadIndex.value }` } 

                                value={ loadIndex.text } 

                                onChange={ e => loadIndexes ( e ) } 
                                
                                checked={ params.get ( "li" ) && ( params.get ( "li" ).split ( "," ).indexOf ( loadIndex.text.toString () ) !== -1 ) } />

                            <span className="check">

                                <span className="checked">

                                    <FaCheck />

                                </span>

                            </span>

                            <span className="name">

                                { loadIndex.text }

                            </span>

                        </label> 
                    
                    ) }

                </div>

            </div>

        </div>

    </div> );

},

/**
 * 
 * @param { attributes } param0
 * 
 * @param { params } param1
 * 
 * @param { key } param2
 * 
 * @returns sorted params
 * 
 * Sort params by checked values
 * 
 * If checked values exist, move them to the top of the list
 * 
 * If checked values don't exist, return the list as it is
 * 
 */

sortPrams = ( attributes, params, key ) => { const

    /** Get checked values */

    checkedValues = params.get ( key ) ? params.get ( key ).split ( "," ) : [],

    /** Get unchecked values */

    loadIndexes = attributes.filter ( attribute => !checkedValues.includes ( attribute.value ) );

    /** If checked values exist, move them to the top of the list */

    if ( checkedValues.length ) { checkedValues.forEach ( checkedValue => { const

        index = attributes.findIndex ( attribute => attribute.value === checkedValue );

        loadIndexes.unshift ( attributes [ index ] );

    } ); }

    /** Return the list */

    return loadIndexes;

}


/** Export component */
 
export default LoadIndex;