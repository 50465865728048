/**
 * 
 * @param { title } param0
 * 
 * @param { children } param1
 * 
 * @returns Compare page layout component
 * 
 */

const Layout = ( { title, children } ) => {

    /** @returns component */

    return ( <div className="content compare">

        <div className="title">
            
            <h2>{ title }</h2>
            
        </div>

        <div className="layout">

            <section>{ children }</section>

        </div>

    </div> );

}

/** @export component */

export default Layout;