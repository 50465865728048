/** @import modules */

import { useContext }       from "react";

/** @import datasets */

import { MontageTypes }     from "../../Datasets/Montage";

/** @import contexts */

import { MontageContext }   from "../../Contexts/MontageContext";

/** @import miscs */

import { scrollElement }    from "../../Misc/ScrollElement";

/**
 * 
 * @returns montage service type selector component
 * 
 */

const Type = () => { const 

    { Type } = useContext ( MontageContext );

    /** @return component */

    return ( <div className="type">

        { MontageTypes ().map ( ( option, index ) =>

            <label htmlFor={ option.value } key={ index }>

                <input 
                
                    type="radio" 
                
                    id={ option.value } 
                
                    name="montage_type" 
                
                    value={ option.value } 
                
                    onChange={ e => { scrollElement ( ".montage-application" ); Type.set ( e.target.value ); } } 
                    
                    defaultChecked={ Type.view === option.value ? true : false } />

                <span className="custom-radio"></span>

                <span>{ option.name }</span>

            </label>

         ) }

    </div> );

}

/** @export Type */
 
export default Type;