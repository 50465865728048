import { useParams, useNavigate } from "react-router-dom";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import { RETRY_PAYMENT } from "../../Queries/Orders";

import Breadcrumbs from "../../Components/Layout/Header/Breadcrumbs";

import NoticeNeutral from "../../Components/Layout/Notices/Neutral";

/**
 * 
 * @exports RetryCheckout
 * 
 * @description Retry payment component for failed transactions
 * 
 * @returns { JSX.Element }
 * 
 */

export default function RetryCheckout () {

    const 
    
    { t } = useTranslation (),

    navigate = useNavigate (),
    
    { hash_id } = useParams (),

    { data, loading, error } = useQuery ( RETRY_PAYMENT, { variables: { orderHashId: hash_id } } ),

    [ invalidLink, setInvalidLink ] = useState ( false ),

    returnHome = () => navigate ( "/" ),

    retryPayment = () => {

        if ( invalidLink ) setInvalidLink ( false );

        if ( data?.orderRetryPayment?.paymentLink === null ) {

            return setInvalidLink ( true );

        }
        
        return window.location.href = data?.orderRetryPayment?.paymentLink;

    }

    /**
     * 
     * @description
     * 
     * Render retry payment component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <div className="container max-content-width">

            <Breadcrumbs collection={ [ 
                
                { name: t ( "checkout" ), URI: "/orders/checkout" },

                { name: t ( "payment_failed" ), URI: "/orders/checkout" } 
                
            ] } type="basic" />

            <div className="checkout_sp">

                <h2>{ t ( "payment_failed" ) }</h2>

                <p>{ t ( "payment_failed_description" ) }</p>

                { /** @description Display error notice */
                
                    error && <NoticeNeutral notice={ error?.message || "Internal Server Error" } /> 
                    
                }

                { /** @description Display error notice */
                
                    invalidLink && <NoticeNeutral notice={ t ( "invalidRetryLink" ) } /> 
                    
                }

                <div className="submit">

                    <button className="submit" onClick={ retryPayment } disabled={ loading }>

                        { t ( "retry_payment" ) }
                        
                    </button>

                    <button className="submit" onClick={ returnHome }>

                        { t ( "return_home" ) }
                        
                    </button>

                </div>

            </div>

        </div>

    );

};