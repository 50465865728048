/** @import modules */

import { useTranslation     } from "react-i18next";

/** @import models */

import ContactsModel        from "../../../../Models/Contacts";

/**
 * 
 * @returns Mobile navigation contacts component
 * 
 */

const MobileNavContacts = () => { const { t } = useTranslation (),

    { data, loading } = ContactsModel ();

    /** @returns */

    return (<div className="contacts">

        <a href={ ( loading ) ? "/" : `tel:${ data.contactInfo.contact_info.shop_phone }` }>

            { t ( "call" ) } { ( loading ) ? `${ t ( "loading" ) }...` : data.contactInfo.contact_info.shop_phone }

        </a>

    </div>);
    
}

/** @export MobileNavContacts */
 
export default MobileNavContacts;