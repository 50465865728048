/** @module                     Hooks */

import { useTranslation }       from "react-i18next";

/**
 * 
 * @param { price } param0 
 * 
 * @returns Single product price component
 * 
 */

const Price = ( { price } ) => { const { t } = useTranslation ();

    /** Return component */

    return ( <div className="price">

        <span className="tooltip">

            { t ( "tooltip_price" ) }

        </span>

        &euro; { price }

    </div> );

}

/** Export component */
 
export default Price;