/** @module                         Hooks */

import { useEffect }                from "react";

/** @module                         Utilities */

import { onCheckoutOption }         from "../../../../Misc/DataLayer";

/** @module                         React-Icons */

import { FaDotCircle }              from "react-icons/fa";

const 

Customer = ( { state, action, __, type, setType } ) => {

    /** @event useEffect */

    useEffect ( () => {

        ( type ) && onCheckoutOption ( 1, type ) ;

    }, [ type ] );

    /** @returns */

    return (

        <div className="sector customer">

            <div className="radios">
                
                <div className="radio">

                    <label htmlFor="private" className={ `${ type === "private" ? "selected" : "default" }` }>

                        <input 
                        
                            type="radio" 
                        
                            id="private" 
                        
                            name="customerType" 
                        
                            value="private" 
                        
                            checked={ type === "private" ? true : false } 
                        
                            onChange={ () => setType ( "private" ) } />

                        <span className="icon">

                            <span className="checked">

                                <FaDotCircle />

                            </span>

                        </span>

                        <span className="name">

                            { __( "private_person" ) }

                        </span>

                    </label>

                </div>

                <div className="radio">

                    <label htmlFor="legal" className={ `${ type === "legal" ? "selected" : "default" }` }>

                        <input 
                        
                            type="radio" 
                        
                            id="legal" 
                        
                            name="customerType" 
                        
                            value="legal" 
                        
                            checked={ type === "legal" ? true : false } 
                        
                            onChange={ () => setType ( "legal" ) } />

                        <span className="icon">

                            <span className="checked">

                                <FaDotCircle />

                            </span>

                        </span>

                        <span className="name">

                            { __( "legal_person" ) }

                        </span>

                    </label>

                </div>
                
            </div>

            { type === "legal" &&

                <div className="form-group">

                    <div className="field">

                        <label htmlFor="companyName">

                            { __( "company_name" ) }

                        </label>

                        <input 
                        
                            type="text" 
                        
                            name="companyName" 

                            value={ state.companyName }

                            onChange={ e => action ( e ) }
                        
                            id="companyName" />

                    </div>

                    <div className="field">

                        <label htmlFor="regNr">

                            { __( "regnr" ) }

                        </label>

                        <input 
                        
                            type="text" 
                        
                            name="regNr" 

                            value={ state.regNr }

                            onChange={ e => action ( e ) }
                        
                            id="regNr" />

                    </div>

                    <div className="field">

                        <label htmlFor="pvnRegNr">

                            { __( "vat" ) }

                        </label>

                        <input 
                        
                            type="text" 
                        
                            name="pvnRegNr" 

                            value={ state.pvnRegNr }

                            onChange={ e => action ( e ) }
                        
                            id="pvnRegNr" />

                    </div>

                </div>

            }

            <div className="form-group">

                <div className="field">

                    <label htmlFor="firstName">

                        { __( "name" ) }

                    </label>

                    <input 
                    
                        type="text" 
                    
                        name="firstName" 

                        value={ state.firstName }

                        onChange={ e => action ( e ) }
                    
                        id="firstName" />

                </div>

                <div className="field">

                    <label htmlFor="lastName">

                        { __( "lastname" ) }

                    </label>

                    <input 
                    
                        type="text" 
                    
                        name="lastName" 

                        value={ state.lastName }

                        onChange={ e => action ( e ) }
                    
                        id="lastName" />

                </div>

                <div className="field">

                    <label htmlFor="email">

                        { __( "email" ) }

                    </label>

                    <input 
                    
                        type="text" 
                    
                        name="email" 

                        value={ state.email }

                        onChange={ e => action ( e ) }
                    
                        id="email" />

                </div>

                <div className="field">

                    <label htmlFor="mobilePhoneNr">

                        { __( "phone" ) }

                    </label>

                    <input 
                    
                        type="text" 
                    
                        name="mobilePhoneNr" 

                        value={ state.mobilePhoneNr }

                        onChange={ e => action ( e ) }
                    
                        id="mobilePhoneNr" />

                </div>

            </div>

        </div>

    );

}

/** @exports Cutomer */
 
export default Customer;