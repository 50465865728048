/** @import modules */

import { Fragment } from "react";

import { useTranslation } from "react-i18next";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Product properties component
 * 
 */

const Properties = ( { product } ) => { const { t } = useTranslation (),

    properties_collection = [

        { 

            type: "text",
            
            value: ( product.voltage === 0 ) ? null : parseFloat ( product.voltage ), 

            label: `${ product.voltage } V`,
            
            tooltip: t ( "voltages" ),
        
            case: "lowercase" },

        { 

            type: "text",
            
            value: ( product.current === 0 ) ? null : parseFloat ( product.current ), 

            label: `${ product.current } A`,
            
            tooltip: t ( "currents" ),
        
            case: "lowercase" },

        {  

            type: "text",
            
            value: ( product.capacity === 0 ) ? null : parseFloat ( product.capacity ), 

            label: `${ product.capacity } Ah`,
            
            tooltip: t ( "capacities" ),
        
            case: "lowercase" }

    ],

    properties = properties_collection.filter ( item => item.value !== null );

    /** Return component */

    return ( <div className="product-props properties">

        { properties.map ( ( prop, index ) => <Fragment key={ index }>

            { prop.type === "chart" && <div className="prop">

                <div className="props-tooltip">

                    { prop.tooltip }

                </div>

                <div className="icon">
                    
                    { prop.icon }
                
                </div>

                <div className={ `value property-color-${ prop.color } ${ prop.case }` }>
                    
                    { prop.label }
                    
                </div>

            </div> }

            { prop.type === "tag" && <div className="prop">

                <div className="props-tooltip">

                    { prop.tooltip }

                </div>

                <div className="icon">

                    { prop.icon }

                </div>

                <div className="value-numeric">

                    { prop.label }

                </div>

            </div> }

            { prop.type === "text" && <div className="prop text-prop">

                <div className="props-tooltip">

                    { prop.tooltip }

                </div>

                { prop.label }

            </div> }

        </Fragment> ) }

    </div> );

}

/** Export component */
 
export default Properties;