/** @import assets */

import visa                 from "../../../Assets/gateway/visa.png";

import mastercard           from "../../../Assets/gateway/mastercard.png";

import securecode           from "../../../Assets/gateway/securecode.png";

import visaverified         from "../../../Assets/gateway/visa-verified.png";

/** @import modules */

import { useTranslation }   from "react-i18next";

const Gateway = () => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="footer-gateway">

        <div className="description">

            { t ( "gateway_notice" ) }

        </div>

        <div className="icons">

            <img src={ visa } alt="visa" />

            <img src={ mastercard } alt="mastercard" />

            <img src={ securecode } alt="securecode" />

            <img src={ visaverified } alt="visaverified" />

        </div>

    </div> );

}

/** @exports Gateway */

export default Gateway;