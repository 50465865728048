/** @module                         Hooks */

import { useState, useEffect }      from 'react';

import { useTranslation }           from 'react-i18next';

const 

CountdownTimer = ( { enable } ) => { const 
    
    { t } = useTranslation (),

    [ minutes, setMinutes ] = useState ( 3 ),

    [ seconds, setSeconds ] = useState ( 0 );

    useEffect(() => {

        if ( ! enable ) return;

        const timer = setInterval ( () => {

          if ( minutes === 0 && seconds === 0 ) {

            clearInterval ( timer );

            window.location.reload ();

          } else {

            if (seconds === 0) {

              setMinutes ( ( prevMinutes ) => prevMinutes - 1 );

              setSeconds ( 59 );

            } else {

              setSeconds ( ( prevSeconds ) => prevSeconds - 1 );

            }

          }

        }, 1000 );
    
        return () => {
            
          clearInterval ( timer );

        };

      }, [ minutes, seconds, enable ] );

    return (

        <div className='montage_countdown'>

            { t ( "remaining_time" ) }:&nbsp;

            { minutes === 0 && seconds === 0 ? null : <span className="timer">{ minutes }:{ seconds < 10 ? `0${ seconds }` : seconds }</span> }

        </div>

    );

}

/** @exports CountdownTimer */
 
export default CountdownTimer;