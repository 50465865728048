/**
 * 
 * @param { attributes }
 *  
 * @returns appointment datetime
 * 
 */

const Placeholder = ( { attributes } ) => { const 

    year            = attributes.date.getFullYear   (),

    month           = ( attributes.date.getMonth    () + 1  < 10 ) ? `0${ attributes.date.getMonth () + 1 }`    : attributes.date.getMonth  () + 1,

    day             = ( attributes.date.getDate     ()      < 10 ) ? `0${ attributes.date.getDate () }`         : attributes.date.getDate   (),

    date            = `${ day }.${ month }.${ year }`,


    startHour       = ( attributes.startHour    < 10 ) ? `0${ attributes.startHour }`   : attributes.startHour,

    startMinutes    = ( attributes.startMinute  < 10 ) ? `0${ attributes.startMinute }` : attributes.startMinute,

    endHour         = ( attributes.endHour      < 10 ) ? `0${ attributes.endHour }`     : attributes.endHour,

    endMinutes      = ( attributes.endMinute    < 10 ) ? `0${ attributes.endMinute }`   : attributes.endMinute;

    /** @returns form datetime placeholder */

    return ( <div className="placeholder">

        { `${ date } (${ startHour }:${ startMinutes } - ${ endHour }:${ endMinutes })` }

    </div> );

}

/** @exports Placeholder */
 
export default Placeholder;