/** @module                         Hooks */

import { useEffect }                from "react";

/** @module                         Utilities */

import { onCheckoutOption }         from "../../../../Misc/DataLayer";

/** @module                         Datasets */

import { countries }                from "../../../../Datasets/Locales";

import { latvianCities }            from "../../../../Datasets/Locales";

import { lithuanianCities }         from "../../../../Datasets/Locales";

import { estonianCities }           from "../../../../Datasets/Locales";

/** @module                         React-Icons */

import { FaDotCircle }              from "react-icons/fa";

const 

Delivery = ( { state, action, __, data } ) => { const 

    handleCountryChange = e => {

        action ( e );

        action ( {

            target: {

                name: "city",

                value: e.target.value === "LV" ? latvianCities[ 0 ] : e.target.value === "LT" ? lithuanianCities[ 0 ] : estonianCities[ 0 ]

            }

        } );

    },

    updateDelivery = e => {

        action ( {

            target: {

                name: "deliveryPlanId",

                value: parseInt ( e.target.value )

            }

        } );

    };

    /** @event useEffect */

    useEffect ( () => {

        if ( state.deliveryPlanId === 0 || state.deliveryPlanId === "" ) return;

        action ( {

            target: {

                name: "deliveryMethod",

                value: data.deliveryPlans.find ( elem => parseInt ( elem.id ) === parseInt ( state.deliveryPlanId ) ).value.toLowerCase ().split ( " " ).join ( "_" )

            }

        } );

        ( state.deliveryMethod ) && onCheckoutOption ( 2, state.deliveryMethod ) ;

    }, [ state.deliveryPlanId ] );

    /** @event useEffect */

    useEffect ( () => {

        action ( {

            target: {

                name: "deliveryPlanId",

                value: parseInt ( data.deliveryPlans[ 0 ].id )

            }

        } );

    }, [] );

    /** @returns */

    return (

        <div className="sector delivery">

            <div className="radios">

                {

                    data.deliveryPlans.map ( ( option, index ) => {

                        return (

                            <div className="radio" key={ index }>

                                <label htmlFor={ option.value } className={ `${ state.deliveryMethod === option.value.toLowerCase ().split ( " " ).join ( "_" ) ? "selected" : "default" }` }>

                                    <input 

                                        type="radio" 

                                        id={ option.value } 

                                        name="deliveryMethod" 

                                        value={ option.id } 

                                        checked={ state.deliveryPlanId == option.id ? true : false } 

                                        onChange={ e => updateDelivery ( e ) } />

                                    <span className="icon">

                                        <span className="checked">

                                            <FaDotCircle />

                                        </span>

                                    </span>

                                    <span className="name">

                                        { __ ( option.value.split ( " " ).join ( "_" ).toLowerCase () ) }

                                    </span>

                                </label>

                            </div>

                        );

                    } )

                }

            </div>

            { state.deliveryMethod === "pickup" &&
            
                <div className="notification valid">

                    { __ ( "store_delivery_label" ) }

                </div>
            
            }

            { ( state.deliveryMethod === "tireshop_delivery" || state.deliveryMethod === "courier" ) && 

                <div className="form-group">

                    <div className="field">

                        <label htmlFor="countryCode">

                            { __( "country" ) }

                        </label>

                        <select name="countryCode" id="countryCode" onChange={ e => handleCountryChange ( e ) } value={ state.countryCode }>

                            { countries.map ( ( country, index ) => {
                                
                                return <option key={ index } value={ country.code }>{ __ ( country.name ) }</option>

                            } ) }

                        </select>

                    </div>

                    <div className="field">

                        <label htmlFor="city">

                            { __( "town" ) }

                        </label>

                        <select name="city" id="city" onChange={ e => action ( e ) } value={ state.city }>

                            { state.countryCode === "LV" && latvianCities.map ( ( city, index ) => {

                                return <option key={ index } value={ city }>{ __ ( city ) }</option>

                            } ) }

                            { state.countryCode === "LT" && lithuanianCities.map ( ( city, index ) => {

                                return <option key={ index } value={ city }>{ __ ( city ) }</option>

                            } ) }

                            { state.countryCode === "EE" && estonianCities.map ( ( city, index ) => {

                                return <option key={ index } value={ city }>{ __ ( city ) }</option>

                            } ) }

                        </select>

                    </div>

                    <div className="field">

                        <label htmlFor="address">

                            { __( "street" ) }

                        </label>

                        <input 
                        
                            type="text" 
                        
                            name="address" 

                            value={ state.address }

                            onChange={ e => action ( e ) }
                        
                            id="address" />

                    </div>

                    <div className="field">

                        <label htmlFor="postCode">

                            { __( "postal" ) }

                        </label>

                        <input 
                        
                            type="text" 
                        
                            name="postCode" 

                            value={ state.postCode }

                            onChange={ e => action ( e ) }
                        
                            id="postCode" />

                    </div>

                </div>

            }

        </div>

    );

}

/** @exports Delivery */
 
export default Delivery;