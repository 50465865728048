/** Import hooks */

import { Cleaner } from "../Misc/ObjectCleaner";

const

/** @returns URL parameters */

TyresNavigator = ( params, update ) => { const parameters = new URLSearchParams ( params ),

    /** Register attributes */

    attributes = {

        f       : parseInt      ( parameters.get ( "f" ) ),

        l       : parseInt      ( parameters.get ( "l" ) ),

        b       : parameters.get ( "b" ),

        a       : parameters.get ( "a" ),

        w       : parseInt      ( parameters.get ( "w" ) ),

        h       : parseFloat    ( parameters.get ( "h" ) ),

        d       : parameters.get ( "d" ),

        s       : parameters.get ( "s" ),

        m       : parameters.get ( "m" ),

        sp      : parameters.get ( "sp" ),

        li      : parameters.get ( "li" ),

        ni      : parameters.get ( "ni" ),

        sr      : parameters.get ( "sr" ),

        fe      : parameters.get ( "fe" ),

        wg      : parameters.get ( "wg" ),

        vt      : parameters.get ( "vt" ),

        pr      : parameters.get ( "pr" ),

        sort    : parameters.get ( "sort" ),

        ss      : parameters.get ( "ss" ),

        sset    : parameters.get ( "sset" ),

        ct      : parameters.get ( "ct" ),

        st      : parameters.get ( "st" ),
        
        av      : parameters.get ( "av" ),

        rf      : parameters.get ( "rf" )

    };

    /** Optimize, clean & return attributes */

    return Cleaner ( { ...attributes, ...update } );

},

/** @returns Query attributes based on URL parameters */

QueryAttributes = ( params ) => { const parameters = new URLSearchParams ( params );

    /** Optimize, clean & return query attributes */

    return Cleaner ( {

        first                       : parseInt      ( parameters.get ( "f" ) ),

        last                        : parseInt      ( parameters.get ( "l" ) ),

        before                      : parameters.get ( "b" ),

        after                       : parameters.get ( "a" ),

        width                       : parseInt      ( parameters.get ( "w" ) ),

        height                      : parseFloat    ( parameters.get ( "h" ) ),

        diameter                    : parameters.get ( "d" ),

        season                      : parameters.get ( "s" ) ? parameters.get ( "s" ).split ( "," ) : [],

        manufacturer                : parameters.get ( "m" ) ? parameters.get ( "m" ).split ( "," ) : [],

        specifications              : parameters.get ( "sp" ),

        loadIndex                   : parameters.get ( "li" ) ? parameters.get ( "li" ).split ( "," ) : [],

        noiseIndex                  : parameters.get ( "ni" ) ? parameters.get ( "ni" ).split ( "," ) : [],

        speedRating                 : parameters.get ( "sr" ) ? parameters.get ( "sr" ).split ( "," ) : [],

        vehicleType                 : parameters.get ( "vt" ),

        fuelEfficiency              : parameters.get ( "fe" ) ? parameters.get ( "fe" ).split ( "," ) : [],

        wetBreakingEfficiency       : parameters.get ( "wg" ) ? parameters.get ( "wg" ).split ( "," ) : [],

        upperPriceRange             : ( params.get("pr") ) ? parseInt ( params.get("pr").split ( "," )[1] ) : null,
        
        lowerPriceRange             : ( params.get("pr") ) ? parseInt ( params.get("pr").split ( "," )[0] ) : null,

        sortOrder                   : parameters.get ( "sort" ),

        showSingles                 : Boolean ( parameters.get ( "ss" ) ),

        isCTire                     : Boolean ( parameters.get ( "ct" ) ),

        runFlat                     : Boolean ( parameters.get ( "rf" ) ),

        studs                       : parameters.get ( "st" ),
        
        showSet                     : Boolean ( parameters.get ( "sset" ) ),

        isCTire                     : Boolean ( parameters.get ( "ct" ) ),

        availability                : parameters.get ( "av" ) ? parameters.get ( "av" ).split ( "," ) : []

    } );

};

/** Export hooks */
 
export { TyresNavigator, QueryAttributes };