/** Import modules */

import { gql } from "@apollo/client";

const 

/** Catalog product */

SingleProduct = gql`query SingleProductQuery ( $id: [ID!]! ) { product ( id: $id ) { 

    edges { node {

        awsImageUrl

        currentImage

        boltsIncluded

        capacity

        centreBore

        createdAt

        current

        dedicatedAxle

        deleted

        description

        diameter

        ean

        specifications

        freightClass

        fuelEfficiency

        hashId

        height

        id

        image

        imageCrop

        length

        loadIndex

        manufacturer { name }

        manufacturerId

        model

        noiseIndex

        noiseLevel

        offset

        pitchCircleDiameter

        priceList 

        productType

        productVendors { 

            price
            
            qty 
            
            qtyDays
            
            qtyHour
            
            qtyWeeks

        }

        radius

        roadSurface

        scraper

        season

        specifications

        speedRating

        studs

        uid

        updatedAt

        url

        usage

        used

        viewCount

        viewUniqCount

        visible

        voltage

        wetBreakingEfficiency

        width

        year

        isCTire

    } }
          
} }`;

/** Export queries */

export { 
    
    SingleProduct 

};