/** @module                 React */

import { useState }         from "react";

import { useNavigate }      from "react-router-dom";

/** @module                 Utilities */

import { onPurchase }       from "../../Misc/DataLayer";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

const 

/** 
 * 
 * @returns useCreate hook for creating new mutation
 * 
 */

useCreate = ( MUTATION ) => { const 

    navigate = useNavigate (),

    /** @hooks */

    [ loading,  setLoading  ]       = useState ( false ),

    [ errors,   setErrors   ]       = useState ( false ),

    [ complete, setComplete ]       = useState ( false ),

    [ returnData, setReturnData ]   = useState ( {} ),

    /** @mutations */

    [ CreateEntry ] = useMutation ( MUTATION, {

        /** @event onComplete */

        onCompleted ( data ) {

            setLoading ( false );

            if ( data.createOrder.errors.length === 0 ) {

                setReturnData ( data );

                setComplete ( true );

                onPurchase ( data.createOrder.order );

                if ( data.createOrder.order.paymentMethod === "online_card_payment" ) {

                    return window.location.href = data.createOrder.paymentLink;

                }

                return navigate ( "/orders/checkout/receipt" );

            }

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( true ); }

    } ),

    /** 
     * 
     * @param { String } title
     * 
     * @param { String } content
     * 
     * @param { String } description
     * 
     * @param { Boolean } isPublished
     * 
     * @param { Boolean } tiremarketOnly
     * 
     * @param { String } language
     * 
     * @returns create post action
     * 
     */

    createMutation = ( data ) => {

        /** @override hook states */

        setErrors ( false ); setLoading ( true ); 

        /** @mutation */

        CreateEntry ( { variables: data } );

    }

    /** @returns */

    return { createMutation, loading, complete, returnData, errors, setErrors }

};

/** @exports useCreate */

export default useCreate;