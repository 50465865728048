/** @import components */

import { useEffect, useState, useRef } from "react";

import NoResults from "./NoResults";

import Item from "./Item";

/**
 * 
 * @param { products } 
 * 
 * @returns quick search product results
 * 
 */

const Results = ( { products } ) => { const 

    [ notification, setNotification ]   = useState ( 0 ),

    isMounted                           = useRef ( false ),

    /** @returns found products */

    Collection = ( { items } ) => {

        if ( ! items.length ) return <NoResults />

        return items.map ( ( item, index ) => <Item product={ item.node } key={ index } notice={ { view: notification, set: setNotification } } /> );

    };

    useEffect ( () => {

        if (isMounted.current) {
            const timer = setTimeout(() => setNotification ( "" ), 1000);
            return () => clearTimeout(timer);
          } else {
            isMounted.current = true;
          }

    }, [ notification ] );

    /** @returns component */

    return ( <div className="collection">

        <Collection items={ products } />

    </div> );

}

/** @export Results */
 
export default Results;