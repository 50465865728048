/** @exports scrollElement */

const scrollElement = element => { const 

    target  = document.querySelector ( element ).getBoundingClientRect ().top,

    body    = document.body.getBoundingClientRect ().top,

    offset  = document.querySelector ( "header" ).offsetHeight;

    return window.scrollTo ( {

        behavior : "smooth",

        top : target - body - offset - 24

    } );

}

export { scrollElement };