/** Import modules */

import { useState, useEffect } from "react";

/** Fetch stored shopping cart from local storage */

const cartStorage = JSON.parse ( localStorage.getItem ( "cart" ) ) || JSON.parse ( "[]" );

/**
 * 
 * @returns Cart hook
 * 
 */

export const CartHook = () => { const

    [ cart, setCart ] = useState ( cartStorage );

    useEffect( () => {

        localStorage.setItem( "cart", JSON.stringify( cart ) );

    }, [ cart, setCart ] );

    /** @returns hook data */

    return { cart, setCart };

}