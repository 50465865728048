/** Import modules */

import { useTranslation }   from "react-i18next";

import { useQuery }         from "@apollo/client";

import { FrontEndPages }    from "../../Queries/Policies";

import cookies              from "js-cookie";

import parse               from "html-react-parser";

import ComponentLoader      from "../../Components/Layout/Loaders/Loader";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import ContentsLayout       from "../../Components/Contents/Layout";

/**
 * 
 * @returns Delivery page
 * 
 */

const Delivery = () => { const { t } = useTranslation (),

    { data, loading } = useQuery ( FrontEndPages, { variables: { pageType: "delivery", language: cookies.get ( 'i18next' ) } } );

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "delivery_information" ), URI: "/content/delivery" }

        ] } />

        <ContentsLayout title={ t ( "delivery_information" ) }>

            { 

                loading ? <ComponentLoader /> :

                    data?.frontendPages?.edges[0]?.node?.content ?
            
                        parse ( data.frontendPages.edges[0].node.content ) :

                        <p>Unable to retrive data.</p>
                
            }
            
        </ContentsLayout>
        
    </div> );

}

/** Export component */
 
export default Delivery;