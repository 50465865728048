const Location = () => {

    /** @return component */

    return ( <div className="location">

        <iframe 
        
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2177.2811642114284!2d24.1859592!3d56.926848500000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eed2001a02ba53%3A0x92b852318d676e58!2sTireshop%20Riga!5e0!3m2!1sen!2slv!4v1675865366594!5m2!1sen!2slv" 
            
            title="tireshop_sia"

            loading="lazy" 
            
            referrerPolicy="no-referrer-when-downgrade">

        </iframe>

    </div> );

}
 
/** @export Location */

export default Location;