/** Import datasets */

import HeaderDatasets from "../../../../Datasets/Header";

/**
 * 
 * @returns Mobile navigation business hours component
 * 
 */

const MobileNavBusiness = () => {

    /** Return component */

    return (<div className="business-information">

        <ul>

            { HeaderDatasets ().Hours.map ( ( attr, index ) => <li key={ index }>

                <div className="name">

                    { attr.name }

                </div>

                <div className="content">

                    { attr.content }

                </div>

            </li> ) }

        </ul>

    </div>);
    
}

/** Export component */
 
export default MobileNavBusiness;