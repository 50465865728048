import { Text, View } from '@react-pdf/renderer';

import DocumentSeller from './Entities/Seller';

import DocumentCustomer from './Entities/Customer';

export default function DocumentEntities ( { state, contacts } ) {

    return (

        <View style={ { flexDirection: "row", gap: 10, flexWrap: "nowrap" } }>

            <DocumentCustomer state={ state } contacts={ contacts } />

            <DocumentSeller contacts={ contacts } />
            
        </View>

    );

};