/** @imports */

import { useEffect              } from "react";

import { useLocation, useParams } from "react-router-dom";

/** @returns effect hook to position user in their previous position */

const usePosition = () => { const 

    location    = useLocation (),

    routeParams = useParams ().parameters;

    useEffect ( () => {

        if ( new URLSearchParams ( routeParams ).has ( "loc" ) ) {

            if ( parseInt ( new URLSearchParams ( routeParams ).get ( "loc" ) ) !== window.pageYOffset ) {

                window.scrollTo ( 0, parseInt ( new URLSearchParams ( routeParams ).get ( "loc" ) ) );

            }

        }

    }, [ location, routeParams ] );

}

/** @exports usePosition */
 
export default usePosition;