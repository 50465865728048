import { Text, View } from '@react-pdf/renderer';

export default function DocumentUserNotes ( { message } ) {

    return (

        <View style={ { gap: 5 } }>

            <Text>Piezīmes: </Text>

            <Text style={ { backgroundColor: "#eee", padding: 5 } }>

                { message || "-" }

            </Text>

        </View>

    );

};