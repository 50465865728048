/** @import modules */

import { useTranslation }           from "react-i18next";

import { useParams, useNavigate }   from "react-router-dom";

/** @import hooks */

import { RimsNavigator }            from "../../../../../Hooks/RimsNavigator";

/** @import icons */

import { BsFillCaretDownFill }      from "react-icons/bs";

/**
 * 
 * @param { attributes } 
 * 
 * @returns pitch circle diameter filter component
 * 
 */

const PitchCircleDiameter = ( { attributes } ) => { const 

    { t }               = useTranslation    (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    Filter = ( event ) => { const 

        object = { f : 12, l: null, a: null, b: null, pcd: ( event.target.value === "default" ) ? null : event.target.value, loc: window.pageYOffset };

        return navigate (  
            
            `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }/${ 
            
                new URLSearchParams ( RimsNavigator ( parameters, object ) ).toString () }` );

    };

    /** @return component */

    return ( <div className="content manufacturers">

        <label className="select shadow-select">

            <select onChange={ e => Filter ( e ) } defaultValue={ new URLSearchParams ( parameters ).get ( "pcd" ) || "default" }>

                <option value="default">

                    { t ( "select_option" ) }

                </option>

                { attributes.map ( ( option, key ) => <option 
                    
                    key={ key } 
                    
                    value={ option.value }>

                        { option.text }

                </option> ) }

            </select>

            <span>
                
                <BsFillCaretDownFill />
                
            </span>

        </label>

    </div> );

}

/** @export PitchCircleDiameter */
 
export default PitchCircleDiameter;