const 

mutationArgs = params => { const

    appointmentStart = new Date ( 
            
        params.date.view.getFullYear (), 
        
        params.date.view.getMonth (), 
        
        params.date.view.getDate (), 
        
        params.start.hour.view, 
        
        params.start.minute.view 
        
    ),

    appointmentEnd = new Date ( 
        
        params.date.view.getFullYear (), 
        
        params.date.view.getMonth (), 
        
        params.date.view.getDate (), 
        
        params.end.hour.view, 
        
        params.end.minute.view 
        
    );

    return {

        transportType : params.type.view,

        startTime : appointmentStart.toISOString (),

        endTime : appointmentEnd.toISOString (),

        autoNumber : params.vehicle[ 0 ].value.view,

        autoBrand : params.vehicle[ 1 ].value.view,

        autoModel : params.vehicle[ 2 ].value.view,

        fullName : params.customer[ 0 ].value.view,

        phoneNumber : params.customer[ 1 ].value.view,

        email : params.customer[ 2 ].value.view,

        tiresStored : params.stored.view,

        tiresBought : params.bought.view,

        tiresStoredNotes : params.storedNote.view,

        tiresBoughtNotes : params.boughtNote.view,

        notes : params.note.view,

        wantTires : params.wantTires.view,

        wantNotes : params.wantNotes.view,

        tiresOwn : params.tiresOwn.view,

        tiresOwnNotes : params.tiresOwnNotes.view,

        tiresOther : params.tiresOther.view,

        tiresOtherNotes : params.tiresOtherNotes.view,

        tiresReturned : params.tiresReturned.view,

        tiresStoredAfter : params.tiresStoredAfter.view,

        tiresDisposed : params.tiresDisposed.view,

        afterMontageOther : params.afterMontageOther.view,

        afterMontageOtherNotes : params.afterMontageOtherNotes.view

    };

}

export default mutationArgs;