const

/** @returns Query attributes based on URL parameters */

RefetchAttributes = ( parameters ) => {

    /** Optimize, clean & return query attributes */

    return {

        first                       : ( parameters.f ) ? parseInt ( parameters.f ) : null,

        last                        : ( parameters.l ) ? parseInt ( parameters.l ) : null,

        before                      : ( parameters.b ) ? parameters.b : null,

        after                       : ( parameters.a ) ? parameters.a : null

    };

};

/** Export hooks */
 
export { RefetchAttributes };