/** Import assets */

import Logotype from "../../../Assets/tireshop-logo.png";

/** Import modules */

import { Link } from "react-router-dom";

/**
 * 
 * @returns Header logotype component
 * 
 */

const HeaderLogotype = () => {

    /** Return component */

    return (<div className="logotype">

        <Link to="/">

            <img src={ Logotype } alt="tireshop" />

        </Link>

    </div>);

}

/** Export component */
 
export default HeaderLogotype;