import { AiFillInfoCircle } from "react-icons/ai";

const TempNotice = ( { type, notice } ) => {

    /** Return component */

    return (<div className={ `temp-notice temp-notice-${ type }` }>

        <div className="icon">

            <AiFillInfoCircle />

        </div>

        <div className="message">

            { notice }

        </div>

        <div className="tag">

            { type }

        </div>

    </div>);

}
 
/** Export component */

export default TempNotice;