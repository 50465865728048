/** @import modules */

import { Fragment               } from "react";

import { useTranslation         } from "react-i18next";

/** @import icons */

import { BsCloudRainFill        } from "react-icons/bs";

import { MdLocalGasStation      } from "react-icons/md";

import { GiVibratingBall        } from "react-icons/gi";

/**
 * 
 * @param { product } 
 * 
 * @returns product properties
 * 
 */

const ProductProperties = ( { product } ) => { const { t } = useTranslation (),

    properties_collection = [

        { 
            
            icon: <BsCloudRainFill />, 

            type: "chart",
            
            color: ( product.wetBreakingEfficiency ) ? product.wetBreakingEfficiency.toLowerCase () : null, 
            
            value: ( product.wetBreakingEfficiency ) ? product.wetBreakingEfficiency.toLowerCase () : null, 

            label: ( product.wetBreakingEfficiency ) ? product.wetBreakingEfficiency.toLowerCase () : null,
            
            tooltip: t ( "wet_breaking" ),
        
            case: "uppercase" },

        { 
            
            icon: <MdLocalGasStation />, 

            type: "chart",
            
            color: ( product.fuelEfficiency ) ? product.fuelEfficiency.toLowerCase () : null, 
            
            value: ( product.fuelEfficiency ) ? product.fuelEfficiency.toLowerCase () : null, 

            label: ( product.fuelEfficiency ) ? product.fuelEfficiency.toLowerCase () : null,
            
            tooltip: t ( "fuel_efficiency" ),
        
            case: "uppercase" },

        { 
            
            icon: <GiVibratingBall />, 

            type: "tag",
            
            value: ( product.noiseIndex === 0 ) ? null : product.noiseIndex, 

            label: `${ product.noiseIndex } dB`,
            
            tooltip: t ( "noise_index" ),
        
            case: "lowercase" },

    ],
    
    properties = properties_collection.filter ( item => item.value !== null );

    /** @returns */

    return ( <div className="product-props properties">

        { properties.map ( ( prop, index ) => <Fragment key={ index }>

            { prop.type === "chart" && <div className="prop">

                <div className="props-tooltip">

                    { prop.tooltip }

                </div>

                <div className="icon">
                    
                    { prop.icon }
                
                </div>

                <div className={ `value property-color-${ prop.color } ${ prop.case }` }>
                    
                    { prop.label }
                    
                </div>

            </div> }

            { prop.type === "tag" && <div className="prop">

                <div className="props-tooltip">

                    { prop.tooltip }

                </div>

                <div className="icon">

                    { prop.icon }

                </div>

                <div className="value-numeric">

                    { prop.label }

                </div>

            </div> }

            { prop.type === "text" && <div className="prop">

                <div className="props-tooltip">

                    { prop.tooltip }

                </div>

                { prop.type }

            </div> }

        </Fragment> ) }

    </div> );

}

/** @exports ProductProperties */
 
export default ProductProperties;