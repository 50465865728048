/** Import assets */

import Logotype from "../../../Assets/tireshop-logo.png";

/**
 * 
 * @returns Copyrights component
 * 
 */

const Copyrights = () => {

    /** Return component */

    return (<div className="copyrights">

        <div className="logotype">

            <img src={ Logotype } alt="tireshop.lv" />

        </div>

        <div className="content">

            &copy; TIRESHOP { new Date ().getFullYear () }

        </div>

    </div>);

}

/** Export component */
 
export default Copyrights;