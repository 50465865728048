/** @imports modules */

import { useQuery }             from "@apollo/client";

/** @imports queries */

import { SHOP_CONTACTS }        from "../Queries/Contacts";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Tyres product collection model
 * 
 */

const ShopContacts = () => { const

    { loading, data } = useQuery ( SHOP_CONTACTS );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default ShopContacts;