/** @import modules */

import { Link }                     from "react-router-dom";

import { 
    
    useState, 
    
    useEffect, 
    
    useContext }                    from "react";

/** @import datasets */

import HeaderDatasets               from "../../../Datasets/Header";

/** @import components */

import LanguageSelector             from "./Language";

import MobileNavigation             from "./Mobile/Navigation";

/** @import contexts */

import { CartContext }              from "../../../Contexts/CartContext";

import { CompareContext }           from "../../../Contexts/CompareContext";

/** @import icons */

import { GiHamburgerMenu }          from 'react-icons/gi';

/**
 * 
 * @returns Header actions component
 * 
 */

const HeaderActions = () => { const

    [ navigation, setNavigation ] = useState ( false ),

    { cart } = useContext ( CartContext ),

    { compare } = useContext ( CompareContext );

    /** Mobile navigation controller */

    useEffect ( () => { const nav = document.getElementById ( "mobile-navigation" );

        navigation && nav.classList.add ( "open-mobile-nav" )
        
    }, [ navigation ] );

    /** Return component */

    return (<div className="actions">

        <ul>

            { HeaderDatasets ( "asdasd", "zxczxc" ).Actions.map ( ( action, index ) => 

                <li className={ `${ action.type }-action type-${ action.display }` } key={ index }>

                    { action.link === "external" &&

                    <a href={ action.URI } target="_blank" rel="noreferrer">

                        { action.counter && <div className="content-counter">
                            
                            <span>
                                
                                { action.id === "cart" && cart.length }

                                { action.id === "compare" && compare.length }
                                
                            </span>
                            
                        </div> }

                        { action.icon }

                        { action.type === "labeled" && <span>{ action.name }</span> }

                    </a> }

                    { action.link === "internal" &&

                    <Link to={ action.URI }>

                        { action.counter && <div className="content-counter">
                            
                            <span>
                                
                                { action.id === "cart" && cart.length }

                                { action.id === "compare" && compare.length }
                                
                            </span>
                            
                        </div> }

                        { action.icon }

                        { action.type === "labeled" && <span>{ action.name }</span> }

                    </Link> }

                </li>
            
            ) }

            <LanguageSelector />

            <div className="mobile-navigation-action">

                <button 
                
                    onClick={ () => ( navigation ) ? setNavigation ( false ) : setNavigation ( true ) }>

                    <GiHamburgerMenu />

                </button>

            </div>

        </ul>

        { navigation && <MobileNavigation 
        
            attributes={ { set: setNavigation } } /> }

    </div>);

}

/** Export component */
 
export default HeaderActions;