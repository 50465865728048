/** @import modules */

import { 
    
    useEffect, 
    
    useRef, 
    
    useState, 
    
    useContext }                from "react";

import { useTranslation }       from "react-i18next";

/** @import contexts */

import { CartContext }          from "../../../Contexts/CartContext";

/** @import models */

import OrderSummary             from "../../../Models/OrderSummary";

/** @import icons */

import { IoReloadCircleSharp }             from "react-icons/io5";

/**
 * 
 * @returns update cart summary component
 * 
 */

const UpdateCart = () => { const 

    { t }           = useTranslation (),

    { refetch }     = OrderSummary (),

    mount           = useRef ( false ),

    button          = useRef ( null ),

    [ 
        
        disable, 
        
        setDisable 
    
    ]               = useState ( true ),

    { cart }        = useContext ( CartContext );

    /** @event useEffect */

    useEffect ( () => {

        if ( mount.current === false ) { mount.current = true; return; }

        setDisable ( false );

    }, [ cart, mount ] );

    /** @returns component */

    return ( <div className="update-cart">

        <button 
        
            ref={ button } 

            onClick={ () => { refetch ( cart ); return setDisable ( true ); } }
        
            disabled={ disable }>

                <span>

                    <IoReloadCircleSharp />

                </span>
                
                { t ( "update_cart" ) }
                
        </button>

    </div> );

}

/** @exports UpdateCart */
 
export default UpdateCart;