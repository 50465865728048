/** @import datasets */

import { ItemComponents } from "../../../Datasets/QuickSearch";

/**
 * 
 * @param { product } 
 * 
 * @returns item controller component
 * 
 */

const Item = ( { product, notice } ) => {

    /** @returns component */

    return ( <div className="item">

        <ItemComponents 
        
            productType={ product.productType } 
        
            product={ product }
            
            notice={ notice } />

    </div> );

}

/** @exports Item */
 
export default Item;