import { useState, useRef }         from "react";

import { useMutation }              from '@apollo/client';

import { useTranslation }           from "react-i18next";

import { PRODUCT_OFFER }            from "../../../../../Mutations/ProductOffer";

import { AiOutlineClose }           from "react-icons/ai";

const Modal = ( { controls, id, qty, notification } ) => { const { t } = useTranslation (),

    action = useRef ( "" ),

    [ name, setName ] = useState ( "" ),

    [ email, setEmail ] = useState ( "" ),

    [ phone, setPhone ] = useState ( "" ),

    [ message, setMessage ] = useState ( "" ),

    [ issues, setIssues ] = useState ( [] ),

    [ CreateProductOffer ] = useMutation ( PRODUCT_OFFER, {

        onCompleted ( data ) {

            if ( data.requestProduct.success ) {

                controls.set ( false );

                return notification.set ( [ { type: "success", msg: t ( "offer_created" ) } ] );;

            } else {

                return console.log ( "Failed" );

            }

        },

        onError ( error ) { console.log ( "Error occurred", error ); }

    } ),

    submit = () => { const errors = [];

        if ( ! name ) { errors.push ( "Missing name" ) };

        if ( ! email ) { errors.push ( "Missing email" ) };

        if ( ! phone ) { errors.push ( "Missing phone" ) };

        if ( errors.length ) return setIssues ( errors );

        action.current.setAttribute ( "disabled", "disabled" );

        action.current.innerHTML = `${ t ( "loading" ) }...`;

        setIssues ( [] );

        CreateProductOffer ( { variables: {

            productId: parseInt ( id ),

            count: parseInt ( qty ),

            fullName: name,

            email,

            phone,

            comment: message

        } } );

    };

    /** @returns */

    return ( <div className="modal-container" onClick={ ( e ) => { 

        if ( e.target.classList.contains ( "modal-container" ) ) return controls.set ( false )
    
    } }>

        <div className="modal-outline">

            <div className="modal-content">

                <div className="modal-header">

                    <div className="modal-title">
                        
                        { t ( "request_offer" ) }
                        
                    </div>

                    <div className="close-modal">
                        
                        <button onClick={ () => { controls.set ( false ) } }>

                            <AiOutlineClose />

                        </button>
                        
                    </div>

                </div>

                <div className="modal-body">

                    { issues.length > 0 && <div className="modal-errors">
                        
                        { issues.map ( ( err, index ) => <div className="err" key={ index }>

                            { err }

                        </div> ) }
                        
                    </div>}

                    <div className="field-group">

                        <label htmlFor="name">{ t ( "name" ) }</label>

                        <input type="text" value={ name } onChange={ e => setName ( e.target.value ) } id="name" name="name" />

                    </div>

                    <div className="field-group">

                        <label htmlFor="email">{ t ( "email" ) }</label>

                        <input type="text" value={ email } onChange={ e => setEmail ( e.target.value ) } id="email" name="email" />

                    </div>

                    <div className="field-group">

                        <label htmlFor="phone">{ t ( "phone" ) }</label>

                        <input type="text" value={ phone } onChange={ e => setPhone ( e.target.value ) } id="phone" name="phone" />

                    </div>

                    <div className="field-group">

                        <label htmlFor="message">{ t ( "comment" ) }</label>

                        <textarea onChange={ e => setMessage ( e.target.value ) } value={ message } id="message" name="message">{ message }</textarea>

                    </div>

                </div>

                <div className="modal-footer">

                    <div className="modal-footer-actions">

                        <button onClick={ () => controls.set ( false ) } className="cancel">
                            
                            { t ( "close" ) }
                            
                        </button>

                        <button onClick={ () => submit () } ref={ action } className="submit">
                            
                            { t ( "submit_offer_request" ) }
                            
                        </button>

                    </div>

                </div>

            </div>

        </div>

    </div> );

}

/** @exports Modal */
 
export default Modal;