/** @import assets */

import Tires        from "../Assets/category-tires.jpg";

import Rims         from "../Assets/category-rims.jpg";

import Moto         from "../Assets/category-moto.jpg";

import Truck        from "../Assets/category-truck.jpg";

import Battery      from "../Assets/category-batteries.jpg";

import Montage      from "../Assets/category-montage.jpg";

const 

categories = ( t ) => {

    return [

        {

            image: Tires,

            title: t ( "tyres" ),

            URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/f=12&sort=ASC`

        },

        {

            image: Rims,

            title: t ( "rims" ),

            URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }/f=12&sort=ASC`

        },

        {

            image: Moto,

            title: t ( "moto" ),

            URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/f=12&vt=moto&sort=ASC`

        },

        {

            image: Truck,

            title: t ( "truck" ),

            URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/f=12&vt=truck&sort=ASC`

        },

        {

            image: Battery,

            title: t ( "batteries" ),

            URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_batteries__" ) }/f=12&sort=ASC`

        },

        {

            image: Montage,

            title: t ( "montage" ),

            URI: "/content/montage"

        }

    ];

}

/** @exports */

export {

    categories

}