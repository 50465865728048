/** @import modules */

import { useTranslation     } from "react-i18next";

import { Link               } from "react-router-dom";

/** @import datasets */

import { categories         } from "../../Datasets/HomeCategories";

/**
 * 
 * @returns categories component
 * 
 */

const Categories = () => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="categories">

        <div className="max-content-width">

            <h2>{ t ( "shop_by_category" ) }</h2>

            <section>

                { categories ( t ).map ( ( category, index ) => <Link to={ category.URI } className="category" key={ index }>

                    <img src={ category.image } alt={ category.title } />

                    <span className="overlay"></span>

                    <div className="title">

                        <span></span>

                        { category.title }

                    </div>

                </Link> ) }

            </section>

        </div>

    </div> );

}

/** @exports Categories */
 
export default Categories;