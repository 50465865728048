/** @import modules */

import { useTranslation }           from "react-i18next";

import { useParams, useNavigate }   from "react-router-dom";

import { useQuery }                 from "@apollo/client";

/** @import hooks */

import { STUDS_ENUM }               from "../../../../../Queries/Tyres";

import { TyresNavigator }           from "../../../../../Hooks/TyresNavigator";

import ComponentLoader from "../../../../Layout/Loaders/Loader";

/** @import icons */

import { BsFillCaretDownFill }      from "react-icons/bs";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns StudsFilter filter component
 * 
 */

const StudsFilter = ( { attributes } ) => { const 

    { t }               = useTranslation    (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    { loading, data } = useQuery ( STUDS_ENUM  ),

    /** @param { event } event */

    Filter = ( event ) => { const 

        object = { f : 12, l: null, a: null, b: null, st: ( event.target.value === "default" ) ? null : event.target.value, loc: window.scrollY };

        return navigate (  
            
            `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
            
                new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

    };

    /** @return component */

    return ( <div className="content vehicle-type">

        { loading ? <ComponentLoader minified={ true } /> : 

            <label className="select shadow-select">

                <select onChange={ e => Filter ( e ) } defaultValue={ new URLSearchParams ( parameters ).get ( "st" ) || "default" }>

                    <option value="default">

                        { t ( "select_option" ) }

                    </option>

                    {

                        data.__type.enumValues.map ( ( value, index ) => <option key={ index } value={ value.name }>

                            { t ( `studs_${ value.name }` ) }

                        </option> )

                    }

                </select>

                <span>
                    
                    <BsFillCaretDownFill />
                    
                </span>

            </label>

        }

    </div> );

}

/** @export StudsFilter */
 
export default StudsFilter;