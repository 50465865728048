/** @import assets */

import "../../../Styles/Orders.css";

/**
 * 
 * @param {title, children} param0 
 * 
 * @returns Cart layout component
 * 
 */

const CartLayout = ( { title, children } ) => {

    /** Return component */

    return ( <div className="content cart">
    
        <div className="layout">

            <section>{ children }</section>

        </div>
    
    </div> );

}

/** Export component */
 
export default CartLayout;