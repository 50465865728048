/** @import modules */

import { useTranslation } from "react-i18next";

/**
 * 
 * @param { counter }
 *  
 * @returns availability component
 * 
 */

const Availability = ( { counter, product } ) => { const { t } = useTranslation ();

    const quantity = () => counter.qty + counter.qtyDays + counter.qtyHour + counter.qtyWeeks;

    /** @returns */

    return ( <div className="stocks">
        
        <div className="availability">

            { counter.qty > 0 && 
            
                <div className="value accented alerted">

                    { `${ t ( ( product.productType === "battery" ? "available_stock_single" : "tt_stock_current_stock" ) ) }:` } { ( counter.qty ) }

                </div>
            
            }

            { counter.qtyHour > 0 && 

                <div className="value accented">

                    { `${ t ( "tt_stock_current_express" ) }:` } { counter.qtyHour }

                </div>

            }

            { ( counter.qtyDays + counter.qtyWeeks ) > 0 && 

                <div className="value">

                    { `${ t ( "tt_stock_current_delayed" ) }:` } { counter.qtyDays + counter.qtyWeeks }

                </div>

            }

        </div>

        {/* <div className="delivery">

            <div className="icon">ICO</div>

            <div>1</div>

            <div>1 - 3</div>

            <div>3 - 7</div>

        </div> */}
    
    </div> );

}

/** @exports Availability */
 
export default Availability;