/** Import components */

// import BlogNavigation       from "../Components/Blog/Sidebar/Navigation";

import AsideBanners         from "../Components/Shared/AsideBanners";

/** Register datasets */

const BlogDatasets = () => {

    return {

        Sidebar: [

            // { component: <BlogNavigation /> },

            { component: <AsideBanners /> }

        ]

    };

};

/** Export datasets */

export default BlogDatasets;