/** Import modules */

import { useTranslation }               from "react-i18next";

import { useNavigate, useParams }       from "react-router-dom";

/** Import hooks */

import { TyresNavigator }               from "../../../../../Hooks/TyresNavigator";

/** Import icons */

import { FaCheck }                      from "react-icons/fa";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Seasons filter component
 * 
 */

const RunFlatFilter = () => { const 
    
    { t } = useTranslation (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    params              = new URLSearchParams ( parameters ),

    loadRunFlat = event => { const

        object = { f : 12, l : null, a : null, b : null, loc: window.scrollY };

        event.target.checked ? object.rf = true : object.rf = null;

        return navigate (  
        
            `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${ 
            
                new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

    };

    /** Return component */

    return ( <div className="content ctires">

        <label htmlFor={ "runFlat" } className="checkbox">

            <input 
            
                type="checkbox" 
            
                id={ "runFlat" } 
            
                value={ "runFlat" } 
            
                onChange={ e => loadRunFlat ( e ) } 
                
                checked={ params.get ( "rf" ) && ( ( params.get ( "rf" ) == "true" ) ? true : false ) } />

            <span className="check">

                <span className="checked">

                    <FaCheck />

                </span>

            </span>

            <span className="name">

                { t ( "runFlat" ) }

            </span>

        </label>

    </div> );

}

/** Export component */
 
export default RunFlatFilter;