/** @import modules */

import { useState, useEffect } from "react";

/** @fetch stored shopping cart from local storage */

const

compareTires = JSON.parse ( localStorage.getItem ( "compare" ) ) || JSON.parse ( "[]" );

/** @export CompareProducts */

export const CompareProducts = () => { const

    [ compare, setCompare ] = useState ( compareTires );

    useEffect ( () => {

        localStorage.setItem ( "compare", JSON.stringify ( compare ) );

    }, [ compare, setCompare ] );

    /** @returns hook data */

    return { compare, setCompare };

};