import MS from "../../../../../Assets/summer-tyre.png";

import Winter from "../../../../../Assets/winter-tyre.png";

import Summer from "../../../../../Assets/summer.png";

const SeasonIcons = ( { season } ) => {

    return ( <div className="season-icons">

        { season === "s" && <img src={ Summer } alt="summer" /> }

        { season === "w" && <img src={ Winter } alt="winter" /> }

        { season === "ms" && <>
        
            <img src={ MS } alt="summer" />

            <img src={ Winter } alt="winter" />
        
        </> }

    </div> );

}

/** @exports SeasonIcons */
 
export default SeasonIcons;