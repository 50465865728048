/** @import modules */

// import { useNavigate    } from "react-router-dom";

import { useContext, useState     } from "react";

// import { useTranslation } from "react-i18next";

/** @import hooks */

import { qtySum         } from "../../../../../Hooks/QtySum";

/** @import contexts */

import { CartContext    } from "../../../../../Contexts/CartContext";

/** @import components */

import AddCompare from "./AddCompare";

/** @import icons */

import { FaShoppingCart }           from "react-icons/fa";

const AddCart = ( { product, link, notice } ) => { const // { t } = useTranslation (),

    { cart, setCart } = useContext ( CartContext ),

    [ qty, addQty ] = useState ( 1 ),

    cartQty = () => { const filter = cart.find ( x => x.id === product.id );

        return ( filter === undefined ) ? 0 : filter.qty;

    },

    // navigate = useNavigate (),

    maxQty = qtySum ( product.productVendors ),

    qtyLimiter = maxQty - cartQty (),

    /** @returns */

    disableCart = () => { const current = cart.find ( x => x.id === product.id );

        if ( current === undefined ) { return false; } else {

            return current.qty < maxQty ? false : true;

        }

    },

    /** @returns */

    addToCart = ( e, id, qty ) => {

        if ( cart.find ( x => x.id === id ) ) {

            cart.filter ( x => x.id === id ).map ( x => {

                if ( parseInt ( x.qty ) + qty > parseInt ( maxQty ) ) { return null };

                x.qty = parseInt ( x.qty ) + qty;

                return null;

            } );

            notice.set ( id );

            return setCart ( [...cart ] );

        }

        // Validate Quantity

        notice.set ( id );

        setCart ( [...cart, { id, qty : parseInt( qty ) } ] );

    },

    productQuantity = action => {

        switch ( action ) {

            case "add" : 

                if ( qty < qtyLimiter ) return addQty ( qty + 1 );
            
                break;

            case "remove" : 

                if ( qty > 1 ) return addQty ( qty - 1 );
            
                break;

            default : break;

        }

    };

    /** @returns */

    return ( <div className="catalog-item-actions">


            <div className="quantity-controller">

                <button onClick={ () => productQuantity ( "remove" ) }>
                    
                    -
                    
                </button>

                <input type="number" value={ qty } onChange={ e => addQty ( e.target.value ) } max={ qtyLimiter } />

                <button onClick={ () => productQuantity ( "add" ) }>
                    
                    +
                    
                </button>

            </div>

        <button className="add-to-cart-catalog" onClick={ ( e ) => addToCart ( e, product.id, qty ) } disabled={ disableCart () }>

            <FaShoppingCart />

        </button>

        <AddCompare id={ product.id } type="rim" />

    </div> );

}

/** @exports AddCart */
 
export default AddCart;