/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import Layout               from "../../Components/Technical/Layout";

import List                 from "../../Components/Technical/Techinfos";

/**
 * 
 * @returns Services page
 * 
 */

const TechnicalInfo = () => { const { t } = useTranslation ();

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "technical_info" ), URI: `/${ t ( "__permalink_content__" ) }/${ t ( "__permalink_services__" ) }` }

        ] } />

        <Layout title={ t ( "technical_info" ) }>

            <List />

        </Layout>
        
    </div> );

}

/** Export component */
 
export default TechnicalInfo;