/**
 * 
 * @param {member} param0 
 * 
 * @returns Contacts single team member component
 * 
 */

const ContactsMember = ( { member } ) => {

    /** Return component */

    return (<div className="member">

        <div className="thumbnail">

            <div className="image">

                <img 
                
                    src={ member.image } 
                
                    alt={ member.firstName } />

            </div>

        </div>

        <div className="name">

            { `${ member.firstName } ${ member.lastName }` }

        </div>

        <div className="position">

            { member.title }

        </div>

        <div className="contacts">

            <ul>

                <li className="email">

                    <a href={ `mailto: ${ member.email }` }>
                        
                        { member.email }
                        
                    </a>
                    
                </li>

                <li className="phone">
                    
                    <a href={ `tel: ${ member.phone }` }>
                        
                        { member.phone }
                        
                    </a>
                    
                </li>

            </ul>

        </div>

    </div>);

}
 
/** Export component */

export default ContactsMember;