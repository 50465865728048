/** @import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import { useState }             from "react";

/** @import hooks */

import { qtySum }               from "../../../Hooks/QtySum";

/** @import models */

import Product                  from "../../../Models/SingleRim";

/** @import components */

import Breadcrumbs              from "../../../Components/Layout/Header/Breadcrumbs";

import ComponentLoader          from "../../../Components/Layout/Loaders/Loader";

import SingleProductLayout      from "../../../Components/Products/Shared/Single/Layout";

import Description              from "../../../Components/Products/Shared/Single/Description";

import SingleProductData        from "../../../Components/Products/Shared/Single/SingleProductData";

import Price                    from "../../../Components/Products/Shared/Single/Price";

import Stock                    from "../../../Components/Products/Shared/Single/Stock";

import Properties               from "../../../Components/Products/Shared/Single/Properties";

import Compare                  from "../../../Components/Products/Shared/Single/Compare/Compare";

import Title                    from "../../../Components/Products/Shared/Single/Title";

import Specifications           from "../../../Components/Products/Shared/Single/Specifications";

import ActionNotification       from "../../../Components/Products/Shared/Single/ActionNotifications";

import Delivery                 from "../../../Components/Products/Shared/Single/Delivery";

import Application              from "../../../Components/Products/Rims/Single/Application";

import Cart                     from "../../../Components/Products/Rims/Single/Cart";

const SingleRim = () => { const 
    
    { t }                   = useTranslation    (),

    productId               = useParams         ().productId,

    { loading, data }       = Product           ( productId ),

    [ 
        
        cartNotification, 
        
        setCartNotification ] = useState ( [] ),

    [ compare, setCompare ] = useState ( [] ),

    /** @returns Loaded product data and components */

    LoadProduct = () => {

        if ( loading ) return <ComponentLoader />

        const product = data.product.edges[ 0 ].node;

        return <SingleProductLayout data={ product }>

            <SingleProductData data={ { 
                
                image : product.currentImage, 
                
                model : `${ product.manufacturer.name.toUpperCase () } ${ product.model }`,
                
                type: product.productType } }>

                    <Title product={ product } />

                    <Price price={ parseFloat ( product.priceList.retail_price ).toFixed ( 2 ) } />

                    <Stock quantity={ qtySum ( product.productVendors ) } />

                    <Delivery product={ product } />

                    <Properties product={ product } />

                    {/* { cartNotification.length > 0 && 

                        <ActionNotification data={ cartNotification } /> }

                    <Application product={ product } notifications={ { view: cartNotification, set: setCartNotification } } /> */}

                    { cartNotification.length > 0 && 

                        <ActionNotification data={ cartNotification } /> }

                    <Cart product={ product } notifications={ { view: cartNotification, set: setCartNotification } } />

                    <Compare 
                    
                        productId={ product.id } 
                    
                        type={ product.productType } 
                    
                        notifications={ { view: compare, set: setCompare } } />

            </SingleProductData>

            <Specifications data={ [

                { name: t ( "manufacturer" ), value: product.manufacturer.name, style: "uppercase" },

                { name: t ( "model" ), value: product.model, style: "regular" },

                { name: t ( "width" ), value: `${ product.width }J`, style: "regular" },

                { name: t ( "radius" ), value: `R${ parseInt ( product.diameter ) }`, style: "regular" },

                { name: t ( "offset" ), value: `ET${ product.offset }`, style: "regular" },

                { name: t ( "pcd" ), value: product.pitchCircleDiameter, style: "regular" },

                { name: t ( "centre_bore" ), value: product?.centreBore || "-", style: "regular" },

            ] } />

            <Description description={ product.description } />

        </SingleProductLayout>

    };

    /** Render component */

    return ( <div className="container max-content-width">

        { ! loading && 

        <Breadcrumbs collection={ [

            { name: t ( "rims" ), URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }` },

            { name: data.product.edges[ 0 ].node.manufacturer.name.toUpperCase (), URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }/${

                new URLSearchParams ( { f: 12, m: data.product.edges[ 0 ].node.manufacturer.name } ).toString ()

            }` },

            { name: data.product.edges[ 0 ].node.model, URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }` },

        ] } type="basic" /> }

        <LoadProduct />

    </div> );

}

/** Export component */
 
export default SingleRim;