/** @import modules */

import { useContext         } from 'react';

/** @import contexts */

import { CompareContext     } from '../../../../../Contexts/CompareContext';

/** @import icons */

import { MdBarChart         } from 'react-icons/md';

/**
 * 
 * @param { id, type }
 *  
 * @returns add to compare action
 * 
 */

const AddCompare = ( { id, type } ) => { const 

    { compare, setCompare } = useContext ( CompareContext ),

    /** @returns */

    addToCompare = ( id ) => { const 

        tracker = compare.reduce ( ( counter, object ) => {

            if ( object.type === type ) counter += 1;

            return counter;

        }, 0 );

        if ( tracker === 4 ) { return null };

        if ( compare.find ( x => x.id === id ) ) { return null };

        return setCompare ( [...compare, { id, type } ] );

    };

    /** @returns */

    return ( <button 
    
        className="add-to-cart-catalog" 

        onClick={ () => addToCompare ( id ) }
    
        disabled={ ( compare.find ( x => x.id === id ) ) ? true : false }>

        <MdBarChart />

    </button> );

}

/** @exports AddCompare */
 
export default AddCompare;