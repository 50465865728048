/** @import datasets */

import HeaderDatasets       from "../../../../Datasets/Header";

/** @import contexts */

import { CartContext }      from "../../../../Contexts/CartContext";

import { CompareContext }   from "../../../../Contexts/CompareContext";

/** @import modules */

import { Link }             from "react-router-dom";

import { useContext }       from "react";

/**
 * 
 * @param {action} param0 
 * 
 * @returns Mobile navigation actions component
 * 
 */

const MobileNavActions = ( { action } ) => { const 

    { cart } = useContext ( CartContext ),

    { compare } = useContext ( CompareContext );

    /** Return component */

    return (<div className="mobile-nav-actions">

        <ul>

        { HeaderDatasets ().Actions.map ( ( attr, index ) => <li key={ index }>

            <Link to={ attr.URI } onClick={ () => action.set ( false ) }>

                <div className="icon">

                    { attr.icon }

                </div>

                <div className="name">{ attr.label }</div>

                { attr.counter && <div className="counter">

                    { attr.id === "cart" && cart.length }

                    { attr.id === "compare" && compare.length }

                </div> }

            </Link>

        </li> ) }

        </ul>

    </div>);
    
}

/** Export component */
 
export default MobileNavActions;