const 

Summary = ( { data, __ } ) => { 
    
    // const 

    // delivery = data.orderPriceCalculator.filter ( item => item.id === "tireshop_delivery" ),

    // price = data.orderPriceCalculator.filter ( item => item.price ).reduce ( ( a, b ) => a + parseFloat ( b.price ), 0 );

    /** @returns */

    return (

        <div className="sector summary">

            <table>

                <tbody>

                    <tr>

                        <td className="text accented">

                            { __( "delivery" ) }

                        </td>

                        <td className="value">

                            { data.orderPriceCalculator.delivery.toFixed ( 2 ) } &euro;

                        </td>

                    </tr>

                    <tr>

                        <td className="text accented">

                            { __( "cart_vat" ) }

                        </td>

                        <td className="value">

                            { data.orderPriceCalculator.vat.toFixed ( 2 ) } &euro;

                        </td>

                    </tr>

                    <tr>

                        <td className="text accented">

                            { __( "cart_totals" ) }

                        </td>

                        <td className="value">

                            { data.orderPriceCalculator.total.toFixed ( 2 ) } &euro;

                        </td>

                    </tr>

                </tbody>

            </table>

        </div>

    );

}

/** @exports Summary */
 
export default Summary;