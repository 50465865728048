import { Link } from "react-router-dom";

import NoImage from "../../Assets/no-image.jpg";

const Article = ( { post } ) => {

    /** Return component */
        
    return ( <article>

        <div className="thumbnail">

            <Link to={ `/blog/article/${ post.id }/${ encodeURIComponent ( post.title ) }` }>

                <img src={ post.image } alt={ post.title } onError={ ( { currentTarget } ) => {

                    currentTarget.onerror = null;

                    currentTarget.src = NoImage;

                } } />

            </Link>

        </div>

        <div className="title">

            <Link to={ `/blog/article/${ post.id }/${ encodeURIComponent ( post.title ) }` }>

                { post.title }

            </Link>

        </div>

        <div className="description">

            { post.description }
            
        </div>

    </article> );

}

/** Export component */
 
export default Article;