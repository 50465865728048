/** @import modules */

import { useTranslation }           from "react-i18next";

/** @import icons */

import { BsFillTelephoneFill }      from "react-icons/bs";

import { 
    
    FaMobileAlt, 
    
    FaEnvelope }                    from "react-icons/fa";



const ContactsInformation = ( { data } ) => { const 

    { t } = useTranslation (),

    contacts = [

        { icon: <BsFillTelephoneFill />,    name: t ( "shop_name" ),        value: data.contacts.shop_phone,        prefix: "tel:"      },

        { icon: <FaMobileAlt />,            name: t ( "service_phone" ),    value: data.contacts.service_phone,     prefix: "tel:"      },

        { icon: <FaEnvelope />,             name: t ( "email" ),            value: data.contacts.email,             prefix: "mailto:"   }

    ],

    Collection = ( { params } ) => <ul>{ 
        
        params.map ( ( info, index ) => <li key={ index }>

            <span>{ info.icon }</span>

            { `${ info.name }:` } <a href={ `${ info.prefix }${ info.value }` }>{ info.value }</a>

        </li> ) }
        
    </ul>, 

    Hours = ( { params } ) => {

        return <div className="content">

            <ul>

                <li>{ t ( "weekdays" ) } { params.monday }</li>

                <li>{ t ( "saturday" ) }: { params.saturday }</li>

                {/* <li>{ t ( "saturday" ) }: Atpūšamies</li> */}

                <li>{ t ( "sunday" ) }: { params.sunday }</li>

            </ul>

        </div>

    };

    /** @return component */

    return ( <div className="office-info">

        <div className="communication">

            <div className="headline">{ t ( "contact_information" ) }</div>

            <div className="content">

                <Collection params={ contacts } />

            </div>

        </div>

        <div className="hours">

            <div className="headline">{ t ( "work_hours" ) }</div>

            <Hours params={ data.hours } />

        </div>

    </div> );

}
 
/** @export ContactsInformation */

export default ContactsInformation;