const 

qtySum = quantity => {

    return quantity[0].qty + quantity[0].qtyDays + quantity[0].qtyHour + quantity[0].qtyWeeks;

};

export {

    qtySum

}