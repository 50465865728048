/** @import modules */

import { useTranslation } from "react-i18next";

/** @import misc */

import { 
    
    SingleDateConverter, 
    
    SingleTimeConverter } from "../Misc/Montage/TimeConverter";

/** @register datasets */

const 

/** @returns montage service types */

MontageTypes = () => { const { t } = useTranslation ();

    return [

        { value: "car",         name: t ( "type_car"            ) },
    
        /*{ value: "motorcycle",  name: t ( "type_motorcycle"     ) }, */
    
        { value: "commercial",  name: t ( "type_commercial"     ) },
    
        { value: "cooler",      name: t ( "type_cooler"         ) }
    
    ];

},

/** @returns table data for eliminating appointment */

RemoveAppointmentData = data => { const { t } = useTranslation ();

    return [

        { text: t ( "service_type"  ),      value: t ( `type_${ data.appointmentByUuid.transportType }` )   },

        { text: t ( "car_brand"     ),      value: data.appointmentByUuid.autoBrand                         },

        { text: t ( "car_model"     ),      value: data.appointmentByUuid.autoModel                         },

        { text: t ( "time"          ),      value: SingleTimeConverter ( data.appointmentByUuid.startTime ) },

        { text: t ( "date"          ),      value: SingleDateConverter ( data.appointmentByUuid.startTime ) },

        { text: t ( "bokss"         ),      value: data.appointmentByUuid.serviceBox                        }

    ];

},

/** @returns table data for successful appointment */

AppointmentData = data => { const { t } = useTranslation (),

    datatable = [

        { text: t ( "service_type"      ),      value: t ( `type_${ data.appointmentByUuid.transportType }` )                   },

        { text: t ( "car_brand"         ),      value: data.appointmentByUuid.autoBrand                                         },

        { text: t ( "car_model"         ),      value: data.appointmentByUuid.autoModel                                         },

        { text: t ( "numberplate"       ),      value: data.appointmentByUuid.autoNumber                                        },

        { text: t ( "name"              ),      value: data.appointmentByUuid.fullName                                          },

        { text: t ( "email"             ),      value: data.appointmentByUuid.email                                             },

        { text: t ( "phone"             ),      value: data.appointmentByUuid.phoneNumber                                       },

        { text: t ( "time"              ),      value: SingleTimeConverter ( data.appointmentByUuid.startTime )                 },

        { text: t ( "date"              ),      value: SingleDateConverter ( data.appointmentByUuid.startTime )                 },

        { text: t ( "bokss"             ),      value: data.appointmentByUuid.serviceBox                                        }

    ];

    if ( data.appointmentByUuid.transportType !== "cooler" ) {

        datatable.push ( { text: t ( "bought_online" ),     value: ( data.appointmentByUuid.tiresBought ) ? t ( "yes" ) : t ( "no" ) } );

        if ( data.appointmentByUuid.tiresBought === true ) {

            datatable.push ( { text: t ( "order_number" ), value: data.appointmentByUuid.tiresBoughtNotes } );

        }

        datatable.push ( { text: t ( "warehouse_stored" ),  value: ( data.appointmentByUuid.tiresStored ) ? t ( "yes" ) : t ( "no" ) } );

        if ( data.appointmentByUuid.tiresStored === true ) {

            datatable.push ( { text: t ( "storage_number" ), value: data.appointmentByUuid.tiresStoredNotes } );

        }

        if ( data.appointmentByUuid.wantTires === true ) {

            datatable.push ( { text: t ( "montage_want_tires" ),     value: t ( "yes" ) } );

            datatable.push ( { text: t ( "want_notes_placeholder" ),    value: data.appointmentByUuid.wantNotes } );

        }

        if ( data.appointmentByUuid.tiresOwn === true ) {

            datatable.push ( { text: t ( "montage_tires_own" ),      value: t ( "yes" ) } );

            datatable.push ( { text: t ( "tiresOwnNotes" ), value: data.appointmentByUuid.tiresOwnNotes } );

        }

        if ( data.appointmentByUuid.tiresOther === true ) {

            datatable.push ( { text: t ( "montage_tires_other" ),    value: t ( "yes" ) } );

            datatable.push ( { text: t ( "tiresOtherNotes" ), value: data.appointmentByUuid.tiresOtherNotes } );

        }

        if ( data.appointmentByUuid.tiresReturned === true ) {

            datatable.push ( { text: t ( "montage_tires_returned" ), value: t ( "yes" ) } );

        }

        if ( data.appointmentByUuid.tiresStoredAfter === true ) {

            datatable.push ( { text: t ( "montage_tires_stored_after" ), value: t ( "yes" ) } );

        }

        if ( data.appointmentByUuid.tiresDisposed === true ) {

            datatable.push ( { text: t ( "montage_tires_disposed" ), value: t ( "yes" ) } );

        }

        if ( data.appointmentByUuid.afterMontageOther === true ) {

            datatable.push ( { text: t ( "montage_after_other" ), value: t ( "yes" ) } );

            datatable.push ( { text: t ( "afterMontageOtherNotes" ), value: data.appointmentByUuid.afterMontageOtherNotes } );

        }

    }

    return datatable;

},

/** @returns montage fields for car data */

CarFields = contexts => { const { t } = useTranslation ();

    return [

        { label: `${ t ( "numberplate" ) }:`,   type: "text", name: "autoNumber",   value: contexts.Number  },

        { label: `${ t ( "car_brand" ) }:`,     type: "text", name: "autoBrand",    value: contexts.Brand   },

        { label: `${ t ( "car_model" ) }:`,     type: "text", name: "autoModel",    value: contexts.Model   }

    ];

},

/** @returns montage fields for person data */

PersonFields = contexts => { const { t } = useTranslation ();

    return [

        { label: `${ t ( "montage_name" ) }:`,  type: "text",   name: "fullName",       value: contexts.Name    },

        { label: `${ t ( "phone" ) }:`,         type: "email",  name: "phoneNumber",    value: contexts.Phone   },

        { label: `${ t ( "email" ) }:`,         type: "phone",  name: "email",          value: contexts.Email   }

    ];

}

/** @export Datasets */

export {

    MontageTypes,

    RemoveAppointmentData,

    AppointmentData,

    CarFields,

    PersonFields

}