/** @import modules */

import { gql } from "@apollo/client";

const 

/** Quick search query */

QuickSearch = gql`query StringSearch ( 
    
    $query                  : String! 

    $first                  : Int, 
    
    $last                   : Int, 
    
    $after                  : String, 
    
    $before                 : String,
    
    ) { singleStringSearch ( 
        
        query               : $query,

        first               : $first, 
        
        last                : $last,
    
        after               : $after, 
    
        before              : $before,
        
    ) { 

    edges { node {

        id

        productType

        awsImageUrl

        currentImage

        width

        height

        diameter

        length

        model

        centreBore

        dedicatedAxle

        offset

        radius

        studs

        loadIndex

        noiseIndex

        speedRating

        season

        productVendors {

            price
    
            qty 
            
            qtyDays
            
            qtyHour
            
            qtyWeeks

        }

        manufacturer { name }

        wetBreakingEfficiency

        fuelEfficiency

        specifications

        priceList

    } }

    pageInfo { 
            
        hasNextPage 
        
        hasPreviousPage 
        
        startCursor 
        
        endCursor 
    
    }
          
} }`;

/** @export queries */

export { 
    
    QuickSearch

};