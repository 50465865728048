/** Import modules */

import { useQuery }             from "@apollo/client";

/** Import queries */

import { ServicesNavigation }   from "../Queries/Services";

import { ServicesList }         from "../Queries/Services";

import { SingleService }        from "../Queries/Services";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Services navigation model
 * 
 */

const NavigationModel = () => { const

    { loading, data } = useQuery ( ServicesNavigation );

    /** @return model */

    return { loading, data }

}

/**
 * 
 * @param { params } attr 
 * 
 * @returns Services collection model
 * 
 */

 const ServicesModel = () => { const

    { loading, data } = useQuery ( ServicesList );

    /** @return model */

    const dataset = ( ! data ) ? { services: { edges: [] } } : data;

    return { loading, dataset }

}

/**
 * 
 * @param { params } attr 
 * 
 * @returns Services collection model
 * 
 */

 const SingleServiceModel = () => { const

    { loading, data } = useQuery ( SingleService );

    /** @return model */

    return { loading, data }

}

/** @export models */

export { NavigationModel, ServicesModel, SingleServiceModel };