/** Import modules */

import { useTranslation }   from "react-i18next";

import { useParams }        from "react-router-dom";

import { useRef, useState, useEffect } from "react";

import { Helmet }           from "react-helmet-async";

/** Import models */

import Collection           from "../../../Models/RimsCollection";

/** Import hooks */

import Display              from "../../../Hooks/ProductDisplay";

import usePosition          from "../../../Hooks/usePosition";

/** Import components */

import Breadcrumbs          from "../../../Components/Layout/Header/Breadcrumbs";

import ComponentLoader      from "../../../Components/Layout/Loaders/Loader";

import RimsLayout           from "../../../Components/Products/Rims/Catalog/Layout";

import Actions              from "../../../Components/Products/Rims/Catalog/Actions";

import Products             from "../../../Components/Products/Rims/Catalog/Collection";

import Pagination           from "../../../Components/Products/Rims/Catalog/Pagination";

import NoProductsFound      from "../../../Components/Products/Shared/Catalog/NoProductsFound";

/**
 * 
 * @returns Rims product catalog page
 * 
 */

const RimsCatalog = () => { const 

    { loading, data }       = Collection ( useParams ().parameters ),
    
    { t }                   = useTranslation (),

    { layout, setLayout }   = Display (),

    isMounted                           = useRef ( false ),

    [ notification, setNotification ]   = useState ( 0 ),

    filters                 = useRef ( null ),

    [ 
        
        mobileFilters, 
        
        setMobileFilters ]  = useState ( false ),

    /** Product loader */
    
    ProductCatalog = () => { if ( loading ) return <ComponentLoader />

        if ( data === undefined || ! data.multiFieldProductSearch.edges.length ) return <NoProductsFound />

        return <Products layout={ layout } products={ data.multiFieldProductSearch.edges } notice={ { view: notification, set: setNotification } } />

    };

    useEffect ( () => {

        if (isMounted.current) {
            const timer = setTimeout(() => setNotification ( "" ), 1000);
            return () => clearTimeout(timer);
          } else {

            isMounted.current = true;
          }

    }, [ notification ] );

    usePosition ();

    /** Render component */

    return ( <div className="container max-content-width">

        <Helmet>

            <title>{ `Tireshop.lv | ${ t ( "rims" ) }` }</title>

            <link rel="canonical" href="/shop/products/rims/" />

        </Helmet>

        <Breadcrumbs collection={ [

            { name: t ( "rims" ), URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_rims__" ) }` }

        ] } />

        <RimsLayout title={ t ( "rims" ) } filters={ { view: mobileFilters, set: setMobileFilters, element: filters } }>

            <Actions layout={ { view: layout, set: setLayout } } mobile={ { view: mobileFilters, set: setMobileFilters, element: filters } } />

            <ProductCatalog />

            { ! loading && 

                ( data !== undefined ) && 

                <Pagination pageInfo={ data.multiFieldProductSearch.pageInfo } />

            }

        </RimsLayout>
        
    </div> );

}

/** Export component */
 
export default RimsCatalog;